import React from 'react';
import { NavLink } from 'react-router-dom';

const ActivatedNavLink = props => {
    if (props.active) {
      return <NavLink {...props}>{props.children}</NavLink>;
    }
  
    return <div className="link-disabled">{props.children}</div>;
  };

export default ActivatedNavLink;
