import fetch from 'isomorphic-fetch';
import { API_ROOT, version } from '../../api-config';
import { User } from '../../models/user';
// import { subscribe, unsubscribe } from '../fcm';
import { fetch_options } from '../utils';
// import { plan } from '../data/plan';
import moment from 'moment';
import { RECEIVED_CLASS } from '../aclass';
import { hideChat } from '../chat';

export const LOGIN_REQUESTED = 'user/LOGIN_REQUESTED';
export const LOGIN = 'user/LOGIN';
export const LOGOUT = 'user/LOGOUT';
export const REGISTER_REQUESTED = 'user/REGISTER_REQUESTED';
export const REGISTER = 'user/REGISTER';
export const JOINCLASS = 'user/JOINCLASS';
export const CHANGE_LANGUAGE = 'user/CHANGE_LANGUAGE';
export const SAVE_USER = 'user/SAVE_USER';
export const SAVE_LIKE = 'user/SAVE_LIKE';
export const LOAD_VERSIONS = 'user/LOAD_VERSIONS';
export const RECEIVED_VERSIONS = 'user/RECEIVED_VERSIONS';
export const CLEAN_FLAGS = 'user/CLEAN_FLAGS';
export const ERROR = 'user/ERROR';
export const RECEIVED_RANKING = 'user/RECEIVED_RANKING';
export const ASKED_REVIEW = 'user/ASKED_REVIEW';
export const LOAD_RESULTS = 'user/LOAD_RESULTS';
export const ADD_TIME = 'user/ADD_TIME';
export const ADD_DAILY_GOAL_REACHED = 'user/ADD_DAILY_GOAL_REACHED';
export const OPEN_EXERCISE = 'user/OPEN_EXERCISE';

export const RATE_STATE = { NONE: 0, YES: 1, NO: 2, LATER: 3 };
export const PRODUCTS = { APP: 'app', COURSE: 'course', TEACHER: 'teacher' };
export const BASE_VIDEO_URL = 'https://koyuvideos.s3.amazonaws.com';

export const ACTIVATE_TUTOR = true;

const getStoredUser = () => {
  const userstr = localStorage.getItem('aUser');
  return userstr ? JSON.parse(userstr) : null;
};

const storeUser = (user: User) => {
  localStorage.setItem('aUser', JSON.stringify(user));
};

const destoreUser = () => {
  localStorage.removeItem('aUser');
  localStorage.removeItem('results');
  localStorage.removeItem('token');
  localStorage.removeItem('daily_goal_reached');
  localStorage.removeItem('today_time');
  localStorage.removeItem('ranking');
  localStorage.removeItem('my-last-scroll-position');
};

const getStoredLanguage = () => {
  const userstr = localStorage.getItem('aUser');
  const localLangStr = localStorage.getItem('language');
  const localLang = localLangStr && localLangStr != 'undefined' ? localLangStr : 'es';
  const user = userstr ? JSON.parse(userstr) : null;

  return user && user.language ? user.language : localLang;
};

const getStoredRanking = () => {
  const rankingstr = localStorage.getItem('ranking');
  return rankingstr ? JSON.parse(rankingstr) : null;
};

const today = moment();
const todayKey = today.format('YYYYMMDD');

const getStoredTodayTime = () => {
  const todayTimesStr = localStorage.getItem('today_time');
  const todayTimes = todayTimesStr ? JSON.parse(todayTimesStr) : null;
  // console.log('getStoredTodayTime todayTimes:', todayTimes);
  return todayTimes && todayTimes[todayKey] ? (todayTimes[todayKey] || 0) : 0;
};

const storeTodayTime = (time) => {
  const todayTimesStr = localStorage.getItem('today_time');
  const todayTimes = todayTimesStr ? JSON.parse(todayTimesStr) : {};
  todayTimes[todayKey] = time;
  localStorage.setItem('today_time', JSON.stringify(todayTimes));
}

const getDailyGoalReached = () => {
  const dailyGoalsReachedStr = localStorage.getItem('daily_goal_reached');
  const dailyGoalsReached = dailyGoalsReachedStr ? JSON.parse(dailyGoalsReachedStr) : null;
  // console.log('getDailyGoalReached dailyGoalsReached:', dailyGoalsReached);
  return dailyGoalsReached && dailyGoalsReached[todayKey] ? (dailyGoalsReached[todayKey] || 0) : 0;
};

const storeDailyGoalReached = (value) => {
  const dailyGoalsReachedStr = localStorage.getItem('daily_goal_reached');
  const dailyGoalsReached = dailyGoalsReachedStr ? JSON.parse(dailyGoalsReachedStr) : {};
  dailyGoalsReached[todayKey] = value;
  localStorage.setItem('daily_goal_reached', JSON.stringify(dailyGoalsReached));
}

const initialState = {
  token: localStorage.getItem('token'),
  error: false,
  isLoading: false,
  saved: false,
  user: getStoredUser(),
  results: null,
  lang: getStoredLanguage(),
  app_version: version,
  version_description: "",
  ranking: getStoredRanking(),
  asked_review: false,
  today_time: getStoredTodayTime(),
  daily_goal_reached: getDailyGoalReached(),
  exercise_opened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {

    case LOGIN_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: false
      };

    case LOGIN:
      return {
        ...state,
        token: action.token,
        user: action.user,
        error: action.error,
        isLoading: !state.isLoading,
        lang: action.user ? action.user.language : getStoredLanguage()
      };

    case LOGOUT:
      return {
        token: null,
        error: false,
        isLoading: false,
        user: null,
        lang: localStorage.getItem('language')
      };

    case REGISTER_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: false
      };

    case REGISTER:
      return {
        ...state,
        user: action.user,
        error: action.error,
        isLoading: false,
        saved: true,
        token: action.user.token ? action.user.token : state.token,
        lang: action.user.language ? action.user.language : getStoredLanguage()
      };

    case JOINCLASS:
      return {
        ...state,
        user: action.user,
        error: action.error,
        isLoading: !state.isLoading,
        // saved: true,
        token: action.user.token ? action.user.token : state.token,
        lang: action.user.language ? action.user.language : getStoredLanguage()
      };

    case ERROR:
      // console.log('Error received:', action.error);

      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    case LOAD_VERSIONS:
      return {
        ...state,
        isLoading: !state.isLoading
      }

    case RECEIVED_VERSIONS:
      return {
        ...state,
        app_version: action.versions && action.versions[0] ? action.versions[0].version : state.app_version,
        version_description: action.versions && action.versions[0] ? action.versions[0].details : '',
        isLoading: !state.isLoading
      }

    case CLEAN_FLAGS:
      return {
        ...state,
        saved: false,
        error: false,
        isLoading: false
      };

    case LOAD_RESULTS:
      return {
        ...state,
        results: action.results
      }

    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.lang
      };

    case ASKED_REVIEW:
      return {
        ...state,
        asked_review: true
      };

    case ADD_TIME:
      return {
        ...state,
        today_time: action.time
      };

    case ADD_DAILY_GOAL_REACHED:
      storeDailyGoalReached(action.value);
      return {
        ...state,
        daily_goal_reached: action.value
      };

    case OPEN_EXERCISE:
      return {
        ...state,
        exercise_opened: action.value,
      };

    case RECEIVED_RANKING:
      return {
        ...state,
        ranking: action.ranking,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const login = (aUser: User) => {
  return dispatch => {
    dispatch({
      type: LOGIN_REQUESTED
    });

    localStorage.removeItem('token');
    localStorage.removeItem('aUser');
    localStorage.removeItem('language');

    // const params = `email=${aUser.email}&password=${aUser.password}`;
    // const url = `${API_ROOT}/registeredusers?${params}`;

    const _fetch_options = {
      ...fetch_options,
      method: "POST",
      body: JSON.stringify({ email: aUser.email, password: aUser.password })
    };
    const url = `${API_ROOT}/auth/login`;

    return fetch(url, _fetch_options)
      .then(response => {
        if (response.status != 200) throw new Error(response.status);
        else return response.json();
      })
      .then(user => {
        // console.log('login fetched:', user);
        const token = user.token;

        if (token) {
          localStorage.setItem('token', token);
          storeUser(user);
        }

        dispatch({
          type: LOGIN,
          user: user,
          token: token,
          error: token ? false : true
        });
      })
      .catch(error => {
        console.error('login - Error fetching:', error);
        dispatch({ type: LOGIN, error: true });
      });
  };
};

export const loadToken = () => {
  return dispatch => {
    let token = localStorage.getItem('token');
    dispatch({
      type: LOGIN,
      token: token,
      error: token ? false : true
    });
  };
};

export const logout = () => {
  const storedUser = getStoredUser();
  localStorage.setItem('language', storedUser && storedUser.language ? storedUser.language : 'es');
  return dispatch => {
    destoreUser();

    dispatch({
      type: LOGOUT
    });

    hideChat();
  };

};

export const cleanFlags = () => {
  return dispatch => {
    dispatch({
      type: CLEAN_FLAGS
    });
  };
};

export const changeLanguage = lang => {
  // console.log('user.changeLang:',lang)
  return dispatch => {
    dispatch({
      type: CHANGE_LANGUAGE,
      lang: lang
    });

    dispatch(updateUserFields({ language: lang }));
  };

};

export const saveUser = (aUser: User) => {
  const storedUser = getStoredUser();

  storeUser(aUser);
  if (aUser.token) localStorage.setItem('token', aUser.token);

  return (dispatch, getState) => {
    const userState = getState().user;
    // console.log('userState:', userState, '::', aUser);

    if (!aUser.language) aUser.language = userState.lang;

    dispatch({
      type: REGISTER_REQUESTED
    });

    const url = aUser.id
      ? `${API_ROOT}/registeredusers/${aUser.id}`
      : `${API_ROOT}/registeredusers`;
    const method = aUser.id ? 'PUT' : 'POST';

    // console.log('saveUser:', aUser, url, method);

    const _fetch_options = {
      ...fetch_options,
      method: method,
      body: JSON.stringify(aUser)
    };

    return fetch(url, _fetch_options)
      .then(response => {
        // console.log('saveUser reponse:', response);

        if (response.ok) return response.json();
        else throw response;
      })
      .then(aUser => {
        // console.log('saveUser fetched:', aUser);
        // if (!aUser.token) aUser.token = aUser.id;
        storeUser(aUser);
        localStorage.setItem('token', aUser.token);

        dispatch({
          type: REGISTER,
          user: !aUser.error ? aUser : aUser,
          error: aUser.error ? true : false
        });

      })
      .catch(error => {
        console.error('saveUser error fetching:', error);
        dispatch({
          type: ERROR,
          error: 409
        });
      });

  };
};

export const changeChild = (aUser: User) => {
  return dispatch => {

    // console.log('changeChild:', aUser);

    const aChild = aUser.children ? aUser.children.find(aC => aC.id == aUser.activeChild) : null;

    if (aChild) {
      aUser.currentClass = aChild.currentClass;

      if (aChild.currentClass) {
        dispatch(joinClass(aChild, aUser, aChild.currentClass));
      } else {
        dispatch(saveUser(aUser));
        localStorage.setItem('results', JSON.stringify(null));
        dispatch({
          type: RECEIVED_CLASS,
          aClass: null,
          error: false
        });
        // if (aUser.activeChild) dispatch(loadResponses(aUser.activeChild))
      }
    } else {
      dispatch({
        type: RECEIVED_CLASS,
        aClass: null,
        error: false
      });
    }

  }
}

export const joinClass = (aChild: User, aParent: User, code: string | undefined) => {
  const storedUser = getStoredUser();

  return dispatch => {

    dispatch({
      type: REGISTER_REQUESTED
    });

    const url = `${API_ROOT}/joinclass`;
    const data = { id: aChild.id, name: aChild.name, code }
    // console.log('joinClass:', data, url);

    const _fetch_options = {
      ...fetch_options,
      method: 'POST',
      body: JSON.stringify(data)
    };

    return fetch(url, _fetch_options)
      .then(response => {
        if (response.ok) return response.json();
        else throw new Error(response);
      })
      .then(aClass => {
        // console.log('JoinClass fetched:', aClass);

        //Evaluate if main user is a parent
        if (aParent && aParent.isParent) {

          const children = aParent.children;
          if (children) for (let idx = 0; idx < children.length; idx++) {
            const aC = children[idx];
            if (aC.id == aParent.activeChild) {
              aC.currentClass = aClass.id; break;
            }

          }

          aParent.currentClass = aClass.id;

          dispatch(saveUser(aParent));

        } else {
          // aChild.id = aClass.id;
          aChild.currentClass = aClass.id;
          // if (!aChild.token) aChild.token = aChild.id;

          storeUser(aChild);
          localStorage.setItem('token', aChild.token ? aChild.token : "a_oken");

          dispatch({
            type: JOINCLASS,
            user: aChild,
            error: aClass.error ? true : false
          });

        }

        dispatch({
          type: RECEIVED_CLASS,
          aClass: aClass,
          error: aClass.error ? true : false
        });

      })
      .catch(error => {
        console.error('saveUser error fetching:', error);
        dispatch({
          type: ERROR,
          error: 'An error ocurred'
        });
      });

  };
};

export const leaveClass = (aChild: User, aParent: User, code: string) => {
  const storedUser = getStoredUser();

  return dispatch => {

    dispatch({
      type: REGISTER_REQUESTED
    });

    const url = `${API_ROOT}/leaveclass`;
    const data = { id: aChild.id, code }
    // console.log('leaveClass:', data, url);

    const _fetch_options = {
      ...fetch_options,
      method: 'PUT',
      body: JSON.stringify(data)
    };

    return fetch(url, _fetch_options)
      .then(response => {
        if (response.ok) return response.json();
        else throw new Error(response);
      })
      .then(aClass => {
        // console.log('leaveClass fetched:', aClass);

        //Evaluate if main user is a parent
        if (aParent && aParent.isParent) {

          const children = aParent.children;
          if (children) for (let idx = 0; idx < children.length; idx++) {
            const aC = children[idx];
            if (aC.id == aParent.activeChild) {
              aC.currentClass = ''; break;
            }
          }

          aParent.currentClass = '';

          dispatch(saveUser(aParent));

        } else {
          aChild.id = aClass.id;
          aChild.currentClass = aClass.id;
          // if (!aChild.token) aChild.token = aChild.id;

          storeUser(aChild);
          localStorage.setItem('token', aChild.token ? aChild.token : "a_oken");

          dispatch({
            type: JOINCLASS,
            user: aChild,
            error: aClass.error ? true : false
          });

        }

        dispatch({
          type: RECEIVED_CLASS,
          aClass: null,
          error: aClass.error ? true : false
        });

      })
      .catch(error => {
        console.error('saveUser error fetching:', error);
        dispatch({
          type: ERROR,
          error: 'An error ocurred'
        });
      });

  };
};

export const forgotPassword = (email: string) => {
  // console.log('forgotPassword:', email);

  return dispatch => {
    dispatch({
      type: REGISTER_REQUESTED
    });

    const url = `${API_ROOT}/rememberpass/${email}`;
    return fetch(url, fetch_options)
      .then(response => response.json())
      .then(json => {
        // console.log('register fetched:', json);

        dispatch({
          type: CLEAN_FLAGS
        });
      })
      .catch(error => {
        console.error('Error fetching:', error);
      });
  };
};

export function loadVersion(platform) {

  const url = `${API_ROOT}/app-versions?_sort=date:desc&_limit=1&platform=${platform}`;

  return function (dispatch) {
    dispatch({
      type: LOAD_VERSIONS,
      url
    });

    return fetch(url, fetch_options)
      .then(response => response.json())
      .then(json => {
        // console.log('loadVersions recived:', json);

        dispatch({
          type: RECEIVED_VERSIONS,
          versions: json,
          receivedAt: Date.now()
        });
      });
  };
};

export function deleteAccount() {
  return (dispatch, getState) => {

    const storeState = getState();


    const _user: any = storeState.user.user;
    // console.log('deleteAccount...', _user);

    if (_user) {
      dispatch({
        type: LOGIN_REQUESTED
      });

      const id = _user.id;
      const token = _user.token;

      const _fetch_options = {
        ...fetch_options,
        method: "POST",
        body: JSON.stringify({ id, token })
      };
      const url = `${API_ROOT}/registereduser/delete`;

      return fetch(url, _fetch_options)
        .then(response => {
          if (response.status != 200) throw new Error(response.status);
          else return response.json();
        })
        .then(success => {
          dispatch(logout());
        })
        .catch(error => {
          console.error('deleteAccount - Error fetching:', error);
          // dispatch({ type: LOGIN, error: true });
        });

    }

  };
}

export function addPoints(points) {
  return (dispatch, getState) => {

    const storeState = getState();

    const _user: any = storeState.user.user;
    // console.log('addPoints...', _user, '::', points);

    if (_user && points > 0) {
      const currUserPoints = _user.points ? parseInt(_user.points) : 0;
      dispatch(updateUserFields({ points: (currUserPoints + parseInt(points)) }));

    }

  };
}

export function updateUserFields(fields) {
  return (dispatch, getState) => {

    const storeState = getState();

    const _user: any = storeState.user.user;
    // console.log('updateUserFields fields:', _user, '::', fields);

    const diff_fields = {};
    if (_user) for (let prop in fields) {
      if (JSON.stringify(_user[prop]) != JSON.stringify(fields[prop])) diff_fields[prop] = fields[prop];
    }

    // console.log('updateUserFields diff_fields:', diff_fields);

    if (_user && _user.token && Object.keys(diff_fields).length > 0) {

      dispatch({
        type: REGISTER_REQUESTED
      });

      const id = _user.id;

      const _fetch_options = {
        ...fetch_options,
        method: "PUT",
        body: JSON.stringify({ ...diff_fields })
      };
      const url = `${API_ROOT}/registereduser/fields/${id}`;

      return fetch(url, _fetch_options)
        .then(response => {
          if (response.status != 200) throw new Error(response.status);
          else return response.json();
        })
        .then(aUser => {
          // console.log('updateUserFields fetched:', aUser);
          // if (!aUser.token) aUser.token = aUser.id;
          storeUser(aUser);
          localStorage.setItem('token', aUser.token);

          dispatch({
            type: REGISTER,
            user: !aUser.error ? aUser : aUser,
            error: aUser.error ? true : false
          });

        })
        .catch(error => {
          console.error('addPoints - Error fetching:', error);
        });

    }

  };
}

export function loadRanking() {

  return function (dispatch, getState) {

    const storeState = getState();

    const _user: any = storeState.user.user;
    // console.log('loadRanking:', _user);

    dispatch({
      type: REGISTER_REQUESTED
    });

    const url = `${API_ROOT}/registereduser/ranking/${_user.id}`;
    return fetch(url, fetch_options)
      .then(response => {
        if (response.ok) return response.json();
        else if (response.status === 404) return null;
        else throw new Error(response);
      })
      .then(ranking => {
        // console.log('loadRanking recived:', ranking);

        localStorage.setItem('ranking', JSON.stringify(ranking));

        dispatch({
          type: RECEIVED_RANKING,
          ranking
        });
        // }

      })
      .catch(error => {
        console.error('loadRanking error fetching:', error, 'loading from local');
      });
  }
}

export function getUserData() {
  return (dispatch, getState) => {

    const storeState = getState();

    const _user: any = storeState.user.user;
    // console.log('getUserData...', _user);

    if (_user) {
      dispatch({
        type: LOGIN_REQUESTED
      });

      const id = _user.id;
      const token = _user.token;

      const _fetch_options = {
        ...fetch_options,
        method: "GET"
      };

      const url = `${API_ROOT}/registeredusers/${id}`;

      return fetch(url, _fetch_options)
        .then(response => {
          if (response.status != 200) throw new Error(response.status);
          else return response.json();
        })
        .then(user => {
          // console.log('getUserData fetched:', user);
          const token = user.token;

          if (token) {
            localStorage.setItem('token', token);
            storeUser(user);
          }

          dispatch({
            type: LOGIN,
            user: user,
            token: token,
            error: token ? false : true
          });
        })
        .catch(error => {
          console.error('getUserData - Error fetching:', error);
          // dispatch(logout());
        });

    }

  };
}

export function askedReview() {
  return (dispatch, getState) => {
    dispatch({
      type: ASKED_REVIEW,
    });
  }
}

export function addTime(msecs) {
  return (dispatch, getState) => {

    const userState = getState().user;
    // console.log('user addTime:', userState.today_time, msecs);
    if (msecs) {
      const newTime = (userState.today_time || 0) + msecs;
      storeTodayTime(newTime);

      dispatch({
        type: ADD_TIME,
        time: newTime
      });
    }
  }
}

export function addDailyGoalReached(value) {
  return (dispatch, getState) => {
    // console.log('user addDailyGoalReached:', value);
    if (value) {
      const NOW = moment().format('YYYYMMDD');

      const userState = getState().user;
      const daily_goal_reached = userState.daily_goal_reached;
      const _user: any = userState.user;
      const dailyGoalTrack = _user.dailyGoalTrack ? { ..._user.dailyGoalTrack } : {};
      dailyGoalTrack[NOW] = value;

      if (value === 100 && daily_goal_reached < 100) {
        dispatch(addPoints(10));
        dispatch(updateUserFields({ dailyGoalTrack }));
      }
      dispatch({
        type: ADD_DAILY_GOAL_REACHED,
        value
      });
    }
  }
}

export function openedExercise(value) {
  return (dispatch, getState) => {
    dispatch({
      type: OPEN_EXERCISE,
      value
    });
  }
}

