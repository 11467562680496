/* eslint no-eval: 0 */
import React, { useEffect, useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';
import { getSeriesFromCouples, isPortrait } from '../../../utils';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';

const SumSubstractionNumber = (props) => {

    const [eid, setEid] = useState(0);
    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [times, setTimes] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);
    const [splitOps, setSplitOps] = useState(null);
    const [currentOps, setCurrentOps] = useState(0);


    useEffect(() => {
        handleRepeat();
    }, []);

    const handleRate = () => {
        let splitJoin = splitOps.reduce(((acc, aO, idx) => (idx <= currentOps) ? acc + aO : acc), '');
        const currentNumber = eval(splitJoin);

        // console.log('SumSubstractionNumber.handleNext:', this.state);
        const current_result = currentNumber === numberinbox ? 1 : 0;
        let finished = false;
        if (!current_result) finished = true;
        else if (currentOps === splitOps.length - 1) finished = true;

        if (finished) {
            splitJoin = splitJoin.replace('0+', '');
            const message = current_result ? `${t(props.lang, 'rightanswer')}: ${splitJoin} = ${currentNumber}` : `${t(props.lang, 'wrong')}, ${splitJoin} = ${currentNumber}`;
            const message2 = current_result ? t(props.lang, 'great') : t(props.lang, 'nodiscorage');

            results.push(current_result);

            setResults(results);
            setMessage(message);
            setMessage2(message2);
        } else {
            setCurrentOps(currentOps + 1);
        }

    }

    const handleNext = () => {
        if (current < series.length - 1) {
            const splitSerie = series[current + 1].split(/(\+|-|\*|\/)/sg);
            const splitOps = [];

            splitOps.push(splitSerie[0]);
            for (let idx = 1; idx < splitSerie.length; idx += 2) {
                splitOps.push(splitSerie[idx] + splitSerie[idx + 1]);
            }

            setCurrent(current + 1);
            setNumberinbox(parseInt(splitSerie[0]));
            setMessage(null);
            setMessage2(null);
            setMoved(0);
            setSplitOps(splitOps);
            setCurrentOps(1);
        } else {
            const num = props.params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > props.params.errors ? 0 : 1;
            const uid = props.uid;
            const eid = props.eid;
            const coins = props.params.coins;

            const is_test = props.params.is_test ? props.params.is_test : false;

            props.saveResult(uid, `${uid}-${eid}`, { id: 'SS', uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), r: results, s: series });
            if (!is_test) {
                setShowresults(true);
            }
        }
    }

    const handleRepeat = () => {

        const params = props.params;
        const couples = params.couples;
        const series = getSeriesFromCouples(couples, params.num, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan, params.lowerlimit, params.upperlimit, params.startwith0);

        const splitSerie = series[0].split(/(\+|-|\*|\/)/sg);

        const splitOps = [];
        splitOps.push(splitSerie[0]);
        for (let idx = 1; idx < splitSerie.length; idx += 2) {
            splitOps.push(splitSerie[idx] + splitSerie[idx + 1]);
        }

        // console.log('SumSubstractionNumber.handleRepeat...', splitOps);

        setSeries(series);
        setCurrent(0);
        setNumberinbox(parseInt(splitSerie[0]));
        setResults([]);
        setMessage(null);
        setMessage2(null);
        setShowresults(false);
        setMoved(0);
        setSplitOps(splitOps);
        setCurrentOps(1);
    }

    const handleChange = (num) => {
        setNumberinbox(num);
        setMoved(1);
    }

    if (!showresults) {
        let currentNumber = splitOps ? splitOps[currentOps] : null;

        // console.log('SumSubstractionNumber.render....', splitOps, currentOps, currentNumber);

        if (currentOps == 1 && splitOps[0] == 0) {
            currentNumber = currentNumber.replace(/(\+|-|\*|\/)/sg, '');
        }

        const rate = results[current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const percent = parseInt((current) / props.params.num * 100);

        const is_test = props.params.is_test ? props.params.is_test : false;

        const rows = (props.params ? props.params.rows : 3);

        const paramsToPass = { ...props.params, rows: props.params ? props.params.rows : 1, number: numberinbox };

        if (currentNumber) return (
            <Row className="exercise">

                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <div className='progress'><Progress percent={percent} showInfo={false} /> </div>
                </Col> : null}

                <Col span={24} className="exercise-body">
                    <ExerciseBlocker show={messagestyle} />
                    <section className="sumsubstractionnumber">
                        <AbacusView params={paramsToPass} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={handleChange} />
                        {!message ? <article className="number" key={currentNumber}>
                            <motion.div
                                animate={{ scale: 2 }}
                                transition={{ duration: 1 }}
                                className="the-number"
                            >{currentNumber}</motion.div>
                        </article> : <article className="number"><div className="the-number transparent">0</div></article>}
                    </section>
                </Col>
                <Col span={24} className={'botonera'}>
                    <Row className={'botones sumsubstractionnumber' + messagestyle}>
                        <Col span={24} className={'message'}>{message}</Col>
                        {!message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                        {message ? <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                        {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col>
            </Row >
        );
        else return null;
    } else {
        return (
            <Result params={props.params} results={results} handleRepeat={handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
        );
    }

}

export default SumSubstractionNumber;

