import React from 'react';
import { DropTarget } from 'react-drag-drop-container';
import BoxItem from './BoxItem';

import { nanoid } from 'nanoid'

export default class BoxC10 extends React.Component {
  state = {
    items: [],
    sound: false
  };

  handleDrop = (e) => {
    // console.log('Box.onDrop:',e)
    e.preventDefault();
    let items = this.state.items.slice();
    items.push({ value: e.dragData.value, img: e.dragData.img, uid: nanoid(), itemTarget: e.itemTarget });
    this.setState({ items: items, sound: true });//control copy
    e.containerElem.style.visibility = "hidden";//control desapair
    if (this.props.onMove) this.props.onMove(this.state.items, this.props.id);

    setTimeout(() => { this.setState({ sound: false }) }, 500);
  };

  swap = (fromIndex, toIndex, dragData) => {
    let items = this.state.items.slice();
    const item = { value: dragData.value, img: dragData.img, uid: nanoid(), itemTarget: dragData.itemTarget };
    items.splice(toIndex, 0, item);
    this.setState({ items: items, sound: true });
    if (this.props.onMove) this.props.onMove(this.state.items, this.props.id);

    setTimeout(() => { this.setState({ sound: false }) }, 500);
  };

  kill = (uid) => {
    let items = this.state.items.slice();
    const index = items.findIndex((item) => {
      return item.uid === uid
    });
    if (index !== -1) {
      items.splice(index, 1);
    }
    this.setState({ items: items });
  };

  UNSAFE_componentWillMount () {
    //console.log('Box.componentWillMount:', this.props, this.state)
    if (this.props.items) this.setState({ items: this.props.items });
  }

  render() {
    // console.log('Box.render:', this.props)
    const props = this.props;
    const className = props.className ? props.className : '';
    const items = this.state.items.map(aI=>{
        aI.itemTarget = 'c'+aI.value;
        return aI;
      });
    // console.log('Box.render itemTarget:', itemTarget)
    // console.log('BoxC10.render items:', items)

    return (
      <div className="component_box">
        {this.state.sound ? <audio src={'./static/sounds/click.mp3'} autoPlay={true} loop={false}></audio> : null}

       
          <DropTarget
            onHit={this.handleDrop}
            targetKey={'c10'}
            dropData={{ name: this.props.name }}
          >

            <div className={`box ${className}`}>
              {items.map((item, index) => {
                return (
                  <BoxItem
                    key={item.uid}
                    uid={item.uid}
                    img={item.img}
                    value={item.value}
                    kill={this.kill}
                    index={index}
                    swap={this.swap}
                    itemTarget={item.itemTarget}
                  >
                  </BoxItem>
                )
              })}
            </div>
          </DropTarget>
      </div>
    );
  }
}