import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { Button, Input, notification } from 'antd';
// import { Redirect } from 'react-router';

import '../../Profile/index.less';

import { RouteComponentProps } from 'react-router';
import { User } from '../../../models/user';
import { forgotPassword } from '../../../modules/user';

import BottomMenu from '../../../components/Menu';
import { t } from '../../../languages';
import { recordPageGA } from '../../../modules/ga';
import { NavLink } from 'react-router-dom';


const validateEmail = value => {
    const re = /\S+@\S+\.\S+/;
    return re.test(value);
};

const isOkForm = ({ email }) => {
    return validateEmail(email);
};

const openNotificationWithIcon = (type, message, description, closeCallback) => {
    notification[type]({
        message: message,
        description: description,
        duration: 2,
        key: 'registernotification',
        onClose: closeCallback
    });
};

interface IProps extends RouteComponentProps<any> {
    user: User;
    lang: string;
    changeLanguage: (lang: string) => void;
    forgotPassword: (email: string) => void;
}

class Remember extends React.Component<IProps> {
    state = { email: '', isOk: false, recovered: false }

    componentDidMount() {
        recordPageGA('/remember');
    }

    handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email: string = e.currentTarget.value;
        this.setState({ email, isOk: false });
        if (validateEmail(email)) {
            const isOk = isOkForm(this.state);
            this.setState({ isOk });
        } else this.setState({ isOk: false });
    };

    recoverPsw = (email) => {
        if (validateEmail(email)) {
            this.setState({ recovered: true });
            this.props.forgotPassword(this.state.email);
        } else {
            openNotificationWithIcon(
                'error',
                'Introduce un email válido',
                '',
                () => { this.setState({ redirect: true }); notification.destroy(); }
            );
        }
    }

    render() {
        const props = this.props;

        return (
            <section>
                <section className="MiSoroban welcome login remember">

                    <div className="login-title">
                        <img src="./static/logo_mis.png" alt="" />
                    </div>
                    {this.state.recovered ?
                        <div className="remember-container">
                            <h3>{t(props.lang, 'wesendpass')}</h3>
                            <NavLink to="/profile/login" className="login">
                                {t(this.props.lang, 'loginacc')}
                            </NavLink>
                        </div>
                        :
                        <div>
                            <div className="remember-container">

                                <Input
                                    type="text"
                                    placeholder={t(props.lang, 'email')}
                                    className="login-input first-input"
                                    onChange={this.handleEmailChange} value={this.state.email}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="button-container">
                                <Button
                                    className="button-next"
                                    type="primary"
                                    onClick={() => this.recoverPsw(this.state.email)}>
                                    {t(props.lang, 'recovepass')}
                                </Button>
                            </div>
                            <br />
                            <div>
                                <NavLink to="/profile/login" className="login">
                                    {t(this.props.lang, 'loginacc')}
                                </NavLink>
                            </div>
                        </div>
                    }

                </section>
                {/* <BottomMenu section={'profile'} /> */}
            </section>
        )
    }
}

const mapStateToProps = ({ user }) => ({
    user: user.user,
    lang: user.lang
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        forgotPassword
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Remember);
