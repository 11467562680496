import fetch from 'isomorphic-fetch';
import moment from 'moment';

import { API_ROOT } from '../../api-config';
import { fetch_options } from '../utils';

import plan from '../../data/plan';
import { addPoints, LOGOUT } from '../user';

export const LOAD_USER_RESPONSE = 'response/LOAD_USER_RESPONSE';
export const RECEIVED_USER_RESPONSE = 'response/RECEIVED_USER_RESPONSE';
export const CLEAN_USER_RESPONSE = 'response/CLEAN_USER_RESPONSE';

export const GAME_IDS = {
  // Gamez
  'BC': 'baloonschall',
  'MS': 'memoset',
  'MA': 'mentalabacusfixation',
  'MSS': 'mentalsumsubstraction',
  'MD': 'multiplydivide',
  'PC': 'pathchall',
  'QG': 'quantitygame',
  'RA': 'representinabacus',
  'SS': 'sumsubstractionnumber',
  'SSS': 'sumsubstractionseries',
  'T': 'theory',
  'WN': 'whatnumberis',
  'CL': 'cartoonlayout',
  'CC': 'coupleschall',
  'GS': 'guidedsequence',
  // Challenges
  'EC': 'equationchallenge',
  'NP': 'numericpath',
  'MMC': 'memomeshchallenge',
  'CO': 'completeoperations',
  'NR': 'numberriddle',
};

const initialState = {
  data: null,
  isFetching: false,
  lastUpdated: null,
  error: null
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_USER_RESPONSE:
      return {
        ...state,
        isFetching: true,
        data: null,
        lastUpdated: null
      };

    case RECEIVED_USER_RESPONSE:
      return {
        ...state,
        isFetching: false,
        data: action.response,
        lastUpdated: action.receivedAt
      };

    case LOGOUT:
      localStorage.removeItem('results');

      return initialState;

    default:
      return state;
  }
};

const loadStorageResults = () => {
  const resultsstr = localStorage.getItem('results');
  let results = resultsstr ? JSON.parse(resultsstr) : null;
  // console.log('response.loadStorageResults:', results);

  if (!results) {
    results = { current_level: 1 };//{ current: 'u1', current_level: 1 };
    localStorage.setItem('results', JSON.stringify(results));
  }

  // console.log('response.loadStorageResults:', results);
  return results;
}

export function loadResponses(activeChild: string) {

  // console.log('response.loadResponses:', activeChild);

  return function (dispatch, getState) {
    const responseState = getState().response;

    dispatch({
      type: LOAD_USER_RESPONSE
    });

    const url = `${API_ROOT}/userresponses/${activeChild}`;
    return fetch(url, fetch_options)
      .then(response => {
        if (response.ok) return response.json();
        else if (response.status === 404) return null;
        else throw new Error(response);
      })
      .then(response => {
        // console.log('loadUserResponses recived:', response);
        // const lastUpdatedStore: any = responseState ? responseState.lastUpdated : 0;
        // const responseUpdatedAt = response ? moment(response.updatedAt) : 0;
        const responses = response && response.responses && Object.keys(response.responses).length > 0 ? response.responses : null;
        localStorage.setItem('results', JSON.stringify(responses));

        dispatch({
          type: RECEIVED_USER_RESPONSE,
          response: response ? response.responses : loadStorageResults(),
          receivedAt: moment()
        });

      })
      .catch(error => {
        console.error('response.loadResponses error fetching:', error, 'loading from local');
        dispatch({
          type: RECEIVED_USER_RESPONSE,
          response: loadStorageResults(),
          receivedAt: moment()
        });
      });
  }
}


const storeResult = (uid, ex, result, user) => {
  // console.log('storeResult:', uid, ex, result);

  const NOW = moment().format('YYYYMMDD');

  const resultsstr = localStorage.getItem('results');
  let results = resultsstr ? JSON.parse(resultsstr) : {};
  let current_level = results ? results.current_level : 1;
  const last_level = result.l;


  // console.log('storeResult prev results:', results);
  // save current result
  const num_tries = results[ex] ? (results[ex].ts ? results[ex].ts + 1 : 2) : 1;
  const rhistory = results[ex] && results[ex].rh ? [...results[ex].rh, result.p] : [result.p];

  results[ex] = { ...result, d: NOW, ts: num_tries, rh: rhistory };
  results["date"] = NOW;
  results["last"] = { uid, ex, n: rhistory.length, l: last_level };

  // update global result
  let current_unit;
  let levelIdx = 0;
  let is_test = false;

  const currentPlan = plan[user.age ? user.age : 1];

  if (typeof uid === 'string' && uid.indexOf('t') >= 0) is_test = true;

  if (!is_test) {
    for (let lx = 0; lx < currentPlan.length; lx++) {
      const level = currentPlan[lx];
      if (level.units) for (let ux = 0; ux < level.units.length; ux++) {
        const unit = level.units[ux];
        if (unit.uid === uid) {
          current_unit = unit;
          levelIdx = lx;
          break;
        }
      }
    }

    // console.log('storeResult current_unit:', uid, '-', ex, '=>', current_unit, '::', results);
    if (current_unit) {
      const exercises = current_unit.exercises;
      const numpassed = exercises.reduce(((acc, exer, ix) => {
        const exer_res = results[`${uid}-${ix}`];
        return acc + (exer_res ? (exer_res.p ? 1 : 0) : 0);
      }), 0);

      results['current'] = `u${uid}`;

      // console.log('response.storeResult numpassed:', numpassed, exercises.length, uid);
      results[`u${uid}`] = { p: (numpassed >= exercises.length), h: numpassed, e: exercises.length - numpassed, d: NOW };
      if (numpassed >= exercises.length) {
        if (currentPlan[levelIdx].units && currentPlan[levelIdx].units.length === uid) results['current'] = `t${levelIdx + 1}`;
        else results['current'] = `u${uid + 1}`;
      }

      results['current_level'] = current_level ? (current_level > currentPlan[levelIdx].level ? current_level : currentPlan[levelIdx].level) : currentPlan[levelIdx].level;
    }
  } else {
    const test_res = results[uid];
    if (test_res.p) {
      levelIdx = current_level; //uid.substring(1);
      const new_level = currentPlan[levelIdx];
      const new_unit = new_level.units[0];
      // console.log('response.storeResult levelIdx:', levelIdx, 'current_level:', current_level, 'new_level:', new_level, 'new_unit:', new_unit);
      if (last_level >= current_level) {
        results['current'] = `u${new_unit.uid}`;
        results['current_level'] = currentPlan[levelIdx].level;
      }
    } else {
      if (last_level >= current_level) results['current'] = uid;
    }
    // console.log('response.storeResult after test:', results['current']);

  }

  const resultsStr = JSON.stringify(results);
  localStorage.setItem('results', resultsStr);

  return resultsStr;
}

export const saveResponses = (uid, ex, result) => {
  return (dispatch, getState) => {
    // console.log('response.saveResponses uid, ex, result:', uid, ex, result);

    const userStore: any = getState().user;
    // console.log('response.saveResponses user:', userStore);

    const user = userStore.user;
    const activeChild = user ? (user.isParent ? user.activeChild : user.id) : null;
    // console.log('response.saveResponses:', user, uid, ex, result);

    const resultsStr = storeResult(uid, ex, result, userStore.user);
    // console.log('response.saveResponses resultsStr:', resultsStr);

    dispatch({
      type: RECEIVED_USER_RESPONSE,
      response: JSON.parse(resultsStr),
      receivedAt: moment()
    });

    dispatch({
      type: LOAD_USER_RESPONSE,
    });

    const _fetch_options = {
      ...fetch_options,
      method: 'POST',
      body: resultsStr
    };

    const url = `${API_ROOT}/userresponses/${activeChild}`;
    return fetch(url, _fetch_options)
      .then(response => {
        if (response.ok) return response.json();
        else throw new Error(response);
      })
      .then(response => {
        // console.log('response.saveResponses recived:', response);

        dispatch({
          type: RECEIVED_USER_RESPONSE,
          response: response.responses,
          receivedAt: moment()
        });

        // Update points if passed and coins
        // console.log('response.saveResponses adding points:', result);
        if (result.c && result.p) dispatch(addPoints(result.c));

      })
      .catch(error => {
        console.error('response.saveResponses error fetching:', error, ':: Saving from local');
      });

  }
}
