import moment from "moment-timezone";
import { message } from "antd";
import {generateToken} from '../../utils/sec';

export const API_TOKEN = `Bearer ${generateToken()}`;
export const fetch_options: any = {
    headers: {
        Authorization: API_TOKEN,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    }
};

export const shouldReloadElements = (lastUpdate, timeToReload) => {
    const now = Date.now();

    if (!lastUpdate) return true;
    else if (now - lastUpdate >= timeToReload) return true;
    else return false;
}

export const loadStoredDate = (itemName: string, processFunc: any) => {
    if (!itemName) return null;
    const itemsStr = localStorage.getItem(itemName);
    if (itemsStr) {
        const items = JSON.parse(itemsStr);
        if (items) {
            try {
                const procItems = processFunc ? processFunc(items) : items;
                return procItems;
            } catch (error) {
                return null;
            }

        } else return null;
    } else {
        return null;
    }
}