import React from 'react';
import { t } from '../../languages';

const TOS = ({ lang }) => {
    return (
        <div>
            <h3>AVISO LEGAL</h3>
            <ol>
                <li>
                    <h4>Aviso Legal</h4>
                    <span>Para dar cumplimiento a lo establecido en la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSICE), a continuación se indican los datos de información general del sitio web https://misoroban.com
                    <br /><br /> Razón social del titular del sitio web: Ricardo Ahumada Glenni
                    <br /><br />NIF: 73110255v
                    <br /><br />Domicilio social: Avenida Galicai 12, 5º izquierda, 31003 Pamplona
                    <br /><br />Email: equipo@misoroban.com
                    <br /><br />Todas las marcas, logotipos, nombres comerciales, signos distintivos, servicios, contenidos, textos, fotografías, gráficos, imágenes, software, links e informaciones de cualquier clase que aparecen en este sitio web están protegidos por derechos de propiedad intelectual e industrial, por lo que no podrán ser reproducidos, distribuidos, comunicados públicamente, transformados o modificados sin autorización expresa de su legítimo titular.</span>
                </li>
                <li>
                    <h4>Finalidad de la aplicación móvil</h4>
                    <span>Le informamos que MiSoroban es una aplicación móvil cuya finalidad es facilitar un canal de información y comunicación a usuarios a los que les une el interés por los esports. <br /><br /> Los usuarios podrán interactuar en esta aplicación de forma gratuita.</span>
                </li>
                <li>
                    <h4>Condiciones de uso</h4>
                    <span>Las presentes condiciones generales rigen el uso de MiSoroban. El acceso y posterior utilización de esta plataforma por parte del usuario implicará su conformidad de forma expresa, plena y sin reservas, con estas condiciones de uso. Si el usuario no estuviera de acuerdo con el contenido de este documento deberá abandonar la plataforma, no pudiendo acceder ni disponer de los servicios que ésta ofrece.
                    <br /><br />Igualmente, el usuario acepta de forma expresa y sin excepciones que el acceso y la utilización de la plataforma, de sus servicios y de los contenidos de dichos servicios tienen lugar bajo su única y exclusiva responsabilidad.
                    <br /><br />Los usuarios registrados podrán introducir, editar, gestionar y actualizar información y a su vez tendrán acceso a información publicada por otros usuarios. El contenido y uso de dicha información se regirán por las presentes condiciones de uso.
                    <br /><br />MiSoroban podrá modificar de modo unilateral, en cualquier momento que estime oportuno, la configuración de la presente aplicación móvil, las condiciones del servicio y su contenido, así como eliminarlos, limitarlos o suspenderlos de manera temporal o definitiva. También podrá impedir el acceso a los mismos procurando informar al usuario de dicho cambio, siempre y cuando las circunstancias así se lo permitan, a través de su publicación en la plataforma.</span>
                </li>
                <li>
                    <h4>Mayoría de edad</h4>
                    <span>No se permite el acceso a MiSoroban a menores de 14 años. El cumplimiento de este requisito es responsabilidad del usuario. Si en cualquier momento tenemos constancia o sospechamos que el usuario no cumple este requisito de edad, procederemos al borrado de su cuenta sin previo aviso.</span>
                </li>
                <li>
                    <h4>Privacidad y Seguridad</h4>
                    <span>De conformidad con la normativa de protección de datos le informamos que sus datos personales recabados a través de MiSoroban serán utilizados, única y exclusivamente con el fin de dar una efectiva respuesta o prestación de los servicios solicitados por el usuario.
                    <br /><br />El usuario es libre de completar los datos solicitados en el formulario de registro. Sólo son obligatorios los que permiten el acceso a la plataforma como usuario registrado. El resto de datos de configuración del perfil, son voluntarios.
                    <br /><br />MiSoroban ofrece al usuario la posibilidad de vincular su perfil con la actividad que desarrolla en otras redes sociales. Esta vinculación es voluntaria. Debe saber que en caso de optar por dicha vinculación, la actividad del usuario en MiSoroban se publicará en las redes sociales vinculadas.
                    <br /><br />En el caso de no suministrar todos los datos requeridos como obligatorios, MiSoroban podrá no proceder al registro del usuario o bien denegar parte o la totalidad del servicio.
                    <br /><br />Los datos personales recabados del usuario serán verdaderos, exactos, completos y actualizados, respondiendo de los daños y perjuicios que pudiera ocasionar a causa de la cumplimentación defectuosa de los mismos. Asimismo, MiSoroban no se responsabiliza de los posibles daños o perjuicios que el usuario o un tercero pueda sufrir con motivo de compartir dichos datos personales, fotografías, relatos, etc.
                    <br /><br />Puede ejercer sus derechos de acceso, rectificación, cancelación y oposición, mediante escrito dirigido a UPGRADE ESPORTS S.L., con sede en Avenida Josep Tarradellas 96, 4º 3ª, 08029 Barcelona, o mediante correo electrónico a equipo@misoroban.com</span>
                </li>
                <li>
                    <h4>Contenidos</h4>
                    <span>MiSoroban se reserva el derecho de borrar en cualquier momento un contenido (fotografía, vídeo, texto, etc.) si estima que vulnera alguna ley vigente (derecho a la intimidad, propiedad intelectual, propiedad industrial, etc.).
                    <br /><br />En caso de que algún usuario publique contenido delictivo (pedófilo, racista, insultante, difamatorio,…), dicho contenido será borrado sin previo aviso y la cuenta del responsable será inmediata y definitivamente eliminada. Además, las infracciones causadas se comunicarán a las autoridades competentes.
                    <br /><br />La publicación en la plataforma de fotografías o vídeos en los que figuren otras personas que no sean el propio usuario registrado, requiere la autorización de dichas personas. Por ello, el usuario es responsable de contar con la debida autorización de aquellas personas respecto de las que publique una fotografía o vídeo en MiSoroban.
                    </span>
                </li>
                <li>
                    <h4>Cookies</h4>
                    <span>Este portal, al igual que otros portales en Internet, usa cookies para mejorar la experiencia del usuario. A continuación encontrará información sobre qué son las cookies, qué tipo de cookies utiliza este portal, cómo puede desactivar las cookies en su navegador y cómo desactivar específicamente la instalación de cookies de terceros. Si no encuentra la información específica que usted está buscando, por favor diríjase a equipo@misoroban.com</span>
                    <h4>¿Qué son las cookies?</h4>
                    <span>Las cookies son pequeños archivos que algunas plataformas, como las páginas web, pueden instalar en su ordenador, smartphone, tableta o televisión conectada. Sus funciones pueden ser muy variadas: almacenar sus preferencias de navegación, recopilar información estadística, permitir ciertas funcionalidades técnicas, etcétera. En ocasiones, las cookies se utilizan para almacenar información básica sobre los hábitos de navegación del usuario o de su equipo, hasta el punto, según los casos, de poder reconocerlo.</span>
                    <h4>¿Por qué son importantes?</h4>
                    <span>Las cookies son útiles por varios motivos. Desde un punto de vista técnico, permiten que las páginas web funcionen de forma más ágil y adaptada a sus preferencias, como por ejemplo almacenar su idioma. Además, ayudan a los responsables de los sitios web a mejorar los servicios que ofrecen, gracias a la información estadística que recogen a través de ellas. Finalmente, sirven para hacer más eficiente la publicidad que le mostramos, gracias a la cual le podemos ofrecer servicios de forma gratuita.</span>
                    <h4>¿Cómo utilizamos las cookies?</h4>
                    <span>Navegar por este portal supone que se puedan instalar los siguientes tipos de cookies:</span>
                    <ul>
                        <li>
                            <h4>Cookies de mejora del rendimiento</h4>
                            <span>Este tipo de cookies conserva sus preferencias para ciertas herramientas o servicios para que no tenga que reconfigurarlos cada vez que visita nuestro portal y, en algunos casos, pueden ser aportadas por terceros. Algunos ejemplos son: volumen de los reproductores audiovisuales, preferencias de ordenación de artículos o velocidades de reproducción de vídeo compatibles. En el caso de comercio electrónico, permiten mantener información sobre su cesta de la compra.
                                <br /><br />Nuestra web utiliza servicios de terceros que podrán instalar cookies para la mejora del rendimiento.
                            </span>
                        </li>
                        <li>
                            <h4>Cookies de análisis estadístico</h4>
                            <span>Son aquellas que, bien tratadas por nosotros o por terceros, permiten cuantificar el número de visitantes y analizar estadísticamente la utilización que hacen los usuarios de nuestros servicios. Gracias a ello podemos estudiar la navegación por nuestra página web, y mejorar así la oferta de productos o servicios que ofrecemos
                                <br /><br />Este análisis se realiza mediante herramientas que proporcionan terceros, de los que puede obtener más información sobre sus políticas de privacidad en: <br></br>Google Analytics
                            </span>
                        </li>
                        <li>
                            <h4>Cookies de geolocalización</h4>
                            <span>Estas cookies son usadas por programas que intentan localizar geográficamente la situación del ordenador, smartphone, tableta o televisión conectada, para de manera totalmente anónima ofrecerle contenidos y servicios más adecuados.</span>
                        </li>
                        <li>
                            <h4>Cookies de registro</h4>
                            <span>Cuando usted se registra en nuestro portal, se generan cookies propias que le identifican como usuario registrado e indican cuándo usted se ha identificado en el portal. Estas cookies son utilizadas para identificar su cuenta de usuario y sus servicios asociados. Estas cookies se mantienen mientras usted no abandone la cuenta, cierre el navegador o apague el dispositivo. Estas cookies pueden ser utilizadas en combinación con datos analíticos para identificar de manera individual sus preferencias en nuestro portal.</span>
                        </li>
                        <li>
                            <h4>Cookies publicitarias</h4>
                            <span>Son aquéllas que, bien tratadas por nosotros o por terceros, permiten gestionar eficazmente los espacios publicitarios de nuestro sitio web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Gracias a ella podemos conocer sus hábitos de navegación en internet y mostrarle publicidad relacionada con su perfil de navegación.
                                <br /><br />Las compañías con las que tenemos contratados servicios publicitarios tienen sus propias políticas de privacidad, que Vd. puede consultar en los siguientes enlaces:<br />Google Inc
                            </span>
                        </li>
                        <li>
                            <h4>Otras cookies de terceros</h4>
                            <span>En algunas de nuestras páginas se pueden instalar cookies de terceros que permitan gestionar y mejorar los servicios que éstos ofrecen. Un ejemplo de este uso son los enlaces a las redes sociales que permiten compartir nuestros contenidos.</span>
                        </li>
                        <li>
                            <h4>¿Cómo puedo configurar mis preferencias?</h4>
                            <span>Puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador de internet. En el caso en que las bloquee, es posible que ciertos servicios que necesitan su uso no estén disponibles para usted. A continuación le ofrecemos enlaces en los que encontrará información sobre cómo puede activar sus preferencias en los principales navegadores: </span>
                            <ul>
                                <li><span>Google Chrome</span></li>
                                <li><span>Mozilla Firefox</span></li>
                                <li><span>Internet Explorer</span></li>
                                <li><span>Safari</span></li>
                                <li><span>Safari para IOS (iPhone, iPad)</span></li>
                            </ul>
                        </li>
                        <li>
                            <h4>Cookies Flash</h4>
                            <span>Finalmente, puede usted dirigirse al portal Your Online Choices dónde además de encontrar información útil, podrá configurar, proveedor por proveedor, sus preferencias sobre las cookies publicitarias de terceros.</span>
                        </li>
                    </ul>
                    <span><br />Si desea contactar con nosotros respecto nuestra política de cookies, puede hacerlo en equipo@misoroban.com</span>
                </li>
                <li>
                    <h4>Seguridad</h4>
                    <span>MiSoroban ha adoptado las medidas de seguridad de índole técnica y organizativas necesarias que garantizan la seguridad de estos datos de carácter personal y que evitan su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana o del medio físico o natural.
                    <br /><br />No obstante, MiSoroban no asumirá responsabilidad alguna que pueda derivarse de problemas técnicos o errores en los servicios informáticos no imputables a MiSoroban, que se produzcan durante la conexión a la red de internet o que puedan ser causados por terceras personas mediante intromisiones ilegítimas que escapen a su control.
                    </span>
                </li>
                <li>
                    <h4>Registro de usuarios</h4>
                    <span>Para poder realizar publicaciones e interactuar con otros usuarios en MiSoroban, el usuario ha de estar registrado en la plataforma, para lo que tendrá que rellenar los campos obligatorios en el formulario de registro (nombre de usuario, email y contraseña). El resto de datos personales que aporte el usuario registrado para completar su perfil, son de carácter voluntario.
                    <br /><br />El usuario deberá leer cuidadosamente las presentes Condiciones de uso y privacidad.
                    <br /><br />El usuario es responsable de su propia cuenta en MiSoroban, por lo que solo él está autorizado al uso de la misma  y  es responsable de todas las actividades que se realicen con su cuenta de acceso al servicio.
                    <br /><br />El usuario entiende que MiSoroban no tiene por qué controlar ni aprobar el contenido que el usuario u otros usuarios publiquen o proporcionen al servicio. En lo que se refiere al contenido publicado o proporcionado por el usuario, este otorga el permiso (libre, ilimitado, mundial, no exclusivo y perpetuo) tanto a MiSoroban como a aquellos otros usuarios a los que haya concedido acceso, para usar, copiar, distribuir y mostrar el contenido publicado en MiSoroban en otros sitios web, incluidas redes sociales.
                    <br /><br />Además, el usuario entiende que es posible que MiSoroban necesite realizar copias, modificar el formato o procesar el contenido publicado en la plataforma, para:
                    <ul>
                            <li><br /><span>almacenar y recuperar el contenido;</span><br /><br /></li>
                            <li><span>poner el contenido a su disposición y a la de aquellos miembros del público a los que haya concedido acceso;</span><br /><br /></li>
                            <li><span>ajustarse a los requisitos técnicos de las redes de conexión</span><br /><br /></li>
                            <li><span>ajustarse a las limitaciones y condiciones del servicio.</span></li>
                        </ul>
                        <br /><br />Esta cláusula sólo se aplica al contenido permisible legalmente y sólo en la medida en que el uso y la publicación del mismo no infrinja la ley. El usuario entiende que el uso de contenido que infrinja los derechos de otros usuarios supone una infracción. El usuario garantiza que cuenta con todos los permisos necesarios para poder conceder los derechos, así como que el uso y publicación del contenido no infringe ninguna ley. MiSoroban no le ofrecerá pago alguno por su contenido y puede negarse a publicar su contenido y puede eliminarlo del servicio en cualquier momento.
                    </span>
                </li>
                <li>
                    <h4>Normas de uso</h4>
                    <span>El usuario se compromete a no realizar actividades ilícitas, ilegales, que constituyan un delito, o que infrinjan derechos propiedad intelectual e industrial. Las normas descritas a continuación explican el tipo de comportamiento que se espera tanto de los usuarios. Ten en cuenta que las siguientes normas no son exhaustivas y es posible que no cubran todas las formas de comportamiento ofensivo. Por lo tanto, los moderadores tomarán medidas en caso de tener conocimiento de cualquier tipo de comportamiento que consideren inapropiado.
                        <br /><br />El administrador y los moderadores de la plataforma tienen derecho a borrar y editar sin previo aviso cualquier contenido que incumpla las normas o pueda ser considerado inapropiado.
                        <br /><br />Los moderadores pueden borrar contenido (foto, comentario, video, noticia, etc..) siempre y cuando el usuario incumpla alguna de las normas citadas.
                        <br /><br />Los administradores de la plataforma pueden borrar contenido, suspender o cerrar cuentas y banear usuarios en caso de que observen la comisión de algún ilícito o el incumplimiento de las presentes normas.
                    </span>
                    <ol>
                        <li>
                            <h4>Sobre el contenido</h4>
                            <span>MiSoroban ofrece esta plataforma con el fin de que los usuarios interactúen entre ellos y puedan compartir online información, comentarios, imágenes, vídeos, etc., y se comuniquen entre ellos usando las aplicaciones de mensajería y chat que ofrecemos. Se entiende que el usuario es el único responsable de todas sus publicaciones (texto, imágenes, vídeos, etc.). MiSoroban no se hace responsable de los contenidos publicados por los usuarios y se entiende que no puede llegar a controlar todo el contenido publicado por todos los usuarios.
                                <br /><br />Así pues, como único responsable de su contenido publicado en MiSoroban, el usuario debe asegurarse de:
                                <ul>
                                    <li><br />NO publicar contenido ilegal, amenazante, difamatorio, racista, o cualquier otro contenido que incumpla la privacidad de otros o las leyes vigentes en el país donde resida o desde el que publique.</li>
                                    <li><br />NO publicar contenido sobre el que el usuario no tiene derechos (información privada, confidencial, secreta, …).</li>
                                    <li><br />NO publicar contenido que infrinja una patente, marca registrada, copyright u otros derechos de propiedad intelectual e industrial de otra entidad.</li>
                                    <li><br />NO publicar contenido mal intencionado de cualquier tipo</li>
                                    <li><br />NO trasmitir ni difundir material, software o código malicioso o que contenga virus o ficheros destinados a obtener acceso no autorizado a ordenadores o servidores de terceros, o interrumpir, destruir o limitar la funcionalidad de cualquier software.</li>
                                    <li><br />NO publicar contenido que pueda mostrar crueldad hacia los animales.</li>
                                    <li><br />NO acosar de ninguna manera a otras entidades sean públicas o privada u otro usuario del servicio.</li>
                                    <li><br />NO utilizar ni distribuir el contenido publicado por otros usuarios como propio.</li>
                                </ul>
                                <br />El usuario, como responsable de su contenido puede en cualquier momento decidir el borrado del mismo. No obstante, si otros usuarios han salvado, reenviado o posteado algún contenido, éste podrá seguir siendo accesible para el resto de los usuarios.
                                <br /><br />En caso de baja del servicio o expulsión de MiSoroban, todo su contenido será borrado excepto en los casos arriba indicados.
                            </span>
                        </li>
                        <li>
                            <h4>El usuario, además se compromete a:</h4>
                            <ul>
                                <li>NO difamar, amenazar, acosar, invadir la privacidad de otros usuarios.</li>
                                <li><br />NO difundir, transmitir o poner a disposición de terceros cualquier tipo de información, elemento o contenido que constituya publicidad ilícita o desleal.</li>
                                <li><br />NO publicitar en la web ningún otro sitio en internet de igual o similar temática a MiSoroban, enviar correo basura o cadenas, etc.</li>
                                <li><br />NO suplantar a otros usuarios mediante el uso de sus claves de acceso al servicio.</li>
                                <li><br />NO difundir el contenido de otros usuarios, ya sean comentarios, fotografías, vídeos, etc. y tratar con la máxima confidencialidad todos los datos compartidos por el resto de los usuarios.</li>
                            </ul>
                        </li>
                    </ol>
                </li>
                <li>
                    <h4>Enlaces a terceros</h4>
                    <span>En caso de hacer uso de enlaces a sitios web de terceros, informamos al usuario que pasará  a estar regido por los términos y condiciones de dichos sitios web.</span>
                </li>
                <li>
                    <h4>Legislación aplicable</h4>
                    <span>Las presentes condiciones legales y su interpretación se rigen de conformidad con el derecho español, y cualesquiera reclamaciones presentadas en el marco de estas condiciones estarán sometidas a los juzgados y tribunales de Barcelona.</span>
                </li>
            </ol>
        </div>
    )
}
export default TOS;