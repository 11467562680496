import ReactGA from 'react-ga';

declare let window: any;

export const initGA = () => {
    if (!window.cordova) {
        ReactGA.initialize('G-SZQYS23Z7B');
    }
}

export const recordPageGA = (page) => {
    if (!window.cordova) {
        ReactGA.pageview(page);
    }
}
