import { Collapse, Spin } from 'antd';
import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getMobileOperatingSystem, isValidActiveClient } from '../../utils';

import ModalLoader from '../../components/Course/ModalLoader';

import { loadResponses, saveResponses } from '../../modules/response';

import practices from '../../data/practices';

import showLevelUnits from '../../components/Course/LevelUnits';
import BottomMenu from '../../components/Menu';


import { Redirect, RouteComponentProps } from 'react-router';
import SectionHeader from '../../components/Utils/SectionHeader';
import { User } from '../../models/user';
import { lockPortrait, unlockScreen } from '../../utils/screen';

import SubscribeButton from '../../components/Subscription/SubscribeButton';
import { recordPageGA } from '../../modules/ga';
import '../Learn/index.less';
import './index.less';

const innerHeight = window.innerHeight;

interface IProps extends RouteComponentProps<any> {
  user: User;
  results: any;
  lang: string;
  saveUser: (user: User) => void;
  loadResponses: (activeChild: any) => void;
  saveResponses: (uid, ex, result) => void;
  isLoading: boolean;
  saved: boolean;
  error: boolean;
  transactions: any;
}

const LIMITLEVEL = 20;
const LIMITUNIT = 0;
const HASSUBSCRIPTION = false;


const rateUrl =
  getMobileOperatingSystem() == 'web'
    ? 'market://details?id=com.enmotionvalue.misoroban'
    : 'https://play.google.com/store/apps/details?id=com.enmotionvalue.misoroban';

const moveToScrollPoition = () => {
  const courseElem = document.querySelector('.course');
  const lastScrPos = localStorage.getItem("my-last-scroll-position");
  if (courseElem && lastScrPos) {
    // console.log('load lastScrPos:', lastScrPos);
    courseElem.scrollTop = parseInt(lastScrPos);
  }
}

const Practice = ({
  user,
  results,
  lang,
  saveUser,
  loadResponses,
  saveResponses,
  isLoading,
  saved,
  error,
  transactions
}: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [currentPlan, setcurrentPlan]: [any, any] = useState(null);
  const [hasSubscription, setHasSubscription]: [any, any] = useState(HASSUBSCRIPTION);
  const [limitUnit, setLimitUnit]: [any, any] = useState(LIMITUNIT);

  useEffect(() => {
    // console.log('Practice useEffect[]:', user);
    recordPageGA('/practice');
    /* const activeChild = user ? (user.isParent ? user.activeChild : user.id) : null;
          
    if (activeChild) {
      loadResponses(activeChild);
    } */

    if (user) {
      loadResponses(user.id);
      const age = user.age ? user.age : 1;
      const newPlan = JSON.parse(JSON.stringify(practices[age]));
      setcurrentPlan(newPlan);
    }

  }, []);

  useEffect(() => {
    const isActiveClient = isValidActiveClient(user);
    // console.log('Practice useEffect[transactions]:', transactions, user, isActiveClient);

    /* REAL */
    let hasSubscription = (transactions && transactions.length > 0) || isActiveClient;

    /* test NO SUBSCRIPTIONS */
    // hasSubscription = false;


    /* test with SUBSCRIPTIONS */
    // hasSubscription = true;


    setHasSubscription(hasSubscription);
    setLimitUnit(hasSubscription ? 0 : LIMITUNIT)
  }, [transactions]);


  useEffect(() => {
    // console.log('Practice useEffect[results]:', results);
    moveToScrollPoition();
  }, [results]);

  const showModal = cont => {
    setModalContent(cont);
    setModalVisible(true);
  };

  useEffect(() => {
    if (!modalVisible) unlockScreen();
  }, [modalVisible]);

  const handleOk = e => {
    // console.log('Practice.handleOk', e);
    setModalVisible(false);
  };

  const handleCancel = e => {
    // console.log('Practice.handleCancel', e);
    setModalVisible(false);
    lockPortrait();
  };

  const handleAccChange = panel => { };

  const userId = user ? user.id : null;
  const current_level = results ? results['current_level'] : null;
  const currentLevel = (current_level && currentPlan) ? currentPlan.find(aL => aL.level === current_level) : null;
  const activeKey = (currentLevel && currentLevel.open) ? (results && results['current'] ? results['current'] : '') : '';

  const divStyle: any = {
    // height: `${innerHeight - 53}px`,
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: '53px'
  };

  // console.log('Practice: current_level:', current_level);
  // console.log('Practice: trace....', isLoading, results, currentPlan);

  if (!user) return <Redirect to="/profile/login" />;
  else
    return (
      <section className='MiSoroban'>
        <ModalLoader
          title={modalTitle}
          visible={modalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          content={modalContent}
          className="exercise-modal"
        />

        <div style={divStyle} className="learn practice">
          <div className='mis-wrapper'>
            <SectionHeader section={'master'} />


            {isLoading ? <Spin size="large" className="spinner" /> : null}
            {!results || !currentPlan ? null : currentPlan.map((aP, idx) => {
              const current_level = results.current_level;

              return (
                <article className="level" data-level={idx + 1} key={idx}>
                  {aP.level == LIMITLEVEL && !hasSubscription ? (
                    <SubscribeButton lang={lang} show={hasSubscription} />
                  ) : null}

                  <Collapse
                    key={idx}
                    accordion
                    bordered={false}
                    defaultActiveKey={0}
                    onChange={handleAccChange}>
                    {showLevelUnits(
                      aP,
                      showModal,
                      handleCancel,
                      results,
                      saveResponses,
                      lang,
                      limitUnit,
                      (idx == 0 ? true : hasSubscription),
                      'practice'
                    )}
                  </Collapse>


                </article>
              );
            })}
          </div>
        </div>

        <BottomMenu section={'practice'} />
      </section>
    );
};

const mapStateToProps = ({ user, response, subscription }) => ({
  user: user.user,
  // results: user.results,
  results: response.data,
  isLoading: response.isFetching,
  lang: user.lang,
  products: subscription.products,
  transactions: subscription.transactions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadResponses,
      saveResponses
    },
    dispatch
  );

export default memo(connect(mapStateToProps, mapDispatchToProps)(Practice));
