const dbManager = (dbName, dbStore) => {

    // IndexedDB
    const indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB;
    const dbVersion = 1.0;

    // Create/open database
    let db = null;

    const init = function () {
        const dbPromise = new Promise((resolve, reject) => {
            // console.log("dbManager.init db:", db);

            if (db) {
                resolve('success');
            } else {
                const request = indexedDB.open(dbName, dbVersion);
                request.onerror = function (event) {
                    // console.log("Error creating/accessing IndexedDB database");
                    reject('"Error creating/accessing IndexedDB database"');
                };

                request.onsuccess = function (event) {
                    db = request.result;
                    // console.log("dbManager.init Success creating/accessing db:", db);
                    db.onerror = function (ev) {
                        // console.log("dbManager.init Error creating/accessing db:", event);
                        reject('dbManager.init Error creating/accessing db');
                    };

                    // Interim solution for Google Chrome to create an objectStore. Will be deprecated
                    if (db.setVersion) {
                        if (db.version != dbVersion) {
                            // console.log("db.version != dbVersion....");

                            var setVersion = db.setVersion(dbVersion);
                            setVersion.onsuccess = function () {
                                createObjectStore(db);
                            };
                        } else {
                            // console.log("db.version == dbVersion....");
                        }
                    } else {
                        // console.log("!db.setVersion....");
                    }

                    resolve('success');
                }

                // For future use. Currently only in latest Firefox versions
                request.onupgradeneeded = function (event) {
                    console.log("onupgradeneeded---");
                    createObjectStore(event.target.result);
                };
            }

        });

        return dbPromise;
    }

    const isReady = function () {
        const dbPromise = new Promise((resolve, reject) => {
            // console.log("dbManager.isReady db:", db);

            if (db) resolve(true);
            else init().then((ok) => {
                resolve(true);
            }).catch(err => { reject(false); });
        });
        return dbPromise;

    }

    const createObjectStore = function (dataBase) {
        // Create an objectStore
        // console.log("dbManager: Creating objectStore");
        dataBase.createObjectStore(dbStore);
    };

    const storeInDb = function (key, blob) {
        console.log("dbManager.storeInDb: Putting blob in IndexedDB:", key, blob, db);
        const dbPromise = new Promise((resolve, reject) => {

            // Open a transaction to the database
            const transaction = db.transaction([dbStore], 'readwrite');

            // Put the blob into the dabase
            const putObj = transaction.objectStore(dbStore).put(blob, key);

            // console.log("dbManager.storeInDb: putObj:", putObj);

            putObj.onsuccess = (event) => {
                resolve('success');
            };

            putObj.onerror = (event) => {
                reject('Error storing in db');
            };

        });

        return dbPromise;

    };

    const readFromDb = function (key) {
        // console.log("dbManager.readFromDb: Reading blob in IndexedDB:", key, db);

        const dbPromise = new Promise((resolve, reject) => {
            // Open a transaction to the database
            var transaction = db.transaction([dbStore], 'readonly');

            // Retrieve the file that was just stored
            const dbEv = transaction.objectStore(dbStore).get(key);

            dbEv.onsuccess = (event) => {
                const blob = event.target.result;
                // console.log("dbManager.readFromDb onsuccess: accessing", blob);
                if (blob) {
                    resolve(blob);
                } else {
                    reject(404);
                }

            };

            dbEv.onerror = (event) => {
                console.log("dbManager.readFromDb onerror:", event);
                reject('Error accesing element');
            }
        });

        return dbPromise;
    };


    return {
        db,
        init,
        isReady,
        storeInDb,
        readFromDb
    };
}

const dbMgr = dbManager('mis-files', 'koyu');

dbMgr.init().then((result) => {
    // console.log("dbMgr.init sucess!!!", result);
}).catch((err) => {
    // console.log("dbMgr.init eror!!!", err);
});

export default dbMgr;
