import React, { useEffect, useState } from 'react';
import { List, Button } from 'antd';
import { t } from '../../../languages';
import { NavLink } from 'react-router-dom';
import LangButtons from '../LangButtons';

const Language = ({ lang, handleSaveLanguage }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(lang);

    useEffect(()=>{
        setSelectedLanguage(lang);
    },[lang])

    return (
        <section className="welcome-language">

            <div className="buttons">
                <h2>{t(lang, 'language')}</h2>

                <LangButtons lang={selectedLanguage} handleChangeLang={(selLang) => handleSaveLanguage(selLang)} />
            </div>
            <NavLink to="/register/age" onClick={() => null}>
                <Button type="primary" className="button-next">
                    {t(lang, 'continue')}
                </Button>
            </NavLink>
        </section>
    )
};
export default Language;