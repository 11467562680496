import React, { useEffect, useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';
import Result from '../base/result';
import { genExerciseComponent } from '../../utils/componentwrapper';

import './index.less';
import { t } from '../../languages';


const Tests = ({ params, uid, saveResult, handleCancel, lang }) => {

  const [tid, setTid] = useState(0);
  const [exercises, setExercises] = useState([]);
  const [current, setCurrent] = useState(0);
  const [results, setResults] = useState([]);
  const [exerIds, setExerIds] = useState([]);
  const [times, setTimes] = useState([]);
  const [message, setMessage] = useState(null);
  const [timeout, setTimeout] = useState(false);
  const [showresults, setShowresults] = useState(false);
  const [coins, setCoins] = useState(0);
  const [seriesResults, setSeriesResults] = useState([]);
  const [series, setSeries] = useState([]);


  useEffect(() => {
    handleRepeat();

    return () => {
    };
  }, []);

  const handleRepeat = () => {
    // console.log('tests.handleRepeat:', params, uid);

    const exercises = params;
    const coins = exercises.reduce((acc, aE) => aE.params.coins + acc, 0);
    setExercises(exercises);
    setCoins(coins);

    setTid(0);
    setCurrent(0);
    setResults([]);
    setTimes([]);
    setMessage(null);
    setTimeout(false);
    setShowresults(false);
    setSeries([]);
    setSeriesResults([]);
    setExerIds([]);

  }

  const handleExerciseResult = (uid, ex, result) => {
    const num = exercises.length;
    // console.log('tests.handleExerciseResult:', uid, ex, result, '::', num, current);

    const lResults = [...results];
    lResults.push(result.p ? 1 : 0);

    const lExerIds = [...exerIds];
    lExerIds.push(result.id);

    const lSeriesResults = [...seriesResults];
    lSeriesResults.push(result.r ? result.r : [1]);

    const lSeries = [...series];
    lSeries.push(result.s ? result.s : [1]);


    setResults(lResults);
    setExerIds(lExerIds);
    setSeriesResults(lSeriesResults);
    setSeries(lSeries);
    setCurrent(current + 1);

    if (current >= num - 1) {
      const hits = lResults.reduce(((acc, aR) => acc += aR), 0);
      const errors = num - hits;
      const passed = errors > 0 ? 0 : 1;

      // console.log('tests.handleExerciseResult to save:', lResults, lSeries, lSeriesResults);

      saveResult(uid, uid, { uid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), r: lResults, s: lSeries, sr: lSeriesResults, ex: lExerIds });
      setShowresults(true);
    }

  }

  const currentExer = exercises[current];
  const exerParams = currentExer ? { ...currentExer.params, is_test: true } : null;

  const rate = results[current];
  const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
  const num = exercises.length;
  const percent = parseInt((current) / num * 100);

  // console.log('Tests.render:', props, this.state, percent, currentExer);
  // console.log('Tests.results-series:', results, series, seriesResults, exerIds);

  if (!showresults) {
    return (
      <Row className="exercise">
        <Col span={22} className='progress-bar'>
          <Progress percent={percent} showInfo={false} />
        </Col>

        <Col span={24} className="test-body" key={current}>
          {currentExer ? genExerciseComponent(currentExer.impl, uid, current, exerParams, handleCancel, handleExerciseResult, lang) : null}
        </Col>

      </Row >
    );
  } else {
    const lParams = { ...params, num: exercises.length, errors: 0 }

    return (
      <Result
        uid={uid}
        params={lParams}
        results={results}
        handleRepeat={handleRepeat}
        handleCancel={handleCancel}
        is_test={true}
        lang={lang}
      />
    );
  }
};

export default Tests;
