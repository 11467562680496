import React, { useEffect, useRef, useState } from 'react';

import { generateRandomNumber } from '../../../utils';

import { generateRandomNumberSeries } from '../../../utils';
import './index.less';
import { generatePath } from './pathutils';

const MAX_ROWS = 5;
const MAX_COLS = 4;


const PathGrid = props => {
  const [items, setItems] = useState([]);
  const [time, setTime] = useState(0);
  const [good, setGood] = useState(false);
  const [bad, setBad] = useState(false);
  const [remaining, setRemaining] = useState(null);
  const [current, setCurrent] = useState(0);
  const [cols, setCols] = useState(null);
  const [serie, setSerie] = useState(null);
  const [showPath, setShowPath] = useState(false);

  const containerRef = useRef();

  useEffect(() => {
    // console.log('PathGrid.useEffect:', props);
    const value = props.value;
    const num_items = props.items;
    const lSerie = [generateRandomNumber(props.init, props.finish)];
    for (let idx = 1; idx < num_items; idx++) {
      lSerie.push(lSerie[idx - 1] + value);
    }

    // console.log('lSerie init:', lSerie);

    const COLS = props.cols ? props.cols : MAX_COLS;
    const initX = props.initpos ? props.initpos.x : Math.floor(COLS / 2) - 1;
    const initY = props.initpos ? props.initpos.y : Math.floor(MAX_ROWS / 2);

    let lItems = [];
    for (let ydx = 0; ydx < MAX_ROWS; ydx++) {
      const aRow = [];
      const numbers = generateRandomNumberSeries(COLS, props.init, props.finish, [props.value]);
      for (let xdx = 0; xdx < COLS; xdx++) {
        // const isSelected = xdx == initX && ydx == initY;
        aRow.push({ val: numbers[xdx], x: xdx, y: ydx });
      }

      lItems.push(aRow);
    }

    // console.log('lItems:', lItems);
    generatePath(lSerie, lItems, initX, initY);
    // console.log('lItems | lSerie:', lItems, '|', lSerie);
    setItems(lItems);
    setSerie(lSerie);

    const date = new Date();
    setTime(date.getTime());
    setCurrent(0);
    setRemaining(num_items - 1);

    setCols(props.cols);
    // setShowPath(props.showPath);
  }, []);

  useEffect(() => {
    // ...
    setShowPath(props.showPath);

    return () => {
      // ...
    };
  }, [props]);

  const clickHandle = (idx, item) => {
    // console.log('PathGrid.clickHandle:', idx, item, serie, current, remaining);
    const date = new Date();

    if (!item.selected) {

      const nextValue = remaining ? serie[current + 1] : serie[current];
      if (item.val === nextValue) {
        setGood(true);
        setBad(false);

        const lItems = [...items];
        lItems[item.y][item.x].selected = true;
        setItems(lItems);
        setCurrent(current + 1);
        const lRemaining = remaining - 1;
        setRemaining(lRemaining);

        if (lRemaining <= 0) {
          // console.log('Completed!!!');
          setTimeout(() => {
            props.onComplete(true, date.getTime() - time);
          }, 200);
        }

      } else {
        const lItems = [...items];
        lItems[item.y][item.x].error = true;
        setItems(lItems);
        setGood(false);
        setBad(true);
        // console.log('Failed !!!');
        setTimeout(() => {
          props.onComplete(false, date.getTime() - time);
        }, 200);
      }

      setTimeout(() => {
        setGood(false);
        setBad(false);
      }, 500);
    }
  };


  return (
    <div className={`pathgrid ${!remaining ? 'completed' : ''}`} ref={containerRef}>

      {good ? (<audio src="./static/sounds/pop.mp3" autoPlay={true} loop={false}></audio>) : null}
      {bad ? (<audio src="./static/sounds/jump.mp3" autoPlay={true} loop={false}></audio>) : null}

      {!remaining || showPath ? <div className='pathchall-over'></div> : null}

      {items
        ? items.map((aRow, ydx) => {
          return (
            <div key={ydx} className="pathgrid-row">
              {aRow.map((aI, idx) => {
                const val = aI.val;
                let digits = val.toString().split('');
                let itemStyle = 'pathgrid-item';
                if (aI.selected) itemStyle += ' selected';
                if (showPath && aI.ispath) itemStyle += ' showpath';
                if (aI.error) itemStyle += ' error';

                return (
                  <div key={idx + '-' + ydx} className={itemStyle} onClick={() => clickHandle(idx, aI)}>
                    {digits.map((el, edx) => (
                      <div key={edx + '-' + idx + '-' + ydx}>
                        <img className="pabacus" src={`./static/snapshots/a${el}.png`} alt="abacus" />
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          );
        })
        : null}
    </div>
  );
};

export default PathGrid;
