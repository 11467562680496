import React from 'react'
import Bead from './Bead';

import './index.less';

const formatHeaderMarkers = (markersvals, hidelatframe) => {

    if (markersvals) {

        const marks = markersvals['marks'].map((aMarker, idx) => {
            return (<td key={idx} colspan={aMarker[0]}><div id={aMarker[1] ? 'mrk[' + idx + ']' : ''} className={aMarker[1] ? 'marked' : ''}>{aMarker[1].indexOf('result') >= 0 ? 'Result' : ''}</div></td>);
        });

        return (<tr className="headmarkers">
            {hidelatframe ? '' : <td></td>}
            {marks}
            {hidelatframe ? '' : <td></td>}
        </tr>);
    } else { return null; }
}

const ROWS = 17;
const abacusheaders = { 0: 'U', 1: 'D', 2: 'C', 3: 'UM', 4: 'DM', 5: 'CM', 6: 'Um', 7: 'Dm', 8: 'Cm', 9: 'UMm', 10: 'DMm', 11: 'CMm', 12: 'Ub', 13: 'Db', 14: 'Cb', 15: 'UMb', 16: 'DMb', 17: 'CMb', 18: 'Ut', 19: 'Dt', 20: 'Ct', 21: 'UMt', 22: 'DMt', 23: 'CMt', 24: 'Uc', 25: 'Dc', 26: 'Cc', 27: 'UMc', 28: 'CMc', 29: 'Uq' };

const Abacus = (props) => {
    //console.log('Abacus:', props);
    const thenumber = props.number || 0;
    const therows = props.rows || ROWS;
    const therowvalues = props.rowValues || Array(ROWS).fill(0);
    const rowcolor = props.rowcolor || 0;
    const markselected = props.markselected || true; // to mark in gray or blue
    const hidelatframe = props.hidelatframe!=null? props.hidelatframe : true;
    const hideheaderlabels = props.hideheaderlabels!=null?props.hideheaderlabels : true;
    const isactive = props.isactive!=null ? props.isactive : true;
    const prefix = props.prefix || '';
    const cssstyle = props.cssstyle || '';
    const markersvals = props.markersvals || null;
    const clickHandler = props.clickHandler;
    const numcolors = props.numcolors || 3;
    const inactiverows = props.inactiverows || null;
    const stillrows = props.stillrows || null;
    const showNumbers = props.showNumbers || false;

    const rows = therows > 30 ? 30 : therows;
    let idfirstblue = 0;

    // console.log('Abacus:', thenumber, therowvalues, '::',rows, isactive, hideheaderlabels, hidelatframe , '::', rowcolor, markersvals)

    const headerrow = [];
    const upperrow = [];
    const pointsrow = [];
    const bottomrow = [];
    const footrow = [];

    const rvlength = therowvalues.length;
    for (let r = 0; r < rvlength; r++) {
        const isStill = stillrows && stillrows.includes(r);

        const isInactive = inactiverows && inactiverows.includes(r);
        const inactiveClass = isInactive ?' gris nomove ':'';

        const rownumbertoshow = !isInactive ? therowvalues[r] : 0;
        const rownumbertoshowbottom = rownumbertoshow < 5 ? rownumbertoshow : (rownumbertoshow - 5);


        /* has value fo each bead */
        const hasValue1 = ((therowvalues[r]>=1 && therowvalues[r]<=4) || therowvalues[r]>=6) && !isInactive;
        const hasValue2 = ((therowvalues[r]>=2 && therowvalues[r]<=4) || therowvalues[r]>=7) && !isInactive;
        const hasValue3 = ((therowvalues[r]>=3 && therowvalues[r]<=4) || therowvalues[r]>=8) && !isInactive;
        const hasValue4 = (therowvalues[r]==4 || therowvalues[r]==9) && !isInactive;
        const hasValue5 = therowvalues[r]>=5 && !isInactive;

        /* rows classes for marked, inactive, etc */
        const  isMarked = markersvals ? markersvals['classNamees'][r] : false;
        

        const mrkclass = markselected || isMarked === 0 ? ' gris ' + (isMarked === 0 ? 'nomove ' : '') : '';
        const mrkclass2 = markselected || isMarked === 0 ? (rownumbertoshowbottom >= 2 ? '' : (' gris ' + (isMarked === 0 ? 'nomove ' : ''))) : '';
        const mrkclass3 = markselected || isMarked === 0 ? (rownumbertoshowbottom >= 3 ? '' : (' gris ' + (isMarked === 0 ? 'nomove ' : ''))) : '';
                
        const addedClass =  mrkclass + inactiveClass;
        const addedClass2 = mrkclass2 + inactiveClass;
        const addedClass3 = mrkclass3 + inactiveClass;

        /* position of row color */
        let beadcolor = 'bead4';
        const posInitRowColor = rvlength - rowcolor - numcolors;
        const addColor = r >= posInitRowColor && r < (posInitRowColor + numcolors);
        if (addColor) beadcolor = 'bead' + (posInitRowColor - r + numcolors); 
        else beadcolor = 'bead4';

        if (beadcolor === 'bead1' && !idfirstblue) idfirstblue = (prefix) + 'upper[' + r + '][5]'; // start showing headers        
        const textrow = (r >= (rowcolor - 1)) ? abacusheaders[r - rowcolor + 1] : '';
        

        /*Creamos los tds*/
        headerrow.push(<td key={r}>{(textrow ? <span className="nh">{textrow}</span> : null)}</td>);

        upperrow.push(<td key={r}>
            <Bead
                handleMove={(e) => { clickHandler(e, r, rownumbertoshow >= 5 ? -5 : 5, isInactive || isStill) }} 
                id={(prefix) + 'upper[' + r + '][5]'} 
                className={'bead ' + beadcolor + ' ' + (rownumbertoshow >= 5 ? 'poner' : ('first' + addedClass))}
                isUpper={true}                
                active={!(isInactive || isStill)}
                hasValue={hasValue5}
                position = {5}
                row = {r}
                rowValue = {therowvalues[r]}
            />
        </td >);

        pointsrow.push(<td key={r}>{((r + 1) % 3 === 0 && rows > 3 ? (<img alt="" src="./static/abacus/point.png" />) : (<span className="pointw"></span>))}</td>);

        bottomrow.push(<td key={r}>
            <Bead
                handleMove={(e) => { clickHandler(e, r, rownumbertoshowbottom === 1 ? -4 : 1, isInactive || isStill) }}
                className={'bead ' + beadcolor + ' ' + (rownumbertoshowbottom ? '' : ('first' + addedClass))}
                active={!(isInactive || isStill)}
                hasValue={hasValue1}
                position = {1}
                row = {r}
                rowValue = {therowvalues[r]}
                />
            <Bead
                handleMove={(e) => { clickHandler(e, r, rownumbertoshowbottom === 2 ? -3 : 2, isInactive || isStill) }}
                className={'bead ' + (rownumbertoshowbottom === 1 ? 'ponsup ' : '') + beadcolor + addedClass2}
                active={!(isInactive || isStill)}
                hasValue={hasValue2}
                position = {2}
                row = {r}
                rowValue = {therowvalues[r]}
                />
            <Bead
                handleMove={(e) => { clickHandler(e, r, rownumbertoshowbottom === 3 ? -2 : 3, isInactive || isStill) }}
                className={'bead ' + (rownumbertoshowbottom === 2 ? 'ponsup ' : '') + beadcolor + addedClass3}
                active={!(isInactive || isStill)}
                hasValue={hasValue3}
                position = {3}
                row = {r}
                rowValue = {therowvalues[r]}
                />
            <Bead
                handleMove={(e) => { clickHandler(e, r, rownumbertoshowbottom === 4 ? -1 : 4, isInactive || isStill) }}
                className={'bead ' + (rownumbertoshowbottom === 3 ? 'ponsup ' : 'last ') + beadcolor + (rownumbertoshowbottom === 4 ? ' lastup ' : addedClass)}
                active={!(isInactive || isStill)}
                hasValue={hasValue4}
                position = {4}
                row = {r}
                rowValue = {therowvalues[r]}
                />
        </td >);

        footrow.push(<td key={r}>{!isInactive?rownumbertoshow:null}</td>);
    }


    return (
        <div id="showabacus">
            {isactive ? null : <div className="inactiveabacus"></div>}

            <table id={prefix + 'abacus'} className={cssstyle}>
                <thead>
                    {formatHeaderMarkers(markersvals, rows, hidelatframe)}
                    {!hideheaderlabels?<tr className="labels">
                        {!hidelatframe ? <td className="lm left"></td> : null}
                        {headerrow}
                        {!hidelatframe ? <td className="lm right"></td> : null}
                    </tr>:null}
                </thead >
                <tbody>
                    <tr className="upper">
                        {!hidelatframe ? <td className="lm left"></td> : null}
                        {upperrow}
                        {!hidelatframe ? <td className="lm right"></td> : null}
                    </tr>
                    <tr className="points">
                        {!hidelatframe ? <td className="lm left"></td> : null}
                        {pointsrow}
                        {!hidelatframe ? <td className="lm right"></td> : null}
                    </tr>
                    <tr className="bottom">
                        {!hidelatframe ? <td className="lm left"></td> : null}
                        {bottomrow}
                        {!hidelatframe ? <td className="lm right"></td> : null}
                    </tr>
                </tbody>
                {showNumbers?<tfoot>
                    <tr>
                        {!hidelatframe ? <td></td> : null}
                        {footrow}
                        {!hidelatframe ? <td></td> : null}
                    </tr>
                </tfoot>:null}
            </table>
        </div>
    );

}

export default Abacus;
