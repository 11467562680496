import React from 'react'

import { Row, Col, Button, Progress } from 'antd';
import Couples from '../../base/Couples';
import Result from '../../base/result';

import { generateRandomNumberSeries } from '../../../utils';

import './index.less';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';

class MemoSet extends React.Component {
    state = {
        eid: 0,
        series: [],
        current: 0,
        results: [],
        times: [],
        message: null,
        message2: null,
        showresults: false,
        timeout: false,
        level: null
    }

    componentDidMount() {

        this.handleRepeat();
    }

    handleRate = (timereq) => {
        const results = this.state.results;
        const times = this.state.times;
        const current_time = timereq;
        const current_result = 1;
        const message = `${t(this.props.lang, 'bravoin')} ${parseInt(current_result / 1000)} ${t(this.props.lang, 'seconds')}`;
        const message2 = current_result ? t(this.props.lang, 'great') : t(this.props.lang, 'nodiscorage');
        results.push(current_result);
        times.push(current_time);
        this.setState({
            results,
            times,
            message, message2
        });
    }

    handleNext = () => {
        const current = this.state.current;

        if (current < this.state.series.length - 1) {
            const series = this.state.series;
            const level = series[current + 1];
            // console.log('handleNext level:', level);

            this.setState({
                current: current + 1,
                message: null,
                message2: null,
                ready: false,
                timeout: false,
                level: level
            });

            setTimeout(() => { this.setState({ timeout: true }) }, level.time * 1000);
        } else {
            const num = this.props.params.num;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > this.props.params.errors ? 0 : 1;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            const times = this.state.times;
            const totalTime = times.reduce((acc, aT) => acc += aT, 0);

            this.props.saveResult(uid, `${uid}-${eid}`, { id: 'MS', uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), time: totalTime, r: this.state.results, s: this.state.series }, times);
            this.setState({ showresults: true });
        }
    }

    handleRepeat = () => {
        const params = this.props.params;

        let series = [];
        const configSeries = params.series;

        const couples = params ? params.couples : null;
        // console.log('configSeries couples:', configSeries, couples);

        if (configSeries) {
            for (let idx = 0; idx < configSeries.length; idx++) {
                const aSerie = configSeries[idx];
                series.push(generateRandomNumberSeries(aSerie.num, aSerie.init, aSerie.finish));
            }
        } else {
            if (couples === 'random') {
                series = [generateRandomNumberSeries(params.num, params.init, params.finish)];
            } else {
                series = couples;
            }
        }

        // console.log('series:', series);

        const level = series[0];

        // console.log('level:', level);

        this.setState({
            series: series,
            current: 0,
            results: [],
            times: [],
            message: null,
            message2: null,
            showresults: false,
            ready: false,
            timeout: false,
            level: level
        });
        setTimeout(() => { this.setState({ timeout: true }) }, level.time * 1000);

    }

    handleComplete = (timereq) => {
        this.handleRate(timereq);
    }

    render() {
        const props = this.props;
        const message = this.state.message;
        const message2 = this.state.message2;

        const rate = this.state.results[this.state.current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((this.state.current) / this.state.series.length * 100);
        const is_test = props.params.is_test ? props.params.is_test : false;

        const current = this.state.current;
        const level = this.state.level;
        // console.log('MemoSet.render:', this.state, percent);

        const params = { ...props.params, num: this.state.series.length, show_time: true };

        if (!showresults) {
            if (!level) return null;
            else return (
                <Row className="exercise">
                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    {!message ? <Col span={24} className="exercise-body memo-body">
                        {/* <ExerciseBlocker show={messagestyle} /> */}
                        <section className="memoset">
                            <article className="couples">
                                {!message ? <Couples key={current}
                                    showBack={true}
                                    type={'abacus'}
                                    items={props.params.num}
                                    beads={1}
                                    series={level}
                                    onComplete={this.handleComplete} /> : null}
                            </article>
                        </section>
                    </Col> : <Col span={24}>
                        <Row className={'botones memoset' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>
                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                            {message ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                        </Row>
                    </Col>}
                </Row >
            );
        } else {
            return (
                <Result params={params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
            );
        }
    };
}

export default MemoSet;


