import React from 'react';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import Item from 'antd/lib/list/Item';

/*
    BoxItem - a thing that appears in a box, after you drag something into the box
*/

export default class BoxItem extends React.Component {
  // the things that appear in the boxes

  handleDrop = (e) => {
    // console.log('BoxItem.onHit:', e)
    e.stopPropagation();
    this.props.swap(e.dragData.index, this.props.index, e.dragData);
    e.containerElem.style.visibility = "hidden";
  };

  deleteMe = (e) => {
    // console.log('BoxItem.onDrop:',e)
    this.props.kill(this.props.uid);//control copy
  };

  render() {
    // console.log('BoxItem.render:',this.props)
    const props = this.props;
    const itemTarget = props.itemTarget ? props.itemTarget : 'boxItem'

    return (
      <div className="box_item_component" >
        <DragDropContainer
          targetKey={itemTarget}
          dragData={{ label: props.children, img: props.img, value: props.value, index: props.index }}
          onDrop={this.deleteMe}
          /* onDragStart={()=>{console.log('BoxItem moving...', itemTarget);}} */
          disappearDraggedElement={true}
          dragHandleClassName="grabber"
        >
          <DropTarget
            onHit={this.handleDrop}
            targetKey={itemTarget}
          >
            <div className="outer">
              <div className="item">
                <img className="grabber" src={this.props.img} alt={Item.label} />
              </div>
            </div>
          </DropTarget>
        </DragDropContainer>
      </div>
    );
  }
}