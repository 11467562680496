import { generateRandomNumber } from "../../../utils";

export const generatePath = (serie, items, initX, initY) => {
    const deltaOps = [{ dx: 0, dy: -1 }, { dx: 1, dy: 0 }, { dx: 0, dy: 1 }, { dx: -1, dy: 0 }];
    const prevPositions = [{ x: initX, y: initY }];

    items[initX][initY].val = serie[0];
    items[initX][initY].selected = true;
    items[initX][initY].ispath = true;

    for (let idx = 1; idx < serie.length; idx++) {
        const prevDelta = [];
        for (let p = 0; p < 20; p++) {
            //   console.log('generatePath prevPositions: iter:', idx, prevPositions);

            let delta = deltaOps[Math.floor(Math.random() * deltaOps.length)];
            while (prevDelta.find(aD => aD.dx === delta.dx && aD.dy === delta.dy)) {
                delta = deltaOps[Math.floor(Math.random() * deltaOps.length)];
            }

            if (!prevPositions[idx - 1]) {
                serie = serie.slice(0, idx);
                return; // not previous elem generate, break!
            }

            const newX = prevPositions[idx - 1].x + delta.dx;
            const newY = prevPositions[idx - 1].y + delta.dy;

            const aPos = prevPositions.find(aP => aP.x === newX && aP.y === newY);
            //   console.log('generatePath newPos: iter:', idx, aPos,'::', newX, newY, prevPositions);

            if (!aPos && !(newX < 0 || newY < 0 || newX >= items.length || newY >= items[0].length)) {
                prevPositions.push({ x: newX, y: newY });
                items[newX][newY].val = serie[idx];
                items[newX][newY].selected = false;
                items[newX][newY].ispath = true;

                break;
            }
        }
    }

    // console.log('generatePath prevPositions:', prevPositions);
};

export const generateForwardPath = (serie, items, initX, initY, endX, endY, min, max) => {
    const deltaOps = [{ dx: 1, dy: 0 }, { dx: 0, dy: 1 }, { dx: -1, dy: 0 }];
    const prevPositions = [{ x: initX, y: initY }];

    items[initX][initY].val = serie[0];
    items[initX][initY].selected = true;
    items[initX][initY].ispath = true;

    let curPos = { x: initX, y: initY };
    let idx = 1;

    while (curPos.x != endX || curPos.y != endY) {

        const prevDelta = [];
        if (!prevPositions[idx - 1]) break;

        for (let p = 0; p < 20; p++) {
            // console.log('generateForwardPath prevPositions: iter:', idx, prevPositions);

            let delta = deltaOps[Math.floor(Math.random() * deltaOps.length)];
            while (prevDelta.find(aD => aD.dx === delta.dx && aD.dy === delta.dy)) {
                delta = deltaOps[Math.floor(Math.random() * deltaOps.length)];
            }

            const newX = prevPositions[idx - 1].x + delta.dx;
            const newY = prevPositions[idx - 1].y + delta.dy;

            const aPos = prevPositions.find(aP => aP.x === newX && aP.y === newY);
            //   console.log('generateForwardPath newPos: iter:', idx, aPos,'::', newX, newY, prevPositions);

            if (!aPos && !(newX < 0 || newY < 0 || newX >= items.length || newY >= items[0].length)) {
                const newVal = generateRandomNumber(min, max);

                serie.push(newVal);

                prevPositions.push({ x: newX, y: newY });
                items[newX][newY].val = newVal;
                items[newX][newY].selected = false;
                items[newX][newY].ispath = true;
                
                curPos = { x: newX, y: newY };

                break;
            }
        }

        idx++;
        // console.log('generateForwardPath curPos: ', curPos, '::', endX, endY);

    }

    return curPos;

    // console.log('generatePath prevPositions:', prevPositions);
};