import { useEffect } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from '../../../languages';
import { loadRanking } from '../../../modules/user';
import { formatMS2MMSS } from '../../../utils';
import TutorManager from '../../TutorManager';
import './index.less';


const SectionHeader = ({ section, lang, user, today_time, loadRanking, ranking }) => {

  // console.log('SectionHeader user:', user, ranking);

  useEffect(() => {
    // loadRanking();

    return () => {
    }

  }, []);

  useEffect(() => {
    // loadRanking();
  }, [user.points]);

  const isProfile = section === 'profile';
  const profileStyle = section === 'profile' ? { marginRight: 0 } : null;

  return (<>

    {!isProfile ? <TutorManager section={section} /> : null}

    <section className="levels-header-logo">
      <div className="inner-logo">
        <img className='logo' src="./static/logo_mis.png" alt="" style={profileStyle} />
        {section !== 'profile' ? <div className="levels-header-points-time">
          <span className='time'>{formatMS2MMSS(today_time)}</span>
          {section !== 'course' && section !== 'resources' ? <span className='points'><img src="./static/myspace/coin.svg" alt="" /> {user.points}</span> : null}
        </div> : null}
      </div>
    </section>
    <section className="levels-header-title">
      <h1>{t(lang, section)}</h1>
    </section>
  </>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  lang: user.lang,
  ranking: user.ranking,
  today_time: user.today_time,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadRanking
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SectionHeader);
