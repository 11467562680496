import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';

import './index.less';
import { StarOutlined } from '@ant-design/icons';
import SubscriptionOptions from './SubscriptionOptions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { purchase, BASEPRODUCTS, loadSubscriptions } from '../../modules/subscription';
import { t } from '../../languages';
import { getMobileOperatingSystem } from '../../utils';
import WebSubscriptionButton from './WebSubscriptionButton';

const SubscribeButton = ({ lang, user, products, transactions, purchase, loadSubscriptions }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // console.log('SubscribeButton useEffect[]:', products, transactions);
        const os = getMobileOperatingSystem().toLocaleLowerCase();
        if (/* os=='ios' && */ !products) loadSubscriptions();
    }, []);


    const purchaseOffer = (position) => {
        if (products && products.length > position) {
            purchase(products[position]);
        }
    }

    // console.log('SubscribeButton render:', products, transactions);

    if (transactions && transactions.length > 0) {
        return null;
    } else {
        if (!window.cordova) {
            return <WebSubscriptionButton lang={lang} user={user} />;
        } else return <section className="subscribe-button">
            <div className='header'><img src={'./static/subscription/crown.svg'} /></div>
            <Button onClick={() => setIsModalOpen(true)} className="activar">{/* <StarOutlined /> */} {t(lang, 'subscribetoactivate')} {/* <StarOutlined /> */}</Button>
            <div className="try"><strong>{t(lang, 'try3days')}</strong><br /> {t(lang, 'cancelwhenyouwant')}</div>

            <Modal
                title={t(lang, 'subscribe')}
                className='subscription-modal'
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                centered
                footer={null}
            >
                <SubscriptionOptions lang={'lang'} />
            </Modal>
        </section>;
    }

};

const mapStateToProps = ({ user, subscription }) => ({
    user: user.user,
    version: user.app_version,
    lang: user.lang,
    products: subscription.products,
    transactions: subscription.transactions
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            purchase,
            loadSubscriptions
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeButton);


