import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Input, notification, Spin } from 'antd';

import { t } from '../../../languages/index';

import { User } from '../../../models/user';

const openNotificationWithIcon = (type, message, description, closeCallback) => {
    notification[type]({
        message: message,
        description: description,
        duration: 2,
        key: 'registernotification',
        onClose: closeCallback
    });
};

const JoinClass = ({ lang, user, join, isLoading, saved, error }) => {

    if (error) openNotificationWithIcon('error', 'No ha sido posible unirse a la clase. Revisa el código', '', () => { });

    const activeChild = user ? (user.isParent ? user.children.find(aC => aC.id == user.activeChild) : user) : null;
    const [name, setName] = useState(activeChild ? activeChild.name : '');
    const [code, setCode] = useState('');
    const [isOk, setIsOk] = useState(false);
    const [next, setNext] = useState(false);

    useEffect(() => {
        // console.log('name, email, country, tos:', name, email, country, tos);
        const isOk: any = name && code;
        setIsOk(isOk);
    }, [name, code]);

    const saveStep = () => {
        const aChild = user ? (user.isParent ? user.children.find(aC => aC.id == user.activeChild) : user) : new User();
        if (aChild) {
            aChild.name = name;
            join(aChild, user, code);
        } else {
            openNotificationWithIcon('error', 'No ha sido posible unirse a la clase.', '', () => { });
        }
    }


    if (saved) return <Redirect to="/homework" />
    else return (
        <section className="WellMessage">
            {isLoading ? <Spin size="large" className="spinner" /> : null}
            <h2>Bienvenidos/as</h2>
            <p>Indica el código de la clase y tu nombre para unirte</p>
            <form>
                <div>
                    <Input placeholder="Código de la clase" value={code} onChange={(e) => setCode(e.target.value)} />
                </div>
                <div>
                    <Input placeholder="¿Cómo te llamas?" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div>
                    <Button type="primary" disabled={!isOk} onClick={saveStep}>Continuar</Button>
                </div>
            </form>

        </section>
    );
};
export default JoinClass;
