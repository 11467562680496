import { useEffect, useState } from 'react';


import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';
import { generateRandomNumberSeries } from '../../../utils';

import './index.less';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';


const MentalAbacusFixation = ({ lang, params, uid, eid, saveResult, handleCancel }) => {

    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [times, setTimes] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);
    const [ready, setReady] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        handleRepeat();
    }, []);

    const handleReady = () => {
        setReady(true);
        setTimeout(() => {
            setIsTimeout(true);
        }, 5000);

    }

    const handleRate = () => {
        const currentNumber = series[current];
        const current_result = currentNumber === numberinbox ? 1 : 0;
        const message = current_result ? t(lang, 'rightanswer') : `${t(lang, 'yourwrong')} ${currentNumber}`;
        const message2 = current_result ? t(lang, 'great') : t(lang, 'nodiscorage');
        results.push(current_result);

        setResults(results);
        setMessage(message)
        setMessage2(message2)
    }

    const handleNext = () => {
        if (current < series.length - 1) {
            setCurrent(current + 1);
            setNumberinbox(0);
            setMessage(null);
            setMessage2(null);
            setMoved(0);
            setReady(false);
            setIsTimeout(false);
        } else {
            const num = params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > params.errors ? 0 : 1;
            const coins = params.coins;

            saveResult(uid, `${uid}-${eid}`, { id: 'MA', uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), r: results, s: series });
            setShowresults(true);
        }
    }

    const handleRepeat = () => {
        let series = [];
        const configSeries = params.series;
        // console.log('configSeries:', configSeries);
        if (configSeries) {
            for (let idx = 0; idx < configSeries.length; idx++) {
                const aSerie = configSeries[idx];
                series = series.concat(generateRandomNumberSeries(aSerie.num, aSerie.init, aSerie.finish, null));
            }
        } else {
            series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        }

        console.log('series:', series);

        setSeries(series)
        setCurrent(0);
        setNumberinbox(0);
        setResults([]);
        setMessage(null);
        setMessage2(null);
        setShowresults(false);
        setMoved(0);
        setReady(false);
        setIsTimeout(false);
    }

    const handleChange = (num) => {
        setNumberinbox(parseInt(num));
        setMoved(1)
    }

    /* To render */
    const currentNumber = series[current];
    const rate = results[current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const percent = parseInt((current) / params.num * 100);

    const is_test = params.is_test || false;
    const stillrows = !ready ? Array.from(Array(params.rows).keys()) : [];

    const locparams = { ...params, number: currentNumber, stillrows };
    // console.log('MentalAbacusFixation.render:', ready, isTimeout);
    // console.log('MentalAbacusFixation:', current, currentNumber, series, locparams, isTimeout);

    if (!showresults) {
        return (
            <Row className="exercise">
                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <Progress percent={percent} showInfo={false} />
                </Col> : null}

                <Col span={24} className="exercise-body">
                    <ExerciseBlocker show={messagestyle} />
                    <section className="mentalabacusaixation">
                        {!ready && !isTimeout ? <article key={currentNumber}>
                            <AbacusView params={locparams} isactive={false} hidelatframe={true} hideheaderlabels={true} />
                            <div className='maf_message'>
                                <img src="./static/myspace/vision.svg" alt="eyebrow" />
                            </div>
                        </article> : null}

                        {ready && !isTimeout ? <article className="clock">
                            <div className="blank closemessage">
                                <div>
                                    {/* <img src="./static/myspace/eyebrow.svg" alt="eyebrow" /> */}
                                </div>
                            </div>
                            <img src="./static/utils/clock.gif" alt="clock" />
                        </article> : null}
                        {ready && isTimeout ? <article className="setabacus">
                            <AbacusView params={{ ...params, number: numberinbox }} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={handleChange} />
                            <div className='maf_message'>
                                <img src="./static/myspace/hand_touch.svg" />
                            </div>
                        </article> : null}

                    </section>
                </Col>
                <Col span={24}>
                    <Row className={'botones' + messagestyle}>
                        <Col span={24} className={'message'}>{message}</Col>

                        {!message && !ready ? <Col span={24}><Button key="submit" type="primary" onClick={handleReady} style={{ 'width': '90%' }}>{t(lang, 'ready')}!</Button></Col> : null}

                        {!message && ready && isTimeout ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(lang, 'grade')}</Button></Col> : null}

                        {message && ready ? <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(lang, 'next')}</Button></Col> : null}

                        {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}

                    </Row>
                </Col>
            </Row >
        );
    } else {
        return (
            <Result params={params} results={results} handleRepeat={handleRepeat} handleCancel={handleCancel} lang={lang} />
        );
    }

}

export default MentalAbacusFixation;

