import { Button, Input, notification, Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { User } from '../../models/user';
// import LoginWith from '../Utils/LoginWith';
import { t } from '../../languages';

import { recordPageGA } from '../../modules/ga';
import { PRODUCTS } from '../../modules/user';

const validateEmail = value => {
  const re = /\S+@\S+\.\S+/;
  return re.test(value);
};

const isOkForm = ({ email, password }) => {
  return (validateEmail(email) && password && password.length >= 8);
};

const openNotificationWithIcon = (type, message, description, closeCallback) => {
  notification[type]({
    message: message,
    description: description,
    duration: 2,
    key: 'registernotification',
    onClose: closeCallback
  });
};


const LoginForm = ({ lang, user, isLoading, logged, error, isApple, loginUser, saveUser, cleanFlags }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isOK, setIsOK] = useState(false);
  // const [logging, setLogging] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    recordPageGA('/login');
  }, []);

  useEffect(() => {
    setIsError(error);
  }, [error]);


  const handlePassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password: string = e.currentTarget.value.trim();

    setPassword(password);
    setIsOK(isOkForm({ email, password }));

  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email: string = e.currentTarget.value;
    setEmail(email);
    setIsOK(isOkForm({ email, password }));
  };

  const handleLoginUser = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    const user: User = new User();

    user.email = email.trim();
    user.password = password;

    loginUser(user);
    // setLogging(true);
  };

  const isProductCourse = process.env.MIS_PRODUCT && [PRODUCTS.COURSE, PRODUCTS.TEACHER].includes(process.env.MIS_PRODUCT);

  if (logged) return <Redirect to="/profile" />;
  else return <article className="login-body">
    <div className='mis-wrapper'>

      <section className="profile login-form">
        <div className={`login-title ${isApple ? `apple-header` : ''}`}>
          <img src="./static/logo_mis.png" alt="" />
        </div>
        {isLoading ? <Spin size="large" className="spinner" /> : null}


        <div className="login-form-inputs">
          <div className={isApple ? 'apple-padding' : ''}></div>
          {isError ? <div className='error'>{t(lang, 'loginerror')}</div> : null}

          <Input
            type="text"
            className="login-input first-input"
            onChange={handleEmailChange} value={email}
            autoComplete="off"
            placeholder={t(lang, 'email')}
            autoCapitalize="none"
          />
          <Input.Password
            type="text"
            className="login-input"
            onChange={handlePassChange}
            value={password}
            autoComplete="off"
            placeholder={t(lang, 'password')}
          />
          <NavLink to='/user/remember'>
            <h4 className="forgotten-pw">{t(lang, 'forgotPsw')}</h4>
          </NavLink>

          <div className="button-container">
            <Button className="button-blue" type="primary" disabled={!isOK}
              onClick={handleLoginUser}>
              {t(lang, 'login')}
            </Button>
          </div>

        </div>

        <div className="links">
          {isProductCourse ? null : <NavLink to='/wellcome'>
            <h4 className="create-profile">{t(lang, 'createProfile')}</h4>
          </NavLink>}
          {/* <NavLink to='/joinclass'>
            <h4 className="create-profile">{'Únete a una clase'}</h4>
          </NavLink> */}
          {/* <LoginWith lang={lang} handleLogin={this.handleLoginWith} /> */}
        </div>

      </section>
    </div>
  </article>;

}

export default LoginForm;
