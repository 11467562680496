import { useEffect, useState } from 'react';


import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';
import { generateRandomNumberSeries } from '../../../utils';

import './index.less';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';
import { set } from 'react-ga';
import AudioVisualizer from '../../base/audiovisualizer';

const ExercisePresentation = ({ lang, video, onFinish }) => {

    const messagestyle = 0;

    return <>
        <Col span={24} className="exercise-body">
            <ExerciseBlocker show={messagestyle} />
            <section className="mentalcalculus">
                <video
                    src={video}
                    muted={false}
                    autoPlay={true}
                    controls={true}
                    onEnded={onFinish}
                    onError={() => console.log('onError...')}
                    onClick={() => console.log('onClick...')}
                    style={{ width: 'auto', height: '50vh' }}
                    poster={`./static/koyu/general_welcome_l1_welcome.jpg`}
                />
            </section>
        </Col>
    </>
}

const AudioTutor = ({ voicesrc, onFinish }) => {
    const [audioEnded, setAudioEnded] = useState(false);

    const handleEnded = () => {
        setAudioEnded(true);
        if (onFinish) onFinish()
    }

    return <article className='audio-tutor'>
        <div className='wave'>
            <span>{audioEnded ? null : <img src={'./static/utils/voicewave.gif'} />}</span>
            <span className='tutor'><img src={'./static/koyu/koyu.jpg'} /></span>
        </div>
        <div className='audio'><audio src={voicesrc} controls={false} autoPlay={true} onEnded={handleEnded} /></div>
    </article>
}

let abTimeout;
const SetAbacus = ({ lang, value, rows, voice, onFinish }) => {
    const [moved, setMoved] = useState(0);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (!value) setMoved(1);
        return () => { clearTimeout(abTimeout) };
    }, []);

    const handleChange = (num) => {
        setNumberinbox(parseInt(num));
        setMoved(1);
    }

    const validateHandle = () => {
        let message = value ? `${t(lang, 'Magnífico!')}` : null;
        if (numberinbox == value) {
            setMessage(message);
            abTimeout = setTimeout(() => {
                onFinish();
            }, 700);
        } else {
            message = `${t(lang, 'coloca el')} ${value}`;
        }
        setMessage(message);
    }

    const stillrows = !value ? Array.from(Array(rows).keys()) : [];

    const locparams = { rows, number: 0, number: numberinbox, stillrows };

    return <>
        <Col span={24} className="exercise-body">
            <section className="mentalcalculus">
                <AudioTutor voicesrc={voice} />
                <article>
                    <AbacusView params={locparams} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={handleChange} />
                </article>
            </section>
        </Col>

        <Col span={24}>
            <Row className={'botones'}>
                <Col span={24} className={'message'}>{message}</Col>
                <Col span={24}>
                    <Button key="submit" disabled={!moved} type="primary" onClick={validateHandle} style={{ 'width': '90%' }}>{t(lang, 'ready')}!</Button>
                </Col>
            </Row>
        </Col>
    </>
}


const Guide = ({ lang, voice, onFinish }) => {

    const handleFinish = () => {
        if (onFinish) onFinish()
    }

    return <>
        <Col span={24} className="exercise-body">
            <section className="mentalcalculus">
                <AudioTutor voicesrc={voice} onFinish={handleFinish} />
                <article className='closed_eyes'><img src='./static/utils/closed_eyes.png' /></article>
            </section>
        </Col>
    </>

}

const WELCOME_MENTAL_CALCULUS = { type: 'presentation', video: './static/koyu/welcome_mental_calculus.mp4' };
const OBSERVE_ABACUS = { type: 'set_number', voice: './static/voices/es/calculus/observe_abacus.wav', value: 0 }
const TRY_ANOTHER_CALC = { type: 'presentation', video: './static/koyu/try_another_calc.mp4' };

const SECUENCIES = {
    '25+34': [{ type: 'guide', voice: './static/voices/es/calculus/25_mas_34.wav' }, { type: 'set_number', 'voice': './static/voices/es/calculus/open_and_set.wav', value: 59 }],

    '57+28': [{ type: 'guide', voice: './static/voices/es/calculus/57_mas_28.wav' }, { type: 'set_number', 'voice': './static/voices/es/calculus/open_and_set.wav', value: 85 }],
}

const MentalCalculus = ({ lang, params, uid, eid, saveResult, handleCancel }) => {


    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [times, setTimes] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);

    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);

    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);

    const [moved, setMoved] = useState(0);
    const [ready, setReady] = useState(false);
    const [showresults, setShowresults] = useState(false);

    const [isTimeout, setIsTimeout] = useState(false);

    useEffect(() => {
        handleRepeat();
    }, []);

    const handleRepeat = () => {
        let series = params.couples.map((aC, idx) => {
            const acOpRep = aC.replace('+', 'sum').replace('*', 'sub').replace('*', 'mult').replace('/', 'div');
            let res_sequence = [];
            if (idx === 0) res_sequence.push(WELCOME_MENTAL_CALCULUS);
            else res_sequence.push(TRY_ANOTHER_CALC);

            res_sequence.push(OBSERVE_ABACUS)
            res_sequence.push({ type: 'guide', voice: `./static/voices/${lang}/calculus/${acOpRep}.wav` });
            res_sequence.push({ type: 'set_number', 'voice': `./static/voices/${lang}/calculus/open_and_set.wav`, value: parseInt(eval(aC)) });

            return res_sequence;
        });

        console.log('MentalCalculus series:', series);

        setSeries(series)
        setCurrent(0);
        setSteps(series[0]);
        setCurrentStep(0);

        setNumberinbox(0);
        setResults([]);

        setMessage(null);
        setMessage2(null);

        setShowresults(false);


        setMoved(0);
        setReady(false);

        setIsTimeout(false);
    }

    const handleNextStep = () => {
        if ((currentStep + 1) >= steps.length) {
            results.push(1);
            setResults(results);

            handleNext();
        } else {
            setCurrentStep(currentStep + 1)
        }
    }

    const handleReady = () => {
        setReady(true);
        setTimeout(() => {
            setIsTimeout(true);
        }, 5000);

    }

    const handleRate = () => {
        const currentNumber = series[current];
        const current_result = currentNumber === numberinbox ? 1 : 0;
        const message = current_result ? t(lang, 'rightanswer') : `${t(lang, 'yourwrong')} ${currentNumber}`;
        const message2 = current_result ? t(lang, 'great') : t(lang, 'nodiscorage');
        results.push(current_result);

        setResults(results);
        setMessage(message)
        setMessage2(message2)
    }

    const handleNext = () => {
        if (current < series.length - 1) {
            setCurrent(current + 1);
            setSteps(series[current + 1])
            setCurrentStep(0);

            setNumberinbox(0);
            setMessage(null);
            setMessage2(null);
            setMoved(0);
            setReady(false);
            setIsTimeout(false);
        } else {
            const num = params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > params.errors ? 0 : 1;
            const coins = params.coins;

            saveResult(uid, `${uid}-${eid}`, { id: 'MC', uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), r: results, s: params.couples });
            setShowresults(true);
        }
    }

    const handleChange = (num) => {
        setNumberinbox(parseInt(num));
        setMoved(1)
    }

    /* To render */
    const currentStepObj = steps[currentStep];

    const rate = results[current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const percent = parseInt((current) / params.num * 100);

    const is_test = params.is_test || false;
    const stillrows = !ready ? Array.from(Array(params.rows).keys()) : [];

    // const locparams = { ...params, number: currentNumber, stillrows };

    if (!showresults && currentStepObj) {
        return (
            <Row className="exercise">
                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <Progress percent={percent} showInfo={false} />
                </Col> : null}

                {currentStepObj.type == 'presentation' ? <ExercisePresentation lang={lang} video={currentStepObj.video} onFinish={handleNextStep} /> : null}
                {currentStepObj.type == 'set_number' ? <SetAbacus lang={lang} rows={params.rows} value={currentStepObj.value} voice={currentStepObj.voice} onFinish={handleNextStep} /> : null}
                {currentStepObj.type == 'guide' ? <Guide lang={lang} voice={currentStepObj.voice} onFinish={handleNextStep} /> : null}
            </Row>
        );
    } else {
        return (
            <Result params={params} results={results} handleRepeat={handleRepeat} handleCancel={handleCancel} lang={lang} />
        );
    }


}

export default MentalCalculus;

