import { Button, Input, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { t } from '../../../languages';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { launchReview, saveReview, USER_POINTS_TO_ASK_REVIEW } from '../../../modules/review';
import './index.less';
import { askedReview, RATE_STATE, updateUserFields } from '../../../modules/user';

const { TextArea } = Input;


const AskIfSatisfied = ({ lang, handleYes, handleNo }) => {
    return <>
        <h3>{t(lang, 'issatisfied')}</h3>
        <div className='botonera'>
            <Button onClick={handleYes}>{t(lang, 'yes')}</Button> <Button onClick={handleNo}>{t(lang, 'no')}</Button>
        </div>
    </>;
}

const WhyUnSatisfied = ({ lang, handleSend, value, setValue }) => {
    const isValidValue = value ? (value.length >= 10) : false;

    return <>
        <h3>{t(lang, 'howcanimprove')}</h3>
        <div>
            <TextArea rows={4} placeholder={t(lang, 'tellushowimprove')} maxLength={200} value={value} onChange={setValue} />
        </div>
        <div className='botonera'>
            <Button onClick={handleSend} disabled={!isValidValue}>{t(lang, 'send')}</Button>
        </div>
    </>;
}

const WannaRate = ({ lang, handleRate, handleLater }) => {
    return <>
        <h3>{t(lang, 'wannarate')}</h3>
        <div className='botonera'>
            <Button onClick={handleRate}>{t(lang, 'ratenow')}</Button> <Button onClick={handleLater}>{t(lang, 'later')}</Button>
        </div>
    </>;
}

const Thanks = ({ lang, handleCancel }) => {
    return <>
        <h3>{t(lang, 'thanksforyourfeedback')}</h3>
        <div className='botonera'>
            <Button onClick={handleCancel}>{t(lang, 'ok')}</Button>
        </div>
    </>;
}


const RequestReview = ({ lang, user, asked_review, askedReview, updateUserFields, saveReview, review_data, review_fetching, handleCloseManager }) => {

    // console.log('RequestReview review_data:', review_data);

    useEffect(() => {
        if (user) {
            const uses = user.uses || 0;
            const rateState = user.rateState || 0;
            const ask = !asked_review && (uses > 0 && uses % 5 == 0 || uses == 1 || user.points >= USER_POINTS_TO_ASK_REVIEW) && (rateState == null || rateState == RATE_STATE.NONE || rateState == RATE_STATE.LATER);
            // console.log('RequestReview:', uses, rateState, user.points,'::', ask);

            if (ask) {
                askedReview();
                showModal();
            }
        }

    }, [user]);

    const [open, setOpen] = useState(false);
    const [step, setStep] = useState('issatisfied');
    const [whynotvalue, setWhynotvalue] = useState(null);

    const showModal = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleClose = () => {
        // dont close!!!
        setOpen(true);
    };

    const handleYes = () => {
        const review = {
            liked: 'yes',
            user: user.id,
            type: 'quick'
        }

        saveReview(review);
        setStep('rate');
    };

    const handleNo = () => {
        const review = {
            liked: 'no',
            user: user.id,
            type: 'quick'
        }
        saveReview(review);
        setStep('whynot');
    };

    const handleRate = () => {
        updateUserFields({ rateState: RATE_STATE.YES });
        launchReview();
        handleCancel();
    };

    const handleLater = () => {
        // console.log('later...');
        updateUserFields({ rateState: RATE_STATE.LATER });
        handleCancel();
    };

    const handleNoRate = () => {
        // console.log('no rate...');
        updateUserFields({ rateState: RATE_STATE.NO });
        handleCancel();
    };

    const handleWhyNotValue = (e) => {
        setWhynotvalue(e.target.value);
    };

    const handleSend = () => {
        const review = {
            id: review_data.id,
            liked: 'no',
            user: user.id,
            noliked: whynotvalue,
            type: 'quick'
        }
        saveReview(review);
        updateUserFields({ rateState: RATE_STATE.YES });
        setStep('thanks');
    };

    const handleThanks = () => {
        // console.log('send handleThanks...');
        handleCloseManager('requestReview');
        handleCancel();
    };

    const handleReset = () => {
        setStep('issatisfied');
    };

    return <>
        {/* <Button type="primary" onClick={showModal}>
            Open Modal with customized footer
        </Button> */}

        {review_fetching ? <Spin size="large" className="spinner" /> : null}

        <Modal
            open={open}
            title={null}
            onCancel={handleClose}
            footer={null}
            className='request-review-modal confirm'
            afterClose={handleReset}
            closable={false}
        >
            {step === 'issatisfied' ? <AskIfSatisfied lang={lang} handleYes={handleYes} handleNo={handleNo} /> : null}
            {step === 'rate' ? <WannaRate lang={lang} handleRate={handleRate} handleLater={handleLater} /> : null}
            {step === 'whynot' ? <WhyUnSatisfied lang={lang} handleSend={handleSend} value={whynotvalue} setValue={handleWhyNotValue} /> : null}
            {step === 'thanks' ? <Thanks lang={lang} handleCancel={handleCancel} /> : null}
        </Modal>
    </>;
}

const mapStateToProps = ({ user, review }) => ({
    user: user.user,
    lang: user.lang,
    asked_review: user.asked_review,
    review_data: review.data,
    review_fetching: review.isFetching
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateUserFields,
            askedReview,
            saveReview
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RequestReview);
