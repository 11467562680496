import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { User } from '../../models/user';
import { changeChild, changeLanguage, cleanFlags, deleteAccount, getUserData, loadToken, login, logout, PRODUCTS, saveUser, updateUserFields } from '../../modules/user';

import LoginForm from '../../components/User/LoginForm';
import UserProfile from '../../components/User/UserProfile';

import { getMobileOperatingSystem } from '../../utils';

import { recordPageGA } from '../../modules/ga';
import './index.less';
import Logout from '../../components/User/Logout';

const isApple = (getMobileOperatingSystem() == 'iOS') ? true : false;


type ProfileProps = {
  user: User;
  token: string;
  login: (aUser: User) => void;
  logout: () => void;
  deleteAccount: () => void;
  updateUserFields: () => void;
  isLoading: boolean;
  lang: string;
  changeLanguage: (lang: string) => void;
  saved: boolean;
  error: boolean;
  logged: boolean;
  saveUser: (user: User) => void;
  changeChild: (user: User) => void;
  cleanFlags: () => void;
  getUserData: () => void;
  match: any;
};

class Profile extends React.Component<ProfileProps> {
  componentDidMount() {
    window.scrollTo(0, 0);
    recordPageGA('/profile');
  };

  render() {
    const props = this.props;
    // console.log('user: ', this.props.user)
    // console.log('language: ', this.props.lang);

    const isLogged = props.token ? true : false;
    const whereRedirect = props.user && props.user.products && props.user.products.includes(PRODUCTS.COURSE) ? '/course' : '/learn';

    return (
      <section className={`MiSoroban`}>
        <Switch>
          <Route exact path="/profile">
            {isLogged ? <UserProfile
              isApple={isApple}
              lang={props.lang}
              user={props.user}
              logout={props.logout}
              changeChild={props.changeChild}
              changeLanguage={props.changeLanguage}
              deleteAccount={props.deleteAccount}
              updateUserFields={props.updateUserFields}
              isLoading={props.isLoading}
              getUserData={props.getUserData}
            /> : <Redirect to="/profile/login" />}
          </Route>
          <Route exact path="/profile/login">
            {isLogged ? <Redirect to={whereRedirect} /> : <LoginForm
              isApple={isApple}
              lang={this.props.lang}
              user={props.user}
              isLoading={props.isLoading}
              logged={isLogged}
              error={props.error}
              loginUser={props.login}
              saveUser={props.saveUser}
              cleanFlags={props.cleanFlags}
            />}
          </Route>
          <Route exact path="/profile/logout">
            <Logout logout={props.logout} />
          </Route>
        </Switch>
      </section>
    );
  }
}
const mapStateToProps = ({ user }) => ({
  user: user.user,
  token: user.token,
  isLoading: user.isLoading,
  saved: user.saved,
  error: user.error,
  lang: user.lang
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    loadToken,
    login, logout, deleteAccount, updateUserFields,
    saveUser, changeChild, getUserData,
    changeLanguage,
    cleanFlags
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
