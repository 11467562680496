import React from 'react';
import { Avatar, List } from 'antd';

const LangButtons = ({ lang, handleChangeLang }) => {
    const ukFlag = lang == 'en' ? 'check' : 'flag_united_kingdom';
    const esFlag = lang == 'es' ? 'check' : 'flag_spain';

    return (
        <List className="lang-list">
            <List.Item onClick={() => handleChangeLang('es')} className="lang-button">
                <Avatar src={`./static/languages/${esFlag}.svg`} />
                <h4 className={`${esFlag}`}>ESPAÑOL</h4>
            </List.Item>
            <List.Item onClick={() => handleChangeLang('en')} className="lang-button">
                <Avatar src={`./static/languages/${ukFlag}.svg`} />
                <h4 className={`${ukFlag}`}>ENGLISH</h4>
            </List.Item>
        </List>
    )
}
export default LangButtons;