import React, { useEffect, useRef, useState } from 'react';
import { generateRandomNumber, generateRandomNumberSeries, shuffle } from '../../../utils';

import './index.less';

const MAX_ROWS = 5;
const MAX_COLS = 4;


const Mesh = props => {
    const [items, setItems] = useState([]);
    const [showMesh, setShowMesh] = useState(false);
    const [guess, setGuess] = useState(false);

    useEffect(() => {
        const COLS = props.cols ? props.cols : MAX_COLS;
        const ROWS = props.rows ? props.rows : MAX_ROWS;

        const objects = [...props.objects];
        shuffle(objects)
        const nObjs = objects ? objects.length : 0;
        const maxObjsPerRow = Math.ceil(nObjs / COLS);
        let addedObs = 0;
        // console.log('Mesh.useEffect objects:', nObjs, objects);

        let lItems = [];
        let freePos = [];
        for (let ydx = 0; ydx < ROWS; ydx++) {
            const aRow = [];
            let objsInThisRow = 0;
            const prevPositions = [];
            for (let xdx = 0; xdx < COLS; xdx++) {
                const addObj = addedObs < nObjs ? generateRandomNumber(0, 1) : 0;
                if (objsInThisRow >= maxObjsPerRow || !addObj || prevPositions.includes(xdx - 1)) {
                    aRow.push({ val: 0, img: null, x: xdx, y: ydx, selected: false });
                    freePos.push([ydx, xdx]);
                } else {
                    const anObj = objects[addedObs];
                    aRow.push({ val: anObj.val, img: anObj.img, x: xdx, y: ydx, selected: false });
                    prevPositions.push(xdx);
                    objsInThisRow++;
                    addedObs++;
                }

            }

            lItems.push(aRow);
        }

        if (addedObs < nObjs) {
            shuffle(freePos);
            const anObj = objects[nObjs - 1];
            const [ydx, xdx] = freePos[0];
            lItems[ydx][xdx] = { val: anObj.val, img: anObj.img, x: xdx, y: ydx, selected: false };
        }

        // console.log('Mesh.useEffect objects:', nObjs, addedObs, '::', objects, lItems,);

        setItems(lItems);

    }, [props.objects]);

    const clickHandle = (idx, item) => {
        // console.log('Mesh.clickHandle:', idx, item);

        if (props.onClick && props.guess) {
            props.onClick(item);
            item.selected = true;
        }
    };


    return (
        <div className={`mesh`}>
            {items
                ? items.map((aRow, ydx) => {
                    return (
                        <div key={ydx} className="mesh-row">
                            {aRow.map((aI, idx) => {
                                const val = aI.val;
                                let itemStyle = 'mesh-item';
                                if (aI.selected) itemStyle += ' selected';

                                return (
                                    <div key={idx + '-' + ydx} className={itemStyle} onClick={() => clickHandle(idx, aI)}>
                                        <div key={idx + '-' + ydx}>
                                            {props.guess && !aI.selected ? '?' : null}
                                            {(aI.val && !props.guess) || (aI.selected && aI.img) ? <img src={`./static/animals/${aI.img}`} /> : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })
                : null}
        </div>
    );
};

export default Mesh;