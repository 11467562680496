const randomposition = (parent_width, parent_height, item) => {
    const item_res = { ...item };
    item_res.pos.x = Math.floor(Math.random() * (parent_width - item.pos.w));
    item_res.pos.y = Math.floor(Math.random() * (parent_height - item.pos.h));
    return item_res;
}

const move = (parent_width, parent_height, item) => {
    const pos = item.pos;
    const ops = [1.5, -1, 1, 1.5];
    const delta = item.d != null ? item.d : { dx: ops[Math.floor(Math.random() * ops.length)], dy: ops[Math.floor(Math.random() * ops.length)] };

    let dx = delta.dx;
    let dy = delta.dy;

    if (dx > 0) {
        if (parent_width - pos.x - pos.w <= 0) dx = -dx;
    } else {
        if (pos.x <= 0) dx = -dx;
    }

    if (dy > 0) {
        if (parent_height - pos.y - pos.h <= 0) dy = -dy;
    } else {
        if (pos.y <= 0) dy = -dy;
    }

    // console.log('move.delta end:', delta, dx, dy);

    return { dx, dy };

}


export { randomposition, move }