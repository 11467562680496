import { Button, Popover } from 'antd';
import React, { useState } from 'react';

import './index.less';
import { t } from '../../../languages';

const images = [...Array(20).keys()];

const ContentBody = ({ selectAvatar }) => (
    <div className='avatar-list'>
        {images.map(aI => {
            return <span key={aI} className='avatar' onClick={() => selectAvatar(aI + 1)}> <img src={`./static/avatar/${aI + 1}.jpg`} alt="" /></span>;
        })}
    </div>
);

const AvatarPicker = ({ lang, handleChange, selected }) => {

    // console.log('AvatarPicker:', age);
    const [clicked, setClicked] = useState(false);
    const [selectedAv, setSelectedAv] = useState(selected ? selected : 0);

    const hide = (aI) => {
        setSelectedAv(aI);
        setClicked(false);
        if (handleChange) handleChange(aI);
    };

    return (
        <section className='avatar-picker'>
            <Popover
                placement="bottom"
                title={<span>{t(lang, 'Escoge tu avatar')}</span>}
                content={<ContentBody selectAvatar={hide} />}
                overlayClassName={'avatar-picker-popover'}
                open={clicked}
            >
                <span key={'av'} className='avatar' onClick={() => setClicked(true)}>
                    {selectedAv ? <img src={`./static/avatar/${selectedAv}.jpg`} alt="" /> : null}
                </span>
            </Popover>
        </section >
    )
}
export default AvatarPicker;