const practice1 = [
    {
        level: 1,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 'p1',
                label: 'practicesumsp',
                level: 1,
                icon: 'practice/sumas.svg',
                exercises: [
                    {
                        label: 'easysums',
                        impl: 'mentalsumsubstraction',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 3, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'intermediatesums',
                        impl: 'mentalsumsubstraction',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 299, valueinabacus: 0, rows: 3, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                    }
                    
                ]
            },
            {
                uid: 'p2',
                label: 'practicesubstractions',
                level: 1,
                icon: 'practice/restas.svg',
                exercises: [
                    {
                        label: 'easysubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 3, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'intermediatesubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
    }
];

const practice2 = [
    {
        level: 1,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 'p1',
                label: 'practicesumsp',
                level: 1,
                icon: 'practice/sumas.svg',
                exercises: [
                    {
                        label: 'easysums',
                        impl: 'mentalsumsubstraction',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 3, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'intermediatesums',
                        impl: 'mentalsumsubstraction',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 299, valueinabacus: 0, rows: 3, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'hardsums',
                        impl: 'mentalsumsubstraction',
                        icon: 'hards',
                        params: { couples: 'random', ops: ['+'], init: 1111, finish: 2999, ispan: 1111, fspan: 2999, valueinabacus: 0, rows: 4, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 4, num: 3 }], wait: 6, errors: 0, cansubstract: true }
                    },
                    
                ]
            },
            {
                uid: 'p2',
                label: 'practicesubstractions',
                level: 1,
                icon: 'practice/restas.svg',
                exercises: [
                    {
                        label: 'easysubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 3, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'intermediatesubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'hardsubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'hards',
                        params: { couples: 'random', ops: ['-'], init: 7111, finish: 9999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 10, num: 3, config: [{ digits: 4, num: 3 }, { digits: 3, num: 3 }], wait: 6, errors: 0, cansubstract: true }
                    }
                ]
            },
            {
                uid: 'p3',
                label: 'practicemultiplications',
                level: 1,
                icon: 'practice/mults.svg',
                exercises: [
                    {
                        label: 'easymults',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['*'], only_answer:true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'easymults2',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['*'], only_answer:true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    
                ]
            },
            {
                uid: 'p4',
                label: 'practicedivisions',
                level: 1,
                icon: 'practice/divs.svg',
                exercises: [
                    {
                        label: 'easydivs',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['/'], only_answer:true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'easydivs2',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['/'], only_answer:true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    
                ]
            },
        ],
    }
];

const practice3 = [
    {
        level: 1,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 'p1',
                label: 'practicesumsp',
                level: 1,
                icon: 'practice/sumas.svg',
                exercises: [
                    {
                        label: 'easysums',
                        impl: 'mentalsumsubstraction',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 3, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'intermediatesums',
                        impl: 'mentalsumsubstraction',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 299, valueinabacus: 0, rows: 3, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'hardsums',
                        impl: 'mentalsumsubstraction',
                        icon: 'hards',
                        params: { couples: 'random', ops: ['+'], init: 1111, finish: 2999, ispan: 1111, fspan: 2999, valueinabacus: 0, rows: 4, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 4, num: 3 }], wait: 6, errors: 0, cansubstract: true }
                    },
                    
                ]
            },
            {
                uid: 'p2',
                label: 'practicesubstractions',
                level: 1,
                icon: 'practice/restas.svg',
                exercises: [
                    {
                        label: 'easysubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 3, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'intermediatesubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 3, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'hardsubs',
                        impl: 'mentalsumsubstraction',
                        icon: 'hards',
                        params: { couples: 'random', ops: ['-'], init: 7111, finish: 9999, ispan: 1111, fspan: 1999, valueinabacus: 0, rows: 4, coins: 10, num: 3, config: [{ digits: 4, num: 3 }, { digits: 3, num: 3 }], wait: 6, errors: 0, cansubstract: true }
                    }
                ]
            },
            {
                uid: 'p3',
                label: 'practicemultiplications',
                level: 1,
                icon: 'practice/mults.svg',
                exercises: [
                    {
                        label: 'easymults',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['*'], only_answer:true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'easymults2',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['*'], only_answer:true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'intermediatemults',
                        impl: 'multiplydivide',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['*'], only_answer:true, init: 11, finish: 99, ispan: 11, fspan: 99, rows: 4, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'hardmults',
                        impl: 'multiplydivide',
                        icon: 'hards',
                        params: { couples: 'random', ops: ['*'], only_answer:true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 5, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                ]
            },
            {
                uid: 'p4',
                label: 'practicedivisions',
                level: 1,
                icon: 'practice/divs.svg',
                exercises: [
                    {
                        label: 'easydivs',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['/'], only_answer:true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'easydivs2',
                        impl: 'multiplydivide',
                        icon: 'easys',
                        params: { couples: 'random', ops: ['/'], only_answer:true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'intermediatedivs',
                        impl: 'multiplydivide',
                        icon: 'mediums',
                        params: { couples: 'random', ops: ['/'], only_answer:true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 2, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'harddivs',
                        impl: 'multiplydivide',
                        icon: 'hards',
                        params: { couples: 'random', ops: ['/'], only_answer:true, init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 3, coins: 10, num: 3, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                ]
            },
        ],
    }
];

const practices = {
    1: practice1,
    2: practice2,
    3: practice3,
}
export default practices;