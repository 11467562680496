import React from 'react'

import Theory from '../components/gamez/theory';
import QuantityGame from '../components/gamez/quantitygame';


import WhatNumberIs from '../components/gamez/whatnumberis';
import RepresentInAbacus from '../components/gamez/representinabacus';
import GuidedSequence from '../components/gamez/guidedsequence';
import SumSubstractionSeries from '../components/gamez/sumsubstractionseries';
import SumSubstractionNumber from '../components/gamez/sumsubstractionnumber';
// import MultiplyDivide from '../components/gamez/multiplydivide';
import MentalSumSubstraction from '../components/gamez/mentalsumsubstraction';
import CartoonLayOut from '../components/gamez/cartoonlayout';
import MentalAbacusFixation from '../components/gamez/mentalabacusfixation';
// import OrderElements from '../components/gamez/orderelements';
// import CannonChall from '../components/gamez/cannonchall';
import MemoSet from '../components/gamez/memoset';
// import RamdonFromList from '../components/gamez/ramdonfromlist';
import BaloonSchall from '../components/gamez/baloonschall';
import CouplesChall from '../components/gamez/coupleschall';
// import PathChall from '../components/gamez/pathchall';


import AbacusView from '../components/base/abacus';
import Tests from '../components/tests';
import PathChall from '../components/gamez/pathchall';
import MultiplyDivide from '../components/gamez/multiplydivide';
import Equation from '../components/gamez/challenge/equationchallenge';
import NumericPathChallenge from '../components/gamez/challenge/numericpath';
import MemoMeshChallenge from '../components/gamez/challenge/memomeshchallenge';
import CompleteOperations from '../components/gamez/challenge/completeoperations';
import NumberRiddle from '../components/gamez/challenge/numberriddle';
import TestChallenge from '../components/Challenge/testchallenge';
import MentalCalculus from '../components/gamez/mentalcalculus';
// import Cartoon from '../components/base/cartoon';

const genExerciseComponent = (type, uid, eid, params, handleCancel, saveResult, lang) => {
    // console.log('genExerciseComponent.params',params);
    if (type === 'test') {
        return <Tests uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'theory') {
        return <Theory uid={uid} eid={eid} params={params} videoId={params.video} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />;
    } else if (type === 'quantitygame') {
        return <QuantityGame uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'whatnumberis') {
        return <WhatNumberIs uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'guidedsequence') {
        return <GuidedSequence uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'representinabacus') {
        return <RepresentInAbacus uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'mentalabacusfixation') {
        return <MentalAbacusFixation uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'cartoonlayout') {
        return <CartoonLayOut uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'sumsubstractionnumber') {
        return <SumSubstractionNumber uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'multiplydivide') {
        return <MultiplyDivide uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'coupleschall') {
        return <CouplesChall uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'baloonschall') {
        return <BaloonSchall uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'pathchall') {
        return <PathChall uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'mentalsumsubstraction') {
        return <MentalSumSubstraction uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'memoset') {
        return <MemoSet uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'sumsubstractionseries') {
        return <SumSubstractionSeries uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'challenge') {
        return <TestChallenge uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'equationchallenge') {
        return <Equation uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'numericpath') {
        return <NumericPathChallenge uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'memomeshchallenge') {
        return <MemoMeshChallenge uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'completeoperations') {
        return <CompleteOperations uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'numberriddle') {
        return <NumberRiddle uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else if (type === 'mentalcalculus') {
        return <MentalCalculus uid={uid} eid={eid} params={params} handleCancel={handleCancel} saveResult={saveResult} lang={lang} />
    } else {
        return <AbacusView params={params} lang={lang} />;
    }
}

export { genExerciseComponent };