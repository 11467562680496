import React from 'react'
import Box from '../../base/DragThingsToBoxes/Box';

import { generateRandomNumber } from '../../../utils';

const object = ['airplane.png', 'automobile1.png', 'cat.png', 'christmastree.png', 'cup.png', 'delivery13.png', 'elephants.png', 'flower.png', 'house.png', 'magical4.png', 'moon.png', 'owl.png', 'saturn1.png', 'snow.png', 'star.png', 'sun.png', 'telescope.png', 'tree.png'];

const DragComp = (props) => {
    // console.log('DragComp:', props);

    let boxItems=null;

    if(props.type==='objects'){

        const randomNum = generateRandomNumber(0, object.length - 1);
        const obj_img = `./static/objects/${object[randomNum]}`;

        boxItems = [
            { uid: 1, img: obj_img, value: 1 },
            { uid: 2, img: obj_img, value: 1 },
            { uid: 3, img: obj_img, value: 1 },
            { uid: 4, img: obj_img, value: 1 },
            { uid: 5, img: obj_img, value: 1 },
            { uid: 6, img: obj_img, value: 1 },
            { uid: 7, img: obj_img, value: 1 },
            { uid: 8, img: obj_img, value: 1 },
            { uid: 9, img: obj_img, value: 1 },
        ];
    }else{
        boxItems = [
            { uid: 1, img: './static/board/c1.png', value: 1 },
            { uid: 2, img: './static/board/c1.png', value: 1 },
            { uid: 3, img: './static/board/c1.png', value: 1 },
            { uid: 4, img: './static/board/c1.png', value: 1 },
            { uid: 5, img: './static/board/c5.png', value: 5 },            
        ];
    }

    

    return (
        <section className="drag_things_to_boxes">
            <div className={`boxes ${props.type}`}>
                <Box targetKey="box" onMove={props.onMove} key={props.current} id="orig" items={boxItems} />
                <div className="inter-boxes"><img src="./static/utils/drag.svg" alt={'drag'}/></div>
                <Box targetKey="box" onMove={props.onMove} key={props.current+1000} id="dest" />
            </div>
        </section>
    );

}


export default DragComp;
