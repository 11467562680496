import { getMobileOperatingSystem } from '../utils';

declare let window: any;

const devicePlatform = getMobileOperatingSystem();
const isAndroid = devicePlatform == 'Android' ? true : false;

export const openBrowser = (url) => {
    if (!url) {
        return;
    }

    // turn my url into a scheme/intent url
    getAvailabilityScheme(url, (url) => {
        window.open(url, '_system');
        // window.open(url, '_blank');
    });
}

const getAvailabilityScheme = (url, callback) => {
    var schemeOrPackage;
    var schemeUrl;

    // check for appavaialbility plugin
    if (!window.appAvailability) {
        callback(url);
    }

    // facebook
    if (url.indexOf('facebook.com/') !== -1) {
        schemeOrPackage = isAndroid ? 'com.facebook.katana' : 'fb://'
        schemeUrl = 'fb://profile/' + url.split('facebook.com/')[1];
    }

    // twitter
    if (url.indexOf('twitter.com/') !== -1) {
        schemeOrPackage = isAndroid ? null : 'twitter://'
        schemeUrl = 'twitter://user?screen_name=' + url.split('twitter.com/')[1];
    }

    if (schemeOrPackage && schemeUrl) {
        if (!window.cordova){
            callback(url);
        }else{
            window.appAvailability.check(schemeOrPackage, () => {
                callback(schemeUrl);
            }, function () {
                callback(url);
            });
        }
        
    } else {
        callback(url);
    }
}
