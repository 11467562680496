import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { isPortrait, vw } from '../../../utils';

const PORTRAITREL = 90;
const LANDSCAPEREL = 60;

const VideoView = ({ videoId, endHandler, pauseHandler, playHandler, controls, portrait, noautoplay }) => {
  const [rel, setRel] = useState(isPortrait() ? PORTRAITREL : LANDSCAPEREL);

  const onResize = () => {
    setRel(isPortrait() ? PORTRAITREL : LANDSCAPEREL);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const finish = () => {
    if (endHandler) endHandler();
  }

  const pause = () => {
    if (pauseHandler) pauseHandler();
  }

  const play = () => {
    if (playHandler) playHandler();
  }

  const opts = {
    height: portrait ? vw(16 / 15 * rel) : vw((rel * 11) / 16),
    width: portrait ? '100%' : vw(100),
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: noautoplay ? 0 : 1,
      controls: controls || 0,
      rel: 0,
      cc_load_policy: 3
    }
  };

  // console.log('rel:', rel);
  return <YouTube videoId={videoId} opts={opts} onEnd={finish} onPause={pause} onPlay={play} />;
};

export default VideoView;
