declare let window: any;

export const lockPortrait = () => {
    if (window.cordova && window.screen) {
        window.screen.orientation.unlock();
        window.screen.orientation.lock('portrait');
    }
};

export const lockLandscape = () => {
    if (window.cordova && window.screen) {
        window.screen.orientation.unlock();
        window.screen.orientation.lock('landscape');
    }
};

export const unlockScreen = () => {
    if (window.cordova && window.screen) window.screen.orientation.unlock();
};

