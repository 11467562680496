import React, { useEffect, useState } from 'react';

import './index.less';

type NumPadListProps = {
  clickHandler: any;
  initVal?: number | null | undefined;
  clear?: boolean; 
};

const BACKS = '<';
const MAX_SIZE = 13;

const NumPad = ({ clickHandler, initVal, clear }:NumPadListProps) => {
  const [val, setVal] = useState(['']);

  useEffect(() => {
    if(initVal) setVal(initVal.toString().split(''));
    if(clear) setVal(['']);
  }, []);

  useEffect(() => {
    if(clear) setVal(['']);
  }, [clear]);

  const handleClick = (elem) =>{
    let lVal = '';
    if(elem === BACKS){
     if(val && val.length){
        val.pop();
        lVal = val.join('');
      }
    }else if(val && val.length >= MAX_SIZE){
      lVal = val.join('');
    }else{
      lVal = val.join('').concat(elem);
    }

    if(clickHandler) clickHandler(lVal?parseInt(lVal):0);
      
    setVal( lVal.split('') );
  }

  return (
    <section className="numpad">
      <div className='number-inbox'>{val}</div>
      <ul className="keyboard">
        <li onClick={() => handleClick(1)} className="letter">1</li>
        <li onClick={() => handleClick(2)} className="letter">2</li>
        <li onClick={() => handleClick(3)} className="letter">3</li>
        <li onClick={() => handleClick(4)} className="letter">4</li>
        <li onClick={() => handleClick(5)} className="letter">5</li>
        <li onClick={() => handleClick(6)} className="letter clearl">6</li>

        <li onClick={() => handleClick(7)} className="letter">7</li>
        <li onClick={() => handleClick(8)} className="letter ">8</li>
        <li onClick={() => handleClick(9)} className="letter">9</li>
        <li onClick={() => handleClick(0)} className="letter">0</li>
        {/* <li onClick={() => handleClick(1)} className="switch">abc</li>
        <li onClick={() => handleClick(1)} className="return">return</li> */}
        <li onClick={() => handleClick('<')} className="delete lastitem">{BACKS}</li>
      </ul>
    </section>
  );
};
export default NumPad;
