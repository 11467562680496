import React from 'react';
import { Avatar, List } from 'antd';

const AgeButtons = ({ age, handleChangeAge }) => {

    // console.log('AgeButtons:', age);
    
    const ar_57 = age == 1 ? 'check' : 'age_1';
    const ar_811 = age == 2 ? 'check' : 'age_2';
    const ar_12more = age == 3 ? 'check' : 'age_3';

    return (
        <List className="age-list">
            <List.Item onClick={() => handleChangeAge(1)} className="age-button">
                <Avatar src={`./static/ages/${ar_57}.svg`} />
                <h4 className={`${ar_57}`}>5-7</h4>
            </List.Item>
            <List.Item onClick={() => handleChangeAge(2)} className="age-button">
                <Avatar src={`./static/ages/${ar_811}.svg`} />
                <h4 className={`${ar_811}`}>8-11</h4>
            </List.Item>
            <List.Item onClick={() => handleChangeAge(3)} className="age-button">
                <Avatar src={`./static/ages/${ar_12more}.svg`} />
                <h4 className={`${ar_12more}`}>12+</h4>
            </List.Item>
        </List>
    )
}
export default AgeButtons;