import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { RouteComponentProps } from 'react-router-dom';

import { changeLanguage } from '../../modules/user';
import { joinClass, cleanFlags } from '../../modules/user';

import JoinClass from '../../components/User/JoinClass';

import './index.less';
import { User } from '../../models/user';
import { getMobileOperatingSystem } from '../../utils';


interface IProps extends RouteComponentProps<any> {
    user: User;
    lang: string;
    joinClass: (aChild: User, aParent: User, code: string) => void;
    changeLanguage: (lang: string) => void;
    cleanFlags: () => void;
    isLoading: boolean;
    saved: boolean;
    error: boolean;
}
const isApple = (getMobileOperatingSystem() == 'iOS') ? true : false;

class RegisterClass extends React.Component<IProps> {
    state = { selectedLanguage: 'en', tempUser: null }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.props.cleanFlags();
    };

    handleChangeLanguage = (language) => {
        this.setState({ selectedLanguage: language });

    }

    handleSave = (user) => {
        this.setState({ tempUser: user });
    }

    render() {
        return (
            <div className={`MiSoroban welcome ${isApple ? 'apple-welcome' : ''}`} >
                <JoinClass
                    lang={this.props.lang}
                    user={this.props.user}
                    join={this.props.joinClass}
                    isLoading={this.props.isLoading}
                    saved={this.props.saved}
                    error={this.props.error}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({
    user: user.user,
    lang: user.lang,
    isLoading: user.isLoading,
    saved: user.saved,
    error: user.error,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            changeLanguage,
            joinClass,
            cleanFlags
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RegisterClass);
