import { Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from '../../languages';
import { getMobileOperatingSystem } from '../../utils';
import { NavLink } from 'react-router-dom';

const isApple = (getMobileOperatingSystem() == 'iOS') ? true : false;

const NoMatch = ({ lang }) => (
    <div className={`MiSoroban welcome ${isApple ? 'apple-welcome' : ''}`} >
        <section className="register almostthere">
            <div className='body'>
                <img src="./static/snapshots/abaco.png" />
                <br />
                <br />
                <h2>{t(lang, 'Get lost')} :-(...</h2>
                <br />
                <div>
                    <NavLink to={'/'}>
                        <Button>{t(lang, 'Home')}</Button>
                    </NavLink>
                </div>

            </div>
        </section>
    </div >
);

const mapStateToProps = ({ user }) => ({
    user: user.user,
    lang: user.lang
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NoMatch);

