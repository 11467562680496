import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RouteComponentProps } from 'react-router-dom';

import { changeLanguage, saveUser } from '../../modules/user';

import WellMessage from '../../components/Wellcome/WellMessage';

import { User } from '../../models/user';
import { recordPageGA } from '../../modules/ga';
import { getMobileOperatingSystem } from '../../utils';
import './index.less';

interface IProps extends RouteComponentProps<any> {
  user: User;
  lang: string;
  saveUser: (user: User) => void;
  changeLanguage: (lang: string) => void;
  isLoading: boolean;
  saved: boolean;
  error: boolean;
}
const isApple = (getMobileOperatingSystem() == 'iOS') ? true : false;


class Wellcome extends React.Component<IProps> {
  state = { selectedLanguage: 'en', tempUser: null }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    recordPageGA('/welcome');
  };

  handleChangeLanguage = (language) => {
    this.setState({ selectedLanguage: language });

  }

  handleSave = (user) => {
    this.setState({ tempUser: user });
  }

  render() {
    return (
      <div className={`MiSoroban welcome ${isApple ? 'apple-welcome' : ''}`} >
        {/* <ChangeOrientation to={'portrait'}  /> */}
        <WellMessage lang={this.props.lang} />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
  lang: user.lang,
  isLoading: user.isLoading,
  saved: user.saved,
  error: user.error,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeLanguage,
      saveUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Wellcome);
