
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router';
import { HashRouter } from 'react-router-dom';
import store, { history } from './store';

import App from './containers/App';

import { initFirebasePushing } from './fcm';
import * as serviceWorker from './serviceWorker';

import { loadSubscriptions } from './modules/subscription';
import { unlockScreen } from './utils/screen';

import './index.less';
import { initGA } from './modules/ga';

// declare let window: any;

const target = document.querySelector('#root');


const startApp = () => {
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <HashRouter basename="/">
          <div>
            <App />
          </div>
        </HashRouter>
      </ConnectedRouter>
    </Provider>,
    target
  );
};

if (!window.cordova) {
  startApp();
} else {
  document.addEventListener('deviceready', () => {
    console.log('Device is ready!');
    store.dispatch(loadSubscriptions());
    startApp();
    initFirebasePushing(history);
    unlockScreen();
    initGA();
    //prevent zoom
    // if (getMobileOperatingSystem() == 'iOS') {
    document.ontouchmove = event => {
      event.preventDefault();
    };
    // }
  }, false);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
