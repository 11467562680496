import moment from 'moment';

let _FirebasePlugin = null;//window.FirebasePlugin;
// console.log('FirebasePlugin:', _FirebasePlugin);

const cordova = window.cordova;
let dataMessage = null;

export const initFirebasePushing = (history) => {
    catchGlobalError();

    _FirebasePlugin = window.FirebasePlugin;
    console.log('initFirebasePushing:', _FirebasePlugin, '\n->history:', history, '\n->cordova.platformId:', cordova.platformId);

    if (_FirebasePlugin) {

        // Set global error handler to catch uncaught JS exceptions
        window.onerror = function (errorMsg, url, line, col, error) {
            console.log('window.onerror:', errorMsg, url, line, col, error);
        };

        try {
            //Register handlers
            _FirebasePlugin.onMessageReceived(function (message) {
                try {
                    console.log("onMessageReceived:", message);
                    // console.dir(message);
                    if (message.messageType === "notification") {
                        handleNotificationMessage(message, history);
                    } else {
                        handleDataMessage(message, history);
                    }
                } catch (e) {
                    console.log("Exception in onMessageReceived callback: " + e.message);
                }

            }, function (error) {
                console.log("Failed receiving _FirebasePlugin message", error);
            });

            _FirebasePlugin.onTokenRefresh(function (token) {
                console.log("Token refreshed: " + token)
            }, function (error) {
                console.log("Failed to refresh token", error);
            });

            _FirebasePlugin.registerAuthStateChangeListener(function (userSignedIn) {
                console.log("Auth state changed: User signed " + (userSignedIn ? "IN" : "OUT"));
            });

            // Custom FCM receiver plugin
            /* cordova.plugin.customfcmreceiver.registerReceiver(function (message) {
                console.log("Received custom message: " + message);
            }); */

            checkNotificationPermission(false); // Check permission then get token
            checkAutoInit();

            if (cordova.platformId === "android") {
                initAndroid();
            } else if (cordova.platformId === "ios") {
                _FirebasePlugin.grantPermission(function (hasPermission) {
                    console.log("Permission was " + (hasPermission ? "granted" : "denied"));
                    getAPNSToken();
                });

                initIos();
            } else {
                console.log('platformId unknown...');
            }

            // subscribe('misoroban');
            // subscribe('testMisoroban');

            // setAnalyticsCollectionEnabled();
        } catch (error) {
            console.log('fcm try error: ', error);

        }
    }
}

export const catchGlobalError = () => {
    window.onerror = function (errorMsg, url, line, col, error) {
        console.log('catchGlobalError:', errorMsg, url, line, col, error);

    };
}

const initIos = () => {
    console.log('initIos.....');
    _FirebasePlugin.onApnsTokenReceived(function (token) {
        console.log("APNS token received: " + token)
    }, function (error) {
        console.log("Failed to receive APNS token", error);
    });
};

const initAndroid = () => {
    console.log('initAndroid.....');
}

// Notifications
const checkNotificationPermission = (requested) => {
    _FirebasePlugin.hasPermission(function (hasPermission) {
        if (hasPermission) {
            console.log("Remote notifications permission granted");
            // Granted
            if (cordova.platformId === 'android') getToken();
            else if (cordova.platformId === "ios") getAPNSToken();
        } else if (!requested) {
            // Request permission
            console.log("Requesting remote notifications permission");
            _FirebasePlugin.grantPermission(checkNotificationPermission.bind(this, true));
        } else {
            // Denied
            console.log("Notifications won't be shown as permission is denied");
        }
    });
};

const checkAutoInit = () => {
    _FirebasePlugin.isAutoInitEnabled(function (enabled) {
        console.log("Auto init is " + (enabled ? "enabled" : "disabled"));
    }, function (error) {
        console.log("Failed to check auto init", error);
    });
};

/* const enableAutoInit = () => {
    _FirebasePlugin.setAutoInitEnabled(true, function () {
        console.log("Enabled auto init");
        checkAutoInit();
    }, function (error) {
        console.log("Failed to enable auto init", error);
    });
};

const disableAutoInit = () => {
    _FirebasePlugin.setAutoInitEnabled(false, function () {
        console.log("Disabled auto init");
        checkAutoInit();
    }, function (error) {
        console.log("Failed to disable auto init", error);
    });
};
 */
/* const getID = () => {
    _FirebasePlugin.getId(function (id) {
        console.log("Got FCM ID: " + id)
    }, function (error) {
        console.log("Failed to get FCM ID", error);
    });
};
 */
const getToken = () => {
    _FirebasePlugin.getToken(function (token) {
        console.log("Got FCM token: " + token)
    }, function (error) {
        console.log("Failed to get FCM token", error);
    });
};

const getAPNSToken = () => {
    _FirebasePlugin.getAPNSToken(function (token) {
        console.log("Got APNS token: " + token)
    }, function (error) {
        console.log("Failed to get APNS token", error);
    });
};


const handleNotificationMessage = (message, history) => {
    console.log('handleNotificationMessage:', message, history);
    if (!message.tap) dataMessage = message;
    processMessage(message, history);

};

const handleDataMessage = (message, history) => {
    console.log("handleDataMessage: data message received: ", message);
    if (!message.tap) dataMessage = message;
    console.log("handleDataMessage: dataMessage: ", dataMessage);
    processMessage(message, history);
};

const processMessage = (message, history) => {
    console.log('.....processMessage message.tap:', message.tap, dataMessage);
    const location = history.location;
    const today = moment().format('YYYY-MM-DD');
    if (!dataMessage) dataMessage = message;
    if (message.tap) {
        let hash = `#/calendar/${today}`;
        console.log('Eval:', dataMessage.match, dataMessage.news);

        if (dataMessage.match) {
            hash = `#/matches/${dataMessage.match.replace('_', '')}`;
        } else if (dataMessage.news) {
            hash = `#/news/${dataMessage.news.replace('_', '')}`;
        } else { }

        console.log('hash:', hash, history);
        history.push(`${location.pathname}${hash}`);
        history.go(0);
        location.hash = hash;
        dataMessage = null;
    }
}


/* function clearNotifications() {
    _FirebasePlugin.clearAllNotifications(function () {
        console.log("Cleared all notifications");
    }, function (error) {
        console.log("Failed to clear notifications", error);
    });
} */

export const subscribe = (topic) => {
    console.log('Push suscribing to:', topic);

    if (_FirebasePlugin) _FirebasePlugin.subscribe(topic,
        () => {
            console.log("Subscribed to topic:", topic);
        },
        (error) => {
            console.log("Failed to subscribe to topic", error);
        }
    );
}

export const unsubscribe = (topic) => {
    console.log('Push UNsuscribing to:', topic);

    if (_FirebasePlugin) _FirebasePlugin.unsubscribe(topic,
        () => {
            console.log("Unsubscribed from topic:", topic);
        },
        (error) => {
            console.log("Failed to unsubscribe from topic", error);
        }
    );
}

/* function getBadgeNumber() {
    _FirebasePlugin.getBadgeNumber(function (number) {
        console.log("Current badge number: " + number);
    }, function (error) {
        console.log("Failed to get badge number", error);
    });
}

function incrementBadgeNumber() {
    _FirebasePlugin.getBadgeNumber(function (current) {
        let number = current + 1;
        _FirebasePlugin.setBadgeNumber(number, function () {
            console.log("Set badge number to: " + number);
        }, function (error) {
            console.log("Failed to set badge number", error);
        });
    }, function (error) {
        console.log("Failed to get badge number", error);
    });
}

function clearBadgeNumber() {
    _FirebasePlugin.setBadgeNumber(0, function () {
        console.log("Cleared badge number");
    }, function (error) {
        console.log("Failed to clear badge number", error);
    });
}

function unregister() {
    _FirebasePlugin.unregister(function () {
        console.log("Unregistered from Firebase");
    }, function (error) {
        console.log("Failed to unregister from Firebase", error);
    });
} */

// Crashlytics
/* function setCrashlyticsCollectionEnabled(enabled) {
    _FirebasePlugin.setCrashlyticsCollectionEnabled(enabled, function () {
        console.log("Crashlytics data collection has been " + (enabled ? "enabled" : "disabled"));
    }, function (error) {
        console.log("Failed to enable crashlytics data collection", error);
    });
}

function isCrashlyticsCollectionEnabled() {
    _FirebasePlugin.isCrashlyticsCollectionEnabled(function (enabled) {
        console.log("Crashlytics data collection setting is " + (enabled ? "enabled" : "disabled"));
    }, function (error) {
        console.log("Failed to fetch crashlytics data collection setting", error);
    });
}

function isCrashlyticsCollectionCurrentlyEnabled() {
    _FirebasePlugin.isCrashlyticsCollectionCurrentlyEnabled(function (enabled) {
        console.log("Crashlytics data collection is currently " + (enabled ? "enabled" : "disabled") + " for this session");
    }, function (error) {
        console.log("Failed to fetch crashlytics data collection session value", error);
    });
}

function setCrashlyticsUserId() {
    _FirebasePlugin.setCrashlyticsUserId("crashlytics_user_id", function () {
        console.log("Set crashlytics user ID");
    }, function (error) {
        console.log("Failed to set crashlytics user ID", error);
    });
}

function sendNonFatal() {
    _FirebasePlugin.console.log("This is a non-fatal error", function () {
        console.log("Sent non-fatal error");
    }, function (error) {
        console.log("Failed to send non-fatal error", error);
    });
}

function logCrashMessage() {
    _FirebasePlugin.logMessage("A custom message about this crash", function () {
        console.log("Logged crash message - it will be sent with the next crash");
    }, function (error) {
        console.log("Failed to log crash message", error);
    });
}

function sendCrash() {
    _FirebasePlugin.sendCrash();
} */

// Analytics
/* function setAnalyticsCollectionEnabled() {
    if (_FirebasePlugin) _FirebasePlugin.setAnalyticsCollectionEnabled(true, function () {
        console.log("Enabled analytics data collection");
    }, function (error) {
        console.log("Failed to enable analytics data collection", error);
    });
}

function isAnalyticsCollectionEnabled() {
    if (_FirebasePlugin) _FirebasePlugin.isAnalyticsCollectionEnabled(function (enabled) {
        console.log("Analytics data collection setting is " + (enabled ? "enabled" : "disabled"));
    }, function (error) {
        console.log("Failed to fetch Analytics data collection setting", error);
    });
} */

export const logEvent = (eventName, contenType, item_id) => {
    if (_FirebasePlugin) _FirebasePlugin.logEvent(eventName, {
        content_type: contenType,
        item_id: item_id
    }, function () {
        console.log("Logged event");
    }, function (error) {
        console.log("Failed to log event", error);
    });
}

export const setScreenName = (screenName) => {
    if (_FirebasePlugin) _FirebasePlugin.setScreenName(screenName, function () {
        console.log("Sent screen name");
    }, function (error) {
        console.log("Failed to send screen name", error);
    });
}

export const setUserID = () => {
    if (_FirebasePlugin) _FirebasePlugin.setUserId("user_id", function () {
        console.log("Set user ID");
    }, function (error) {
        console.log("Failed to set user ID", error);
    });
}

export const setUserProperty = () => {
    if (_FirebasePlugin) _FirebasePlugin.setUserProperty("some_key", "some_value", function () {
        console.log("Set user property");
    }, function (error) {
        console.log("Failed to set user property", error);
    });
}

// Performance
/* function setPerformanceCollectionEnabled() {
    _FirebasePlugin.setPerformanceCollectionEnabled(true, function () {
        console.log("Enabled performance data collection");
    }, function (error) {
        console.log("Failed to enable performance data collection", error);
    });
}

function isPerformanceCollectionEnabled() {
    _FirebasePlugin.isPerformanceCollectionEnabled(function (enabled) {
        console.log("Performance data collection setting is " + (enabled ? "enabled" : "disabled"));
    }, function (error) {
        console.log("Failed to fetch Performance data collection setting", error);
    });
} */