export const initChat = () => {
    console.log('chat.initChat window.$crisp:', window.$crisp);

    if (!window.$crisp) {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "949abaae-f3c4-4b72-9e9b-c3c6877d64ee";
        const d = window.document;
        const s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
        hideChat();
    }
}

export const hideChat = () => {
    if (window.$crisp && window.$crisp.is) window.$crisp.push(['do', 'chat:hide']);
}

export const showChat = () => {
    if (window.$crisp && window.$crisp.is) window.$crisp.push(['do', 'chat:show']);
}

export const openChat = () => {
    if (window.$crisp && window.$crisp.is) window.$crisp.push(['do', 'chat:open']);
}

export const closeChat = () => {
    if (window.$crisp && window.$crisp.is) window.$crisp.push(['do', 'chat:close']);
}

export const openCloseChat = (username) => {
    if (window.$crisp && window.$crisp.is) {
        setChatUserName(username);
        if (window.$crisp.is("chat:opened")) {
            closeAndHideChat();
        }
        else {
            showAndOpenChat();
        }
    } else {
        console.error('openCloseChat: no $crisp');
    }
}

export const showAndOpenChat = () => {
    window.$crisp.push(['do', 'chat:open']);
    window.$crisp.push(['do', 'chat:show']);
}

export const closeAndHideChat = () => {
    window.$crisp.push(['do', 'chat:hide']);
    window.$crisp.push(['do', 'chat:close',]);
}

export const setChatUserName = (username) => {
    if (window.$crisp && username) {
        if (!window.$crisp.get("user:nickname")) window.$crisp.push(["set", "user:nickname", [username]]);
    }
}