import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Progress } from 'antd';
import Result from '../../base/result';

import { generateRandomNumberSeries } from '../../../utils';

import { motion } from 'framer-motion';
import { t } from '../../../languages';

import './index.less';
import PathGrid from '../../base/Path/Grid';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';

const PathChall = ({ lang, params, uid, eid, saveResult, handleCancel }) => {
    // console.log('PathChall:', lang, params, uid, eid);

    const [series, setSeries] = useState([]);
    const [current, setCurrent] = useState(0);
    const [currentNumber, setCurrentNumber] = useState(0);
    const [results, setResults] = useState([]);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [showPath, setShowPath] = useState(false);

    useEffect(() => {
        handleRepeat();
    }, []);

    const handleNext = () => {
        if (current < series.length - 1) {
            setCurrentNumber(series[current + 1]);
            setCurrent(current + 1);
            setMessage(null);
            setMessage2(null);
        } else {
            const num = params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > params.errors ? 0 : 1;
            const coins = params.coins;

            saveResult(uid, `${uid}-${eid}`, { id: 'PC', uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), r: results, s: series });
            setShowresults(true);
        }
        setShowPath(false);
    }

    const handleRepeat = () => {
        const series = params ? generateRandomNumberSeries(params.num, params.init, params.finish, null) : generateRandomNumberSeries(9, 1, 9, null);
        // console.log('PathChall.handleRepeat:', series);

        setSeries(series);
        setCurrentNumber(series[0]);
        setCurrent(0);
        setResults([]);
        setMessage(null);
        setShowresults(false);
        setShowPath(false);
    }

    const handleRate = (success, timereq) => {
        // console.log('Pathchall handleRate:', success, timereq);
        let message = null;
        let message2 = null;

        if (success) {
            message = t(lang, 'rightpath');
            message2 = null; //t(lang, 'great');
        } else {
            message = t(lang, 'wrongpath');
            message2 = null; //t(lang, 'nodiscorage');
            setShowPath(true);
        }

        results.push(success);
        setResults(results);
        setMessage(message);
        setMessage2(message2);
    }

    const rate = results[current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const percent = parseInt((current) / params.num * 100);

    const is_test = params.is_test ? params.is_test : false;

    // console.log('currentNumber:', currentNumber);

    if (!showresults) {
        if (currentNumber) return (
            <Row className="exercise pathchall">
                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <Progress percent={percent} showInfo={false} />
                </Col> : null}

                <Col span={24} className="exercise-body pathchall-body">

                    <section className="pathchall">
                        <article className="pathchall">
                            <ExerciseBlocker show={messagestyle} />
                            <PathGrid
                                key={current}
                                items={params.items}
                                cols={params.cols}
                                value={currentNumber}
                                init={params.ispan}
                                finish={params.fspan}
                                onComplete={handleRate}
                                showPath={showPath}
                            />
                        </article>
                        <article className="number" key={currentNumber}>
                            <motion.div animate={{ scale: 2 }} transition={{ duration: 1 }} className="the-number" >{params.op}{currentNumber}</motion.div>
                        </article>
                    </section>
                </Col>
                <Col span={24}>
                    <Row className={'botones pathchall' + messagestyle}>
                        <Col span={24} className={'message'}>{message}</Col>
                        {message ? <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(lang, 'next')}</Button></Col> : null}
                        {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col>
            </Row >
        );
        else return null;
    } else {
        return (
            <Result params={params} results={results} handleRepeat={handleRepeat} handleCancel={handleCancel} lang={lang} />
        );
    }
};

export default PathChall;
