import React, { useEffect, useRef, useState } from 'react';

import { generateRandomNumber } from '../../../utils';

import { generateRandomNumberSeries } from '../../../utils';
import './index.less';
import { generateForwardPath, generatePath } from './pathutils';

const MAX_ROWS = 5;
const MAX_COLS = 4;

const calcValueForSelected = (items) => {
  let val = 0;

  if (items) {
    for (let idx = 0; idx < items.length; idx++) {
      const aRow = items[idx];
      val += aRow.reduce((acc, cur) => {
        if (cur.selected) return acc + cur.val;
        else return acc;
      }, 0);
    }
  }

  // console.log('calcValueForSelected:', val);

  return val;
}


const ForwardGrid = props => {
  const [items, setItems] = useState([]);
  const [good, setGood] = useState(false);
  const [bad, setBad] = useState(false);
  const [remaining, setRemaining] = useState(null);
  const [cols, setCols] = useState(null);
  const [serie, setSerie] = useState(null);
  const [showPath, setShowPath] = useState(false);
  const [lastPos, setLastPos] = useState(null);
  const [solution, setSolution] = useState(0);

  const containerRef = useRef();

  useEffect(() => {
    // console.log('ForwardGrid.useEffect:', props);
    const num_items = props.items;
    const lSerie = [0];
    for (let idx = 1; idx < num_items; idx++) {
      lSerie.push(0);
    }

    // console.log('lSerie init:', lSerie);

    const COLS = props.cols ? props.cols : MAX_COLS;
    const ROWS = props.rows ? props.rows : MAX_ROWS;
    const initX = props.initpos ? props.initpos.x : 0;
    const initY = props.initpos ? props.initpos.y : 0;
    const endX = props.endpos ? props.endpos.x : ROWS - 1;
    const endY = props.endpos ? props.endpos.y : COLS - 1;

    // console.log('ForwardGrid COLS ROWS initX initY:', COLS, ROWS, initX, initY);

    let lItems = [];
    for (let ydx = 0; ydx < ROWS; ydx++) {
      const aRow = [];
      const numbers = generateRandomNumberSeries(COLS, props.init, props.finish, [props.value]);
      for (let xdx = 0; xdx < COLS; xdx++) {
        // const isSelected = xdx == initX && ydx == initY;
        aRow.push({ val: numbers[xdx], x: xdx, y: ydx });
      }

      lItems.push(aRow);
    }

    // console.log('ForwardGrid lSerie:', lSerie, '::lItems:', lItems);

    const llastPos = generateForwardPath(lSerie, lItems, initX, initY, endX, endY, props.init, props.finish);
    // console.log('lItems | lSerie:', lItems, '|', lSerie, '|', llastPos);
    setItems(lItems);
    setSerie(lSerie);
    setLastPos(llastPos);
    setSolution(lSerie.reduce((acc, val) => acc + val, 0))

    setRemaining(num_items - 1);

    setCols(props.cols);
    // setShowPath(props.showPath);
  }, []);

  useEffect(() => {
    // ...
    setShowPath(props.showPath);

    return () => {
      // ...
    };
  }, [props]);

  const clickHandle = (idx, item) => {
    // console.log('ForwardGrid.clickHandle:', item, props);
    const date = new Date();

    if (item != null) {
      const lItems = [...items];
      lItems[item.y][item.x].selected = !lItems[item.y][item.x].selected;
      setItems(lItems);
      const currVal = calcValueForSelected(items);
      if (props.onClick) props.onClick(solution, currVal, items);
    }
  };



  return (
    <div className={`pathgrid forward ${!remaining ? 'completed' : ''}`} ref={containerRef}>

      {good ? (<audio src="./static/sounds/pop.mp3" autoPlay={true} loop={false}></audio>) : null}
      {bad ? (<audio src="./static/sounds/jump.mp3" autoPlay={true} loop={false}></audio>) : null}

      {!remaining || showPath ? <div className='pathchall-over'></div> : null}

      {items
        ? items.map((aRow, ydx) => {
          let isLastPos = false;

          return (
            <div key={ydx} className="pathgrid-row">
              <div key={'start-' + ydx} className={`pathgrid-item inactive ${ydx == 0 ? 'start' : ''}`} onClick={() => clickHandle(.1, null)}>
                {ydx == 0 ? <img className="pabacus" src={`./static/myspace/right-arrow.svg`} alt="abacus" /> : null}
              </div>
              {aRow.map((aI, idx) => {
                const val = aI.val;
                let digits = val.toString().split('');
                let itemStyle = 'pathgrid-item';
                if (aI.selected) itemStyle += ' selected';
                if (showPath && aI.ispath) itemStyle += ' showpath';
                if (aI.error) itemStyle += ' error';

                isLastPos = lastPos.x == ydx && lastPos.y == idx;

                return (
                  <div key={idx + '-' + ydx} className={itemStyle} onClick={() => clickHandle(idx, aI)}>
                    {digits.map((el, edx) => (
                      <div key={edx + '-' + idx + '-' + ydx}>
                        {props.shownumbers ? el : <img className="pabacus" src={`./static/snapshots/a${el}.png`} alt="abacus" />}
                      </div>
                    ))}
                  </div>
                );
              })}
              <div key={'end-' + ydx} className={`pathgrid-item inactive ${isLastPos ? 'end' : ''}`} onClick={() => clickHandle(.1, null)}>
                {isLastPos ? solution : null}
              </div>
            </div>
          );
        })
        : null}
    </div>
  );
};

export default ForwardGrid;
