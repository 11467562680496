import { Avatar, Button, Menu, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { t } from '../languages';
import { openCloseChat } from '../modules/chat';
import { loadVersion } from '../modules/user';
import ActivatedNavLink from './Utils/ActivatedNavLink';

const app_menu_items = [
  { key: 'learn', link: '/learn', avatar: 'learn' },
  { key: 'practice', link: '/practice', avatar: 'practice' },
  { key: 'challenge', link: '/challenge', avatar: 'challenge' },
  { key: 'profile', link: '/profile', avatar: 'profile' },
];

const course_menu_items = [
  { key: 'course', link: '/course', avatar: 'learn' },
  { key: 'profile', link: '/profile', avatar: 'profile' },
  { key: 'chat', action: 'open-close-chat', avatar: 'chat' },
];

const teacher_menu_items = [
  { key: 'course', link: '/course', avatar: 'learn' },
  { key: 'resources', link: '/resources', avatar: 'resources' },
  { key: 'profile', link: '/profile', avatar: 'profile' },
  { key: 'chat', action: 'open-close-chat', avatar: 'chat' },
];

const INITLEFT = 10;
const INITBOTTOM = 15;

const BottomMenu = ({
  section,
  user,
  loadVersion,
  version,
  lang,
  version_description
}) => {
  const [versionModalVisible, setVersionModalVisible] = useState(false);
  const [showMenu, setShowMenu] = React.useState(true);//React.useState(isPortrait());

  const onResize = () => {
  };

  useEffect(() => {
    // window.addEventListener('resize', onResize);
    return () => {
      // window.removeEventListener('resize', onResize);
    };
  }, []);

  const toggleVersionModal = () => {
    setVersionModalVisible(!versionModalVisible);
  };

  const evalMenuAction = (action) => {
    // console.log('evalMenuAction:', action);
    if (action === 'open-close-chat') openCloseChat(user.name);
  }

  const learnon = section == 'learn' ? '_a' : '';
  const practiceon = section == 'practice' ? '_a' : '';
  const profileon = section == 'profile' ? '_a' : '';
  const challengeon = section == 'challenge' ? '_a' : '';
  const courseon = section == 'course' ? '_a' : '';
  const resourceson = section == 'resources' ? '_a' : '';

  const logged = user && user.token;

  const isDev = process.env.NODE_ENV === 'development';

  let menu_items = app_menu_items;
  if (user && user.products && user.products.includes('course')) menu_items = course_menu_items;
  else if (user && user.products && user.products.includes('teacher')) menu_items = teacher_menu_items;

  return (
    <div className="bottom-menu">
      {showMenu ? (
        <Menu selectedKeys={[section]} mode="horizontal" className="menu">
          {menu_items.map(aMI => <Menu.Item key={aMI.key} disabled={!logged} className={aMI.action ? 'action' : ''}>
            {aMI.link ? <ActivatedNavLink to={aMI.link} active={logged}>
              <Avatar
                size="small"
                shape="square"
                src={`./static/menu/${aMI.avatar}${section == aMI.key ? '_a' : ''}.svg`}
              />
            </ActivatedNavLink> : <div onClick={() => evalMenuAction(aMI.action)}><Avatar
              size="small"
              shape="square"
              src={`./static/menu/${aMI.avatar}${section == aMI.key ? '_a' : ''}.svg`}
            /></div>}
          </Menu.Item>)}
        </Menu>
      ) : null}
      <Modal
        className="version-modal"
        visible={versionModalVisible}
        onOk={toggleVersionModal}
        onCancel={toggleVersionModal}>
        <h4>{t(lang, 'newVersion')}</h4>
        <h5>{version_description}</h5>
        <div className="version-buttons">
          <Button className="version-no" onClick={toggleVersionModal}>
            {t(lang, 'notNow')}
          </Button>
          <Button className="version-ok" href="http://onelink.to/misoroban">
            {t(lang, 'ok')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  version: user.app_version,
  lang: user.lang,
  version_description: user.version_description
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadVersion
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BottomMenu);
