import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';

import './index.less';
import { bindActionCreators } from 'redux';
import { t } from '../../../languages';
import { loadRanking } from '../../../modules/user';

const UserRanking = ({ user, lang, loadRanking, ranking }) => {

    // console.log('UserRanking user:', user);

    useEffect(() => {
        loadRanking();

        return () => {
        }

    }, []);

    useEffect(() => {
        loadRanking();
    }, [user.points]);


    if (!user) {
        return null;
    } else {
        const points = user.points || 0;
        const position = ranking && ranking.mine ? ranking.mine.position : 0;
        const avatar = user.avatar || 0;

        const top10 = ranking && ranking.top10 ? ranking.top10 : [];
        const pos1 = top10 ? top10[0] : null;
        const pos2 = top10 ? top10[1] : null;
        const pos3 = top10 ? top10[2] : null;
        const top7 = top10.length > 3 ? top10.slice(3) : null;

        return <section className='user-ranking'>
            <section className='user-points'>
                <div className='avatar'>
                    {avatar ? <img src={`./static/avatar/${avatar ? avatar : 0}.jpg`} alt="" /> : null}
                </div>
                <div className='user-data'>
                    <h5>{user.name}</h5>
                    <ul>
                        <li><h6>{t(lang, 'yourpoints')}:</h6> <span className='points'><img src="./static/myspace/coin.svg" alt="" /> {points}</span></li>
                        <li><h6>{t(lang, 'yourposition')}:</h6> <span>{position}</span></li>
                    </ul>
                </div>
            </section>

            <section className='top-ten-ranking'>
                <div className='ranking-header'>{t(lang, 'top10ranking')} <img src="./static/myspace/trophy.svg" alt="" /></div>
                <div className='top3'>
                    <div className='second-p'>
                        <ul>
                            <li className='avatar'><img src={`./static/avatar/${pos2 && pos2.avatar ? pos2.avatar : 0}.jpg`} alt="" /></li>
                            <li>{pos2 ? pos2.name : '-'}</li>
                            <li className='points'><img src="./static/myspace/coin.svg" alt="" /> {pos2 ? pos2.points : '-'}</li>
                        </ul>
                    </div>
                    <div className='first-p'>
                        <ul>
                            <li className='avatar'><img src={`./static/avatar/${pos1 && pos1.avatar ? pos1.avatar : 0}.jpg`} alt="" /></li>
                            <li>{pos1 ? pos1.name : '-'}</li>
                            <li className='points'><img src="./static/myspace/coin.svg" alt="" /> {pos1 ? pos1.points : '-'}</li>
                        </ul>
                    </div>
                    <div className='third-p'>
                        <ul>
                            <li className='avatar'><img src={`./static/avatar/${pos3 && pos3.avatar ? pos3.avatar : 0}.jpg`} alt="" /></li>
                            <li>{pos3 ? pos3.name : '-'}</li>
                            <li className='points'><img src="./static/myspace/coin.svg" alt="" /> {pos3 ? pos3.points : '-'}</li>
                        </ul>
                    </div>
                </div>

                {top7 ? <div className='top-4-10'>
                    <ul>
                        {top7.map((aU, idx) => {
                            return <li key={idx}>
                                <span>{idx < 6 ? '0' : ''}{idx + 4}</span>
                                <span><img src={`./static/avatar/${aU.avatar ? aU.avatar : 0}.jpg`} alt="" /> {aU.name}</span>
                                <span className='points'><img src="./static/myspace/coin.svg" alt="" /> {aU.points}</span>
                            </li>
                        })}
                    </ul>
                </div> : null}
            </section>
        </section>;
    }
};

const mapStateToProps = ({ user }) => ({
    user: user.user,
    lang: user.lang,
    ranking: user.ranking,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadRanking
        },
        dispatch
    );

export default memo(connect(mapStateToProps, mapDispatchToProps)(UserRanking));
