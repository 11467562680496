import { Button } from 'antd';
import { t } from '../../languages';


const WebSubscriptionButton = ({ lang, user }) => {

    if (user) {
        // const storeUrl = `https://www.misoroban.com/products/?uid=${user.id}/#!/App-Online-MiSoroban/p/676269917`;
        const gStoreUrl = `https://play.google.com/store/apps/details?id=com.enmotionvalue.misoroban`;
        const aStoreUrl = `https://apps.apple.com/es/app/misoroban/id6484265986`;
        return <section className="subscribe-button">
            <div className='header'><img src={'./static/subscription/crown.svg'} /></div>
            <a href={gStoreUrl} target="app"><Button className="activar">
                <img src={`./static/brand/google-play.svg`} alt="bead" />
                {t(lang, 'subscribetoactivateGoogleplay')}
            </Button></a>
            <a href={aStoreUrl} target="app"><Button className="activar">
                <img src={`./static/brand/apple.svg`} alt="bead" />
                {t(lang, 'subscribetoactivateAppleStore')}
            </Button></a>
        </section>;
    } else return null;
}

export default WebSubscriptionButton;