/* eslint no-eval: 0 */
import React, { useEffect, useState } from 'react'

import { Row, Col, Button, Progress } from 'antd';
import Result from '../../../base/result';
import { generateRandomNumberSeries, isPortrait, shuffle } from '../../../../utils';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../../languages';
import ExerciseBlocker from '../../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../../Utils/ChangeOrientation';
import MyTimer from '../../../Utils/time/MyTimer';
import NumPad from '../../../Utils/NumPad';

const NextButton = ({ num, lang, portrait, handleNext, handleRepeat, handleCancel }) => {
    if (num == 1) {
        const colStyle = { display: 'inline-block', with: '50%' };
        return <>
            <Col span={12} style={colStyle}><Button key="repeat" onClick={handleRepeat} type={'primary'} style={{ 'width': '90%' }}>{t(lang, 'repeat')}</Button></Col>
            <Col span={12} style={colStyle}><Button key="submit" type="primary" onClick={handleCancel} style={{ 'width': '90%' }}>{t(lang, 'accept')}</Button></Col>
        </>;
    } else {
        return <Col span={portrait ? 24 : 12}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(lang, 'next')}</Button></Col>;
    }
}

const OBJECTS = ['icons8-blue-circle-48.png', 'icons8-brown-square-48.png', 'icons8-large-blue-diamond-48.png', 'icons8-large-orange-diamond-48.png', 'icons8-orange-circle-48.png', 'icons8-orange-square-48.png', 'icons8-red-triangle-pointed-down-48.png', 'icons8-yellow-circle-48.png', 'icons8-yellow-square-48.png',];

const NumberRiddle = (props) => {

    const [eid, setEid] = useState(0);
    const [series, setSeries] = useState([]);
    const [guess, setGuess] = useState([]);
    const [objectNumbers, setObjectNumbers] = useState(null);

    const [current, setCurrent] = useState(0);
    const [results, setResults] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);

    const [times, setTimes] = useState([]);
    const [stopTime, setStopTime] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);


    useEffect(() => {
        handleRepeat();
    }, []);

    const handleRate = () => {
        const currentGuess = guess[current];

        const current_result = currentGuess === numberinbox ? 1 : 0;

        const message = current_result ? `${t(props.lang, 'rightanswer')}` : `${t(props.lang, 'theansweris')} ${currentGuess}`;
        const message2 = current_result ? t(props.lang, 'great') : t(props.lang, 'nodiscorage');

        results.push(current_result);

        setResults(results);
        setMessage(message);
        setMessage2(message2);

        setStopTime(true);
    }

    const handleNext = () => {
        if (current < series.length - 1) {
            setCurrent(current + 1);
            setNumberinbox(0);
            setMessage(null);
            setMessage2(null);
            setMoved(0);
            setStopTime(false);

        } else {
            const num = props.params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > props.params.errors ? 0 : 1;
            const uid = props.uid;
            const eid = props.eid;
            const coins = props.params.coins;

            const is_test = props.params.is_test ? props.params.is_test : false;

            const totalTime = times.reduce((acc, aT) => acc += aT, 0);
            props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: totalTime, c: (passed ? coins : 0) }, times, false);

            if (!is_test) {
                setShowresults(true);
            }
        }
    }

    const generateTuples = (params) => {
        const permOperations = params.permOps ? params.permOps : ['+', '-', '*'];

        // console.log('generateTuples:', params, permOperations);

        const lseries = [];
        const guess = [];
        const numbers = [];

        for (let idx = 0; idx < params.num; idx++) {
            const aSerie = generateRandomNumberSeries(3, params.init, params.finish);
            numbers.push(aSerie);
            guess.push(shuffle(aSerie)[0]);
            const ops = shuffle(permOperations);
            const aNewSerie = [];
            for (let idy = 0; idy < ops.length; idy++) {
                const op = ops[idy];
                let e1 = aSerie[idy];
                let e2 = aSerie[idy == ops.length - 1 ? 0 : idy + 1];
                if (op == '-' && e1 < e2) {
                    let t1 = e1;
                    e1 = e2; e2 = t1;
                }
                aNewSerie.push(`${e1} ${op} ${e2}`);
            }
            lseries.push(aNewSerie);
        }

        return [lseries, guess, numbers];
    }

    const handleRepeat = () => {

        const params = props.params;
        const [series, guess, numbers] = generateTuples(params);
        // console.log('NumberRiddle series:', series, guess, numbers);

        const objectNumbers = numbers.map(aNS => {
            const sObjs = shuffle(OBJECTS);
            const aObjsNum = {};
            aNS.forEach((aN, idx) => {
                aObjsNum[aN] = sObjs[idx];
            });

            return aObjsNum;
        })

        // console.log('NumberRiddle objectNumbers:', objectNumbers);

        setSeries(series);
        setGuess(guess);
        setObjectNumbers(objectNumbers);

        setCurrent(0);
        setResults([]);
        setNumberinbox(0);
        setMessage(null);
        setMessage2(null);
        setShowresults(false);
        setMoved(0);

        setStopTime(false);
        setTimes([]);
        setIsTimeout(false);
    }

    const handleChange = (num) => {
        setNumberinbox(num);
        setMoved(1);
    }

    const handleStop = (time) => {
        const lTimes = [...times];
        lTimes.push(time);
        setTimes(lTimes);
    }

    const handleExpire = () => {

        const num = props.params.num;
        const hits = results && results.length > 0 ? results.reduce((acc, aR) => acc += aR) : 0;
        const errors = num - hits;
        const passed = errors > props.params.errors ? 0 : 1;
        const uid = props.uid;
        const eid = props.eid;

        const is_test = props.params.is_test ? props.params.is_test : false;

        props.saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: props.params.timeout }, [], true);

        if (!is_test) {
            setTimes([props.params.timeout]);
            setStopTime(true);
            setIsTimeout(true);
            setShowresults(true);
        }
    }

    if (!showresults) {
        const params = props.params;

        const finish = params.finish | 9;

        const currentSerie = series[current];
        const currentGuess = guess[current];
        const currObjNum = objectNumbers ? objectNumbers[current] : null;

        const isLast = params.num >= current - 1;

        const rate = results[current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const percent = parseInt((current) / params.num * 100);

        const is_test = params.is_test || false;

        const rows = params.rows || 3;

        const portrait = isPortrait();

        const time = new Date();
        time.setSeconds(time.getSeconds() + params.timeout);

        if (!currentSerie) return null;
        else return (
            <Row className="exercise">

                <ChangeOrientation to={'portrait'} />


                {!is_test ? <Col span={22} className='progress-bar'>
                    <div className='progress'><Progress percent={percent} showInfo={false} /> </div>
                </Col> : null}

                <Col span={24} className='exercise-timer'>
                    <MyTimer expiryTimestamp={time} stopTime={stopTime} handleExpire={handleExpire} handleStop={(t) => handleStop(t)} />
                </Col>

                <Col span={24} className="exercise-body">
                    <ExerciseBlocker show={messagestyle} />
                    <section className="numberriddle">
                        <article className="number" key={currentSerie}>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, scale: 1.4 }}
                                transition={{
                                    delay: 0.3,
                                    duration: 1,
                                    ease: [0, 0.71, 0.2, 1.01],
                                    scale: {
                                        type: "spring",
                                        damping: 3,
                                        stiffness: 100,
                                        restDelta: 0.001
                                    }
                                }}
                                className="the-number"
                            >
                                <div>
                                    <ul>{currentSerie ? currentSerie.map((aS, idx) => {
                                        let [n1, op, n2] = aS.split(/(\+|-|\*|\/)/sg);
                                        op = op.replace('*', 'x');
                                        const img1 = `./static/shapes/${currObjNum[n1.trim()]}`;
                                        const img2 = `./static/shapes/${currObjNum[n2.trim()]}`;
                                        return <li key={idx}>
                                            <span className='op'> <img src={img1} alt="" />  {op}  <img src={img2} alt="" /></span>
                                            <span>=</span>
                                            <span className='equals'>{eval(aS)}</span>
                                        </li>;
                                    }) : null}</ul>
                                    <div className='guess'>
                                        <img src={`./static/shapes/${currObjNum[currentGuess]}`} alt="" />
                                        <span>?</span>
                                        <span className='tip'>(1-{finish})</span>
                                    </div>
                                </div>
                            </motion.div>
                        </article>

                        <Row className="input-number">
                            <Col span={24}>
                                <NumPad clickHandler={(val) => handleChange(val)} clear={!moved} />
                            </Col>
                        </Row>

                    </section>
                </Col>
                {portrait || message || moved ? <Col span={24} className={'botonera'}>
                    <Row className={'botones numberriddle' + messagestyle}>
                        <Col span={portrait ? 24 : 12} className={'message'}>{message}</Col>
                        {!message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                        {message ? <Col span={portrait ? 24 : 12}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(props.lang, isLast ? 'accept' : 'next')}</Button></Col> : null}
                        {message2 ? <Col span={portrait ? 24 : 12} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col> : null}
            </Row >
        );
    } else {
        return (
            <Result params={props.params} results={results} show_time={true} times={times} is_timeout={isTimeout} handleRepeat={handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
        );
    }

}

export default NumberRiddle;

