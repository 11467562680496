import { useEffect, useState } from 'react';

import { Button, Col, Progress, Row } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';

import { motion } from 'framer-motion';
import { t } from '../../../languages';
import ChangeOrientation from '../../Utils/ChangeOrientation';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import './index.less';

const GuidedSequence = ({ lang, params, uid, eid, saveResult, handleCancel }) => {
    const [series, setSeries] = useState(params ? params.series : null);
    const [current, setCurrent] = useState(0);
    const [currentPoint, setCurrentPoint] = useState(0);
    const [results, setResults] = useState([]);
    const [times, setTimes] = useState([]);
    const [numberinbox, setNumberinbox] = useState(0);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [moved, setMoved] = useState(0);

    useEffect(() => {
        const currentElem = series ? series[current] : null;
        const voice = currentElem && currentElem.voice ? `./static/voices/${lang}/${currentElem.voice}.mp3` : null;
        if (voice) { const audio = new Audio(voice); audio.play(); }
        if (!currentElem.points) {
            setMoved(true);
            results.push(1);
            setResults(results);
        }

    }, [current]);

    const handleNext = () => {
        // console.log('handleNext...');
        if (current < series.length - 1) {
            setCurrent(current + 1);
            setCurrentPoint(0);
            setNumberinbox(0);
            setMessage(null);
            setMessage2(null);
            setMoved(0);
        } else {
            const num = params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > params.errors ? false : true;

            saveResult(uid, `${uid}-${eid}`, { id: 'GS', uid, eid, p: passed, h: hits, e: errors, r: results, s: series });
            setShowresults(true);
        }
    }

    const handleRepeat = () => {
        // console.log('handleRepeat...');
        setSeries(params ? params.series : null);
        setCurrent(0);
        setCurrentPoint(0);
        setNumberinbox(0);
        setResults([]);
        setMessage(null);
        setShowresults(false);
        setMoved(0);
    }

    const handleChange = (num) => {
        if (!isNaN(num)) {
            const currentElem = series[current];
            const points = currentElem ? currentElem.points : null;

            const voice = points && points[currentPoint] && points[currentPoint].voice ? `./static/voices/${lang}/${points[currentPoint].voice}.mp3` : null;
            if (voice) { const audio = new Audio(voice); audio.play(); }

            // console.log('handleChange !isNaN:', points.length, current, currentPoint);
            if (points && currentPoint < points.length - 1) {
                setCurrentPoint(currentPoint + 1);
            } else {
                setMoved(1);
                setNumberinbox(num);
                const message = t(lang, 'rightanswer');
                const message2 = t(lang, 'great');
                results.push(1);
                setResults(results);
                setMessage(message);
                setMessage2(message2);
            }
        }
    }

    const currentElem = series ? series[current] : null;
    const text = currentElem ? currentElem.text : null;
    const points = currentElem ? currentElem.points : null;
    const point = points ? points[currentPoint] : null;
    const number = currentElem ? currentElem.number : undefined;

    const rate = results[current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const percent = parseInt((current) / params.num * 100);

    const is_test = params.is_test ? params.is_test : false;

    const locparams = { ...params, rows: params ? params.rows : 1, number: numberinbox };
    // console.log('GuidedSequence.render:', current, currentElem, point);

    if (!showresults) {
        return (
            <Row className="exercise" key="2">
                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <Progress percent={percent} showInfo={false} />
                </Col> : null}

                <Col span={24} className="exercise-body">
                    <ExerciseBlocker show={messagestyle} />
                    <section className="representinabacus">

                        {/* {isMobile() ? <TouchAbacus
                            rows={locparams.rows}
                            isactive={true}
                            hidelatframe={true}
                            onChange={handleChange}
                            reset={current}
                            beadcolors={['azul', 'rojo', 'verde']}
                            initcolor={1}
                            point={point}
                            guided={true}
                            current={current}
                            number={number}
                        /> : */}
                        <AbacusView
                            params={locparams}
                            isactive={true}
                            hidelatframe={true}
                            hideheaderlabels={true}
                            onChange={handleChange}
                            guided={true}
                            point={point}
                        />
                        {/* } */}

                        <article className="number" key={text}>
                            <motion.div
                                animate={{ scale: 2 }}
                                transition={{ duration: 1 }}
                                className="the-number"
                            >{text}</motion.div>
                        </article>
                    </section>
                </Col>
                <Col span={24}>
                    <Row className={'botones' + messagestyle}>
                        {message ? <Col span={24} className={'message'}>{message}</Col> : null}
                        <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }} disabled={!moved}>{t(lang, 'next')}</Button></Col>
                        {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col>
            </Row >
        );
    } else {
        return (
            <Result params={locparams} results={results} handleRepeat={handleRepeat} handleCancel={handleCancel} lang={lang} />
        );
    }
}

export default GuidedSequence;

