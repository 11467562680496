import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Carousel } from 'antd';
import { t } from '../../languages/index';

import wellcome from '../../utils/imgs/wellcome.png';

const WellMessage = ({ lang }) => {
  return (
    <section className="WellMessage">
      <Carousel autoplay autoplaySpeed={5000}>
        <div className="message">
          <span className='overimg'>
            <img src="./static/wellcome/group_4.png" className="misorobanlogo"></img>
          </span>
          <h2>{t(lang, 'wellcome')}!</h2>
          <p>
            {t(lang, 'makeurkids')}
          </p>
        </div>
        <div className="message">
          <span className='overimg'>
            <img src="./static/wellcome/group_5.png" className="misorobanlogo"></img>
          </span>
          <h2>{t(lang, 'funlearning')}</h2>
          <p>{t(lang, 'willlearntoopperate')}</p>
        </div>
        <div className="message">
          <span className='overimg'>
            <img src="./static/wellcome/group_6.png" className="misorobanlogo"></img>
          </span>
          <h2>{t(lang, 'dontworry')}</h2>
          <p>{t(lang, 'secure100pc')}</p>
        </div>
      </Carousel>

      <section className="wellcome-buttons">

        <div>
          <NavLink to="/register/language">
            <Button type="primary" className="button-blue">
              {t(lang, 'start')}
            </Button>
          </NavLink>
        </div>

        {/* <div>
          <NavLink to="/joinclass">
            <Button type="primary" className="button-next">{'Unirse a una clase'}</Button>
          </NavLink>
        </div> */}

        <div>
          <NavLink to="/profile/login" className="login">
            {t(lang, 'loginacc')}
          </NavLink>
        </div>
      </section>

    </section>
  );
};
export default WellMessage;
