import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'antd';
import { t } from '../../../languages/index';
import { User } from '../../../models/user';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';


const Step2 = ({ lang, user, save }) => {
    const [num, setNum] = useState(1);
    const [next, setNext] = useState(false);

    const incGamers = (op) => {
        if (op === '-') {
            if (num > 0) setNum(num - 1);
        } else if (op === '+') {
            if (num < 5) setNum(num + 1);
        }
    }

    const saveStep = () => {
        const aUser: User = user;
        aUser.children = [];
        for (let idx = 0; idx < num; idx++) {
            aUser.children[idx] = new User();
        }

        console.log('step aUser:', aUser);

        save(aUser);
        setNext(true);
    }

    if (!user) return <Redirect to="/register/step1" />
    else if (next) return <Redirect to="/register/step3" />
    else return (
        <section className="register2">
            <h2>{t(lang, 'wellcome')}</h2>
            <p>{t(lang, 'morestars')}</p>
            <form>
                <h3>{t(lang, 'howmanystars')}</h3>
                <article className="article-click">
                    <div className="click1" onClick={() => incGamers('-')}><MinusOutlined /></div>
                    <div className="click-number">{num}</div>
                    <div className="click2" onClick={() => incGamers('+')}><PlusOutlined /></div>
                </article>
                <div>
                    <Button type="primary" onClick={saveStep}>{t(lang, 'continue')}</Button>
                </div>
            </form>
        </section>
    );
};
export default Step2;