import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { isMobile } from '../../../utils';

// const BTOP = -30; // small
const BTOP = -35; // medium
// const BTOP = -56; // big
const BBOTTOM = 0;

const TTOP = 0;
// const TBOTTOM = 30; // small
const TBOTTOM = 35; // medium
// const TBOTTOM = 56; // big

const moveAdjacentBeads = (newTop, hasValue, position, row, rowValue) => {
    // console.log('moveAdjacentBeads:', newTop, hasValue, position, '::', row, rowValue);

    if (hasValue) {
        for (let pi = position + 1; pi <= 4; pi++) {
            if (rowValue < 5 && rowValue >= pi) document.getElementById(`b[${row}][${pi}]`).style.top = newTop + "px";
            else if (rowValue > 5 && rowValue >= pi + 5) document.getElementById(`b[${row}][${pi}]`).style.top = newTop + "px";
        }
    } else {
        for (let pi = 1; pi < position; pi++) {
            if (rowValue <= pi - 1 || rowValue === 5) document.getElementById(`b[${row}][${pi}]`).style.top = newTop + "px";
        }
    }

}

const processBeadStyleBottom = (diffPos, hasValue, position, row, rowValue) => {
    // console.log('processBeadStyleBottom:', diffPos, hasValue);
    let beadStyle = '';
    let newTop = 0;
    if (!hasValue) {
        if (diffPos <= BTOP) newTop = BTOP;
        else if (diffPos >= BBOTTOM) newTop = BBOTTOM;
        else newTop = diffPos;
    } else {
        if (diffPos <= BBOTTOM) newTop = BBOTTOM;
        else if (diffPos >= -BTOP) newTop = -BTOP;
        else newTop = diffPos;
    }

    moveAdjacentBeads(newTop, hasValue, position, row, rowValue);
    beadStyle = { top: newTop + "px" };
    return beadStyle;
}

const processBeadStyleTop = (diffPos, hasValue) => {
    // console.log('processBeadStyleTop:', diffPos, hasValue);
    let beadStyle = '';
    let newTop = 0;
    if (!hasValue) {
        if (diffPos <= TTOP) beadStyle = newTop = TTOP;
        else if (diffPos >= TBOTTOM) beadStyle = newTop = TBOTTOM;
        else beadStyle = newTop = diffPos;
    } else {
        if (diffPos >= TTOP) beadStyle = newTop = TTOP;
        else if (diffPos <= -TBOTTOM) beadStyle = newTop = -TBOTTOM;
        else beadStyle = newTop = diffPos;
    }

    beadStyle = { top: newTop + "px" };
    return beadStyle;
}


const Bead = ({ handleMove, className, isUpper, hasValue, active, position, row, rowValue }) => {
    // if (position == 2) console.log('Bead', row, position, '::', hasValue, active, '::', rowValue);

    const [initPos, setInitPos] = useState(0);
    const [currPos, setCurrPos] = useState(0);
    const [beadStyle, setBeadStyle] = useState({});

    useEffect(() => {
        // const a = addEventListner('mouse', console.log('mouse moves'))  //it prints when 

        return () => {
            // removeEventListner(a)  //whenever the component removes it will executes
        }
    }, [])


    const touchStartHandle = (e) => {
        const touches = e.touches;
        // console.log('onTouchStart:', touches);
        setInitPos(touches[0].clientY);
        setCurrPos(touches[0].clientY);
    }

    const _endBottom = (diffPos, e) => {
        // console.log('_endBottom:', hasValue, diffPos, BTOP / 2);
        if (!hasValue) {
            if (diffPos < BTOP / 2) {
                setBeadStyle(processBeadStyleBottom(0, hasValue, position, row, rowValue));
                handleMove(e);
            }
            else {
                setBeadStyle(processBeadStyleBottom(0, hasValue, position, row, rowValue));
            }
        } else {
            if (diffPos > BTOP / 2) {
                setBeadStyle(processBeadStyleBottom(0, hasValue, position, row, rowValue));
                handleMove(e);
            }
            else {
                setBeadStyle(processBeadStyleBottom(0, hasValue, position, row, rowValue));
            }
        }
    }

    const _endTop = (diffPos, e) => {
        // console.log('_endTop:', hasValue, diffPos, BTOP / 2);
        if (!hasValue) {
            if (diffPos > TBOTTOM / 2) {
                setBeadStyle(processBeadStyleTop(0));
                handleMove(e);
            }
            else {
                setBeadStyle(processBeadStyleTop(BTOP));
            }
        } else {
            if (diffPos < TBOTTOM / 2) {
                setBeadStyle(processBeadStyleTop(0));
                handleMove(e);
            }
            else {
                setBeadStyle(processBeadStyleTop(BBOTTOM));
            }
        }
    }

    const touchEndHandle = (e) => {
        const diffPos = currPos - initPos;
        // console.log('onTouchEnd:', diffPos, BTOP / 2);c
        if (isUpper) _endTop(diffPos, e);
        else _endBottom(diffPos, e);

    }

    const touchMoveHandle = (e) => {
        const touches = e.touches;
        const currPos = touches[0].clientY;
        setCurrPos(currPos);
        const diffPos = currPos - initPos;

        const beadStyle = isUpper ? processBeadStyleTop(diffPos, hasValue, position, row) : processBeadStyleBottom(diffPos, hasValue, position, row, rowValue);
        setBeadStyle(beadStyle);
    }

    // console.log('Bead:', isUpper, hasValue, active, position, row);

    if (isMobile() && active) {
        return <span
            style={beadStyle}
            onTouchStart={touchStartHandle}
            onTouchEnd={touchEndHandle}
            onTouchMove={touchMoveHandle}
            className={className}
            id={`b[${row}][${position}]`}
        ></span>;
    } else {
        return <span
            onClick={(e) => handleMove(e)}
            className={className}
        ></span>;
    }

}

export default Bead;
