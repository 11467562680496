import React from 'react'

import { shuffle } from '../../../utils';

const beads = shuffle(['azul_p.png', 'rojo_p.png', 'amarillo_p.png', 'gris_p.png', 'marron_p.png']);

class Couples extends React.Component {
    state = {
        items: [],
        opened: null,
        flipped: 0,
        remaining: 0,
        time: 0,
        sound: null,
    }

    componentDidMount() {
        // console.log('Couples.componentWillMount:', this.state, this.props)
        const num_items = this.props.items;
        const side = !this.props.showBack ? 'front' : 'back';

        const type = this.props.type ? this.props.type : 'beads';
        let items = [];

        if (type === 'abacus') {
            const series = this.props.series;
            for (let idx = 0; idx < series.length; idx++) {
                items.push({ val: series[idx], side: side, img: 1 });
                items.push({ val: series[idx], side: side, img: 0 });
            }
        } else {
            for (let idx = 0; idx < num_items; idx++) {
                items.push({ val: idx, side: side });
                items.push({ val: idx, side: side });
            }
        }

        const date = new Date();
        this.setState({ items: shuffle(items), remaining: num_items, time: date.getTime() })
    }

    clickHandle = (idx, item) => {
        // console.log('clickHandle:', idx, item);
        if (item.side === 'back') {
            const items = this.state.items;
            const flipped = this.state.flipped;

            if (flipped < 2) {
                items[idx] = { ...item, side: 'front' };
                const prev_idx = this.state.opened;
                const prev_opened = this.state.opened !== null ? this.state.items[prev_idx] : null;
                const eqs = prev_opened ? (prev_opened.val === items[idx].val) : false;

                this.setState({ items, opened: idx, flipped: flipped + 1, sound: './static/sounds/woosh.mp3' });

                if (flipped === 1) {
                    let remaining = this.state.remaining;

                    if (!eqs) {
                        this.setState({ sound: './static/sounds/spin_jump.mp3' })
                        setTimeout(() => {
                            items[idx] = { ...items[idx], side: 'back' };
                            items[prev_idx] = { ...items[prev_idx], side: 'back' };
                            this.setState({ items, opened: null, flipped: 0, sound: null })
                        }, 500);
                    } else {
                        remaining--;
                        this.setState({ sound: './static/sounds/clink.mp3' });
                        setTimeout(() => {
                            if (remaining === 0) {
                                const date = new Date();
                                this.props.onComplete(date.getTime() - this.state.time);
                            } else {
                                this.setState({ items, opened: null, flipped: 0, remaining, sound: null });
                            }
                        }, 800);
                    }

                } else {
                    //only one flipped
                }
            }
        } else {
            //showing front
        }
    }

    UNSAFE_componentWillReceiveProps() {
        // console.log('Couples.componentWillReceiveProps:', this.state, this.props);
        const items = this.state.items;
        const completed = (this.state.remaining === 0);
        if (!completed) for (let idx = 0; idx < items.length; idx++) {
            items[idx] = { ...items[idx], side: 'back' };
        }
    }

    render() {
        // console.log('Couples:', this.props, this.state);
        const props = this.props;
        const items = this.state.items;
        const completed_style = (this.state.remaining === 0) ? 'completed' : '';

        return (
            <div className={`couple-list ${completed_style}`}>
                {this.state.sound ? <audio src={this.state.sound} autoPlay={true} loop={false}></audio> : null}

                {items.map((aI, idx) => {
                    const val = aI.val;
                    let digits = val.toString().split('');

                    const isImg = aI.img != null ? aI.img : 1;
                    let imgs = [];

                    if (props.type === 'abacus') {
                        if (isImg) {
                            digits.forEach(aD =>{
                                imgs.push(`./static/snapshots/a${aD}.png`);
                            });
                        } else imgs.push(val);
                    } else {
                        for (let idx = 0; idx < this.props.beads; idx++) {
                            imgs.push(`./static/beads/${beads[val]}`);
                        }
                    }

                    const back = <img src={`./static/beads/back.jpg`} alt="bead" />;
                    return (<div key={idx} onClick={() => this.clickHandle(idx, aI)}>
                        {aI.side === 'back' ? back : imgs.map((aImg, idy) => {
                            return isImg ? <img className="bead" src={aImg} alt="bead" key={idy} /> : <span key={idy}>{aImg}</span>
                        })}
                    </div>);
                })}
            </div>
        );
    }
}

export default Couples;
