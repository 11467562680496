let backendHost = `https://back.misoroban.com`;
let port;


if (process.env.NODE_ENV === 'development') {
  /*PROD*/
  // port = 80;
  backendHost = `https://back.misoroban.com`;

  /*LOCAL*/
  // port = 1337;
  // backendHost = `http://localhost`;
} else {
  // port = 80
  backendHost = process.env.REACT_APP_BACKEND_HOST || `https://back.misoroban.com`;
}

// console.log('backendHost:',process.env.NODE_ENV,':',backendHost);

module.exports = {
  port,
  uri: `${backendHost}:${port}`,
  API_ROOT: port ? (`${backendHost}:${port}/api`) : (`${backendHost}/api`),
  IMG_ROOT: `${backendHost}:${port}`,
  version: '0.1.2'
}