

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import './index.less';

const SocialShare = ({ lang }) => {
    const shareUrl = 'https://products.misoroban.com/abaco-japones-soroban';

    return (
        <section className='social-share'>
            <ul>
                <li>
                    <FacebookShareButton url={shareUrl} >
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                </li>
                <li>
                    <TwitterShareButton url={shareUrl} >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                </li>
                <li>
                    <WhatsappShareButton url={shareUrl} >
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                </li>
                <li>
                    <TelegramShareButton url={shareUrl} >
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                </li>
                <li>
                    <EmailShareButton url={shareUrl} >
                        <EmailIcon size={32} round={true} />
                    </EmailShareButton>
                </li>
                <li>
                    <LinkedinShareButton url={shareUrl} >
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                </li>
                <li>
                    <RedditShareButton url={shareUrl} >
                        <RedditIcon size={32} round={true} />
                    </RedditShareButton>
                </li>
               
            </ul>

        </section>
    );
};

export default SocialShare;