const course1 = [
    /**level 0: bienvenida **/
    {
        level: 1,
        label: 'welcome',
        open: true,
        test:
        {
            tid: 'cw1',
            label: 'welcomefromtutor',//'El ábaco japonés',
            icon: 'course/welcome.jpg',
            level: 1,
            exercises: [
                {
                    label: 'welcome',
                    impl: 'theory',
                    icon: 'video',
                    params: { video: { es: 'bKqC87zHt1Y', en: 'bKqC87zHt1Y' }, coins: 10 }
                },

            ]
        },
    },

    /**level 2: base, suma resta sencillas **/
    {
        level: 2,
        label: 'numbers_sums_subtractions',
        open: true,
        units: [
            {
                uid: 'c1',
                label: 'presentationModule1',
                icon: 'course/intro.jpg',
                single: 'true',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { text: { title: 'module1title', content: 'module1intro' }, coins: 10 }
                    }
                ]
            },

            {
                uid: 'c2',
                label: 'numbers',//'El ábaco japonés',
                icon: 'course/numbers.jpg',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'kEYW61DsrYQ', en: 'kEYW61DsrYQ' }, coins: 10 }
                    },

                    {
                        label: 'numbersinabacus',//'numbers',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: '_lcc8c90OJY', en: '_lcc8c90OJY' }, coins: 10 }
                    },

                    {
                        label: 'idnumbers',//'Identifica',
                        impl: 'whatnumberis',
                        icon: 'identifica',
                        params: { series: [{ init: 0, finish: 9, num: 3 }, { init: 10, finish: 99, num: 3 }, { init: 100, finish: 999, num: 3 }], init: 0, finish: 9, rows: 3, coins: 10, num: 5, errors: '2' }
                    },
                    {
                        label: 'repnumbers',//'Representa',
                        impl: 'representinabacus',
                        icon: 'practica',
                        params: { series: [{ init: 0, finish: 9, num: 3 }, { init: 10, finish: 99, num: 3 }, { init: 100, finish: 999, num: 3 }], init: 1, finish: 9, rows: 3, coins: 10, num: 5, errors: 2 }
                    },
                ]
            },


            {
                uid: 'c3',
                label: 'simplesumsubs',//'Representación de números',
                icon: 'course/simple.jpg',
                exercises: [
                    {
                        label: 'sumsubsabacus',//Sumas y restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'G_CMa4gePLI', en: 'G_CMa4gePLI' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+1', '1+2', '1+3', '1+5', '1+6', '1+7', '1+8', '2+1', '2+2', '2+5', '2+6', '2+7', '3+1', '3+5', '3+6', '4+5', '5+1', '5+2', '5+3', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '2-1', '3-2', '3-1', '4-1', '4-2', '4-3', '6-1', '6-5', '7-1', '7-2', '7-5', '7-6', '8-1', '8-2', '8-3', '8-5', '8-6', '8-7', '9-1', '9-2', '9-3', '9-4', '9-5', '9-6', '9-7', '9-8'], rows: 1, coins: 10, num: 10, errors: '2' }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['2+7', '3+6', '4+5', '5+4', '6+1', '6+2', '6+3', '7+1', '7+2', '8+1', '7+2', '1+8', '1+1+2', '1+3+5', '1+5+2', '1+6+1', '2+2+5', '2+6+1', '3+1+5'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0 }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['9-2', '9-4', '9-5', '9-7', '9-8', '8-1', '8-2', '8-3', '8-5', '8-7', '8-6', '7-2', '7-5', '7-6', '6-1', '6-5', '4-1', '4-2', '4-3', '3-1', '3-2', '9-6-1', '9-1-7'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },


            {
                uid: 'c4',
                label: 'sumsubwith5',//Sumas y restas sencillas,
                icon: 'course/with5.jpg',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'khGV_lEcYeY', en: 'khGV_lEcYeY' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '4+1', '4+2', '4+3', '4+4', '4+5'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['1+4', '2+3', '2+4', '3+2', '3+3', '3+4', '4+1'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },

                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: '81g2Jeo80Zg', en: '81g2Jeo80Zg' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacus',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['5-1', '5-2', '5-3', '5-4', '6-3', '6-4', '7-3', '7-4', '8-4', '9-4'], rows: 1, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: ['5-1', '5-2', '6-2', '5-3', '6-3', '7-3', '5-4', '6-4', '7-4', '8-4'], valueinabacus: 0, rows: 1, coins: 10, num: 5, errors: 0, cansubstract: true }
                    },
                ]
            },


            {
                uid: 'c5',
                label: 'sumsubwith10',
                icon: 'course/with10.jpg',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: '2zW7fAVMVQ0', en: '2zW7fAVMVQ0' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['9+3', '9+4', '9+5', '9+6', '9+7', '9+8', '9+9', '8+2', '8+3', '8+4', '8+5', '8+7', '8+8', '8+9', '7+9', '7+3', '7+4', '7+5', '6+9', '6+5', '6+4', '5+5', '4+9', '4+8', '4+7', '4+6', '3+9', '3+8', '3+7', '2+8', '2+9', '1+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['8+6', '7+7', '7+6', '6+6', '6+7', '6+8', '5+6', '5+7', '5+8', '5+9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 1, finish: 9, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                    },

                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'zVi2c0d3aSQ', en: 'zVi2c0d3aSQ' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['10-1', '10-2', '10-3', '10-4', '10-5', '10-6', '10-7', '10-8', '10-9', '11-2', '11-3', '11-4', '11-5', '11-7', '11-8', '11-9', '12-3', '12-4', '12-5', '13-4', '13-5', '13-9', '14-5', '15-6', '15-7', '15-8', '15-9', '16-7', '16-8', '16-9', '17-8', '17-9', '18-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: ['11-6', '12-6', '12-7', '13-6', '13-7', '13-8', '14-6', '14-7', '14-8', '14-9'], rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 11, finish: 19, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 3 }], wait: 3, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsumsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+', '-'], init: 11, finish: 19, ispan: 1, fspan: 9, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 1, num: 4 }], wait: 3, errors: 0, cansubstract: true }
                    },
                ]
            },


            {
                uid: 'c6',
                label: 'sumsub2c',
                icon: 'course/2figs.jpg',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'ADi-O0QfUbc', en: 'ADi-O0QfUbc' }, coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 29, finish: 59, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },

                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: '7mNFip4-6KY', en: '7mNFip4-6KY' }, coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 59, finish: 89, ispan: 19, fspan: 39, rows: 2, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },

                    {
                        label: 'mentalsumchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['+'], init: 11, finish: 29, ispan: 11, fspan: 29, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 71, finish: 99, ispan: 11, fspan: 19, valueinabacus: 0, rows: 2, coins: 10, num: 1, config: [{ digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 4, errors: 0, cansubstract: true }
                    }
                ]
            },


            {
                uid: 'c7',
                label: 'sumsub3c',
                icon: 'course/3figs.jpg',
                exercises: [
                    {
                        label: 'sumabacus',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'AvFLuBli3Zo', en: 'AvFLuBli3Zo' }, coins: 10 }
                    },
                    {
                        label: 'practicesums',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'mentalsumchall',
                        icon: 'mental',
                        impl: 'mentalsumsubstraction',
                        params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },

                    {
                        label: 'subsabacus',//Restas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: '3tfvd4o_G8s', en: '3tfvd4o_G8s' }, coins: 10 }
                    },
                    {
                        label: 'practicesubs',//Practica con el ábaco,
                        impl: 'sumsubstractionnumber',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 10, errors: 2, cansubstract: true }
                    },
                    {
                        label: 'mentalsubchall',
                        impl: 'mentalsumsubstraction',
                        icon: 'mental',
                        params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 2 }, { digits: 1, num: 2 }], wait: 5, errors: 0, cansubstract: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 'tc1',
            label: 'challengeyourself',//Acceder al nivel,
            level: 2,
            icon: 'course/test.jpg',
            exercises: [
                {
                    label: 'baloonschall',//El reto de los globos,
                    impl: 'baloonschall',
                    params: {
                        couples: 'random', init: 111, finish: 999, rows: 3, valueinabacus: 0, 'upperlimit': 999, coins: 10, num: 1, errors: 0,
                        levels: [{ num: 8, vel: 10 }, { num: 8, vel: 10 }]
                    }
                },

                {
                    label: 'repnumbers',//'Representa',
                    impl: 'representinabacus',
                    icon: 'practica',
                    params: { series: [{ init: 100, finish: 999, num: 2 }], init: 1, finish: 9, rows: 3, coins: 10, num: 5, errors: 2 }
                },

                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['+'], init: 299, finish: 599, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },
                {
                    label: 'practiceabacus',//Practica con el ábaco,
                    impl: 'sumsubstractionnumber',
                    params: { couples: 'random', ops: ['-'], init: 599, finish: 899, ispan: 199, fspan: 399, rows: 3, coins: 10, num: 3, errors: 0, cansubstract: true }
                },

                {
                    label: 'mentalsumchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['+'], init: 111, finish: 299, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                },
                {
                    label: 'mentalsubchall',
                    impl: 'mentalsumsubstraction',
                    params: { couples: 'random', ops: ['-'], init: 711, finish: 999, ispan: 111, fspan: 199, valueinabacus: 0, rows: 3, coins: 10, num: 1, config: [{ digits: 3, num: 3 }, { digits: 2, num: 3 }, { digits: 1, num: 3 }], wait: 5, errors: 0, cansubstract: true }
                }
            ]
        },
    },

    /**level 3: multiplicaciones y divisiones **/
    {
        level: 3,
        label: 'multiply_divide',
        open: true,
        units: [
            {
                uid: 'c8',
                label: 'presentationModule2',
                icon: 'course/intro.jpg',
                single: 'true',
                exercises: [
                    {
                        label: 'knowtheabacus',
                        impl: 'theory',
                        icon: 'video',
                        params: { text: { title: 'module2title', content: 'module2intro' }, coins: 10 }
                    }
                ]
            },

            {
                uid: 'c9',
                label: 'mult1c',
                icon: 'course/multx1.jpg',
                exercises: [
                    {
                        label: 'multi1x2',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'C5jid2zS6Sc', en: 'C5jid2zS6Sc' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacus1x2',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'multi1x3',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'eFc2zcz7BQM', en: 'eFc2zcz7BQM' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacus1x3',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 10, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },

                    {
                        label: 'challmultI',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challmultII',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                ]
            },

            {
                uid: 'c10',
                label: 'div1c',
                icon: 'course/div_1.jpg',
                exercises: [
                    {
                        label: 'divide2:1',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'qDGRuQjY8Vk', en: 'qDGRuQjY8Vk' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacus2:1',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 11, finish: 99, ispan: 2, fspan: 9, rows: 8, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },

                    {
                        label: 'divide3:1',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'C47INBFxDKY', en: 'C47INBFxDKY' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacus3:1',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 2, fspan: 9, rows: 9, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },

                    {
                        label: 'challdivI',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challdivII',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                ]
            },

            {
                uid: 'c11',
                label: 'mult2c',
                icon: 'course/multx2.jpg',
                exercises: [
                    {
                        label: 'multi2x2',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: '9MyTXLkdES8', en: '9MyTXLkdES8' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 11, finish: 99, ispan: 11, fspan: 99, rows: 10, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'multi2x3',//Sumas con el ábaco,
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'EYxvV-hf4t0', en: 'EYxvV-hf4t0'}, coins: 10 }
                    },
                    {
                        label: 'practiceabacusII',//Practica con el ábaco,
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['*'], init: 111, finish: 999, ispan: 11, fspan: 99, rows: 12, coins: 10, num: 10, errors: 1, cansubstract: true, markers: true }
                    },

                    {
                        label: 'challmultI',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 11, fspan: 99, rows: 4, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challmultII',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 5, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                ]
            },

            {
                uid: 'c12',
                label: 'div2c',
                icon: 'course/div_2.jpg',
                exercises: [
                    {
                        label: 'divide:2',
                        impl: 'theory',
                        icon: 'video',
                        params: { video: { es: 'EN5aw3cRc30', en: 'EN5aw3cRc30' }, coins: 10 }
                    },
                    {
                        label: 'practiceabacusI',
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 111, finish: 999, ispan: 11, fspan: 99, rows: 10, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },
                    {
                        label: 'practiceabacusII',
                        impl: 'multiplydivide',
                        icon: 'practica',
                        params: { couples: 'random', ops: ['/'], init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 11, coins: 10, num: 5, errors: 1, cansubstract: true, markers: true }
                    },

                    {
                        label: 'challdivI',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 2, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                    {
                        label: 'challdivII',
                        impl: 'multiplydivide',
                        icon: 'reto',
                        params: { couples: 'random', ops: ['/'], only_answer: true, init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 3, coins: 10, num: 5, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                    },
                ]
            },
        ],
        test:
        {
            tid: 'tc2',
            label: 'challengeyourself',//Acceder al nivel,
            level: 3,
            icon: 'course/test.jpg',

            exercises: [
                {
                    label: 'challmult I',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 3, coins: 8, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challmult II',//Practica con el ábaco,
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 4, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },

                {
                    label: 'challmult I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 11, finish: 99, ispan: 11, fspan: 99, rows: 4, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challmult II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['*'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 5, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },


                {
                    label: 'challdiv I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 11, finish: 99, ispan: 2, fspan: 9, rows: 2, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challdiv II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 2, fspan: 9, rows: 3, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },

                {
                    label: 'challdiv I',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 111, finish: 999, ispan: 11, fspan: 99, rows: 2, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
                {
                    label: 'challdiv II',
                    impl: 'multiplydivide',
                    params: { couples: 'random', ops: ['/'], only_answer: true, init: 1111, finish: 9999, ispan: 11, fspan: 99, rows: 3, coins: 10, num: 2, errors: 1, cansubstract: true, markers: false, onlyanswer: true }
                },
            ]
        }

    },

];


const courses = {
    1: course1,
    2: course1,
    3: course1,
}

export default courses;