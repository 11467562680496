import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { RouteComponentProps, Redirect, NavLink } from 'react-router-dom';

import { loadAClass } from '../../modules/aclass';
import { leaveClass } from '../../modules/user';
import { loadResponses, saveResponses } from '../../modules/response';
import plan from '../../data/plan';

import BottomMenu from '../../components/Menu';
import ModalLoader from '../../components/Course/ModalLoader';
import UnitExercises from '../../components/Course/UnitExercises';

import { User } from '../../models/user';
import { getMobileOperatingSystem } from '../../utils';
import { AClass } from '../../models/aclass';

import { Collapse, Button } from 'antd';

import moment from 'moment-timezone';

import './index.less';

const Panel = Collapse.Panel;

interface IProps extends RouteComponentProps<any> {
    user: User;
    aClass: AClass;
    lang: string;
    results: any;
    loadAClass: (classId: string) => void;
    loadResponses: (userId: string) => void;
    saveResponses: (uid, ex, result) => void;
    leaveClass: (aChild: User | undefined, aParent: User, code: string | undefined) => void;
    isLoading: boolean;
    error: boolean;
}
const isApple = (getMobileOperatingSystem() == 'iOS') ? true : false;


class Homework extends React.Component<IProps> {
    state = {
        modalVisible: false,
        modalContent: null,
        modalTitle: 'Ejercicio',
        currentPlan: null
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        const user = this.props.user;
        const activeChild = user ? (user.isParent ? user.activeChild : user.id) : null;
        const classId = user ? user.currentClass : null;
        if (classId && activeChild) {
            this.props.loadResponses(activeChild);
            this.props.loadAClass(classId);
        }
        if (user) {
            this.setState({
                currentPlan: plan[user.age ? user.age : 1]
            })
        }

    };

    handleOk = (e) => {
        console.log('Course.handleOk', e);
        this.setState({
            modalVisible: false,
            modalContent: null
        });
    }

    handleCancel = (e) => {
        // console.log('Course.handleCancel', e);
        this.setState({
            modalVisible: false,
            modalContent: null
        });
    }

    handleAccChange = (panel) => {
    }

    showModal = (cont) => {
        this.setState({
            modalVisible: true,
            modalContent: cont
        });
    }

    handleLeaveClass = () => {
        console.log('leaving class....');
        const user = this.props.user;
        const children = user && user.isParent ? user.children : null;
        const aChild = children ? children.find(aC => aC.id == user.activeChild) : user;

        this.props.leaveClass(aChild, user, user.currentClass);
    }

    render() {

        const props = this.props;
        const aClass = props.aClass;
        const homework = aClass ? aClass.homework : null;
        const results = props.results;

        const user = props.user;
        const activeChild = user ? user.activeChild : null;
        const classId = user ? user.currentClass : null;

        console.log('Homework:', homework, results);
        const activeKey = moment().format('YYYY-MM-DD');

        const modalVisible=this.state.modalVisible;

        if (!props.user) return <Redirect to='/wellcome' />;
        else if (!classId) {
            return <section>
                <h2>Para poder acceder a las tareas, necesitas unirte a una clase</h2>
                <NavLink to="/joinclass">
                    <Button type="primary">{'Unirse a una clase'}</Button>
                </NavLink>
                <BottomMenu section={'homework'} />
            </section>;
        } else {
            return <section>
                {!modalVisible?null:<ModalLoader title={this.state.modalTitle} visible={modalVisible} handleOk={this.handleOk} handleCancel={this.handleCancel} content={this.state.modalContent} />}

                <Collapse accordion bordered={false} defaultActiveKey={activeKey} onChange={this.handleAccChange} >
                    {homework ? homework.map((aH, idx) => {
                        const exercises = aH.exercises ? aH.exercises.map(aE => {
                            const [level, unit, exercise] = aE.split(',');
                            if (level && unit && exercise) {
                                return plan[level - 1].units[unit - 1].exercises[exercise - 1];
                            }
                        }) : null;

                        const res_unit = results ? results[aH.date] : null;
                        const passed = aH.date <= activeKey;
                        const current = aH.date >= activeKey;
                        const stylepass = passed ? 'passed' : (current ? null : 'grayscale');


                        return <Panel
                            header={aH.date}
                            key={`${aH.date}`}
                            disabled={current || passed ? false : true}
                        >
                            <UnitExercises
                                exercises={exercises}
                                uid={aH.date}
                                showModal={this.showModal}
                                handleCancel={this.handleCancel}
                                results={results}
                                saveResult={props.saveResponses}
                                lang={props.lang}
                            />
                        </Panel>

                    }) : null}
                </Collapse>

                <Button onClick={this.handleLeaveClass}>{'Salir de la clase'}</Button>

                <BottomMenu section={'homework'} />

            </section>;
        }
    }
}

const mapStateToProps = ({ user, aclass, response }) => ({
    user: user.user,
    aClass: aclass.data,
    // results: user.results,
    results: response.data,
    lang: user.lang,
    isLoading: aclass.isLoading,
    error: aclass.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    loadAClass,
    loadResponses,
    saveResponses,
    leaveClass
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Homework);
