
import { Collapse, Progress } from 'antd';
import { t } from '../../languages';
import { genExerciseComponent } from '../../utils/componentwrapper';
import UnitExercises from './UnitExercises';
const Panel = Collapse.Panel;

const IconL = ({ src, lang, text, className, done, num, type }) => {
    const percent = Math.round(done / num * 100);

    if (!type) {
        return (<div className="header-unit">
            <img src={src} alt={text} className={className} />
            <div className="header-unit-body">
                <span className='header-unit-body-title'>{text}</span>
                <span className='header-unit-body-desc'>{t(lang, 'watchexers')} · {`${done}/${num}`}</span>
                <Progress percent={percent} showInfo={false} />
            </div>
        </div>);
    } else if (type == 'course') {
        return (<div className="header-unit">
            <img src={src} alt={text} className={className} />
            <div className="header-unit-body">
                <span className='header-unit-body-title'>{text}</span>
                <span className='header-unit-body-desc'>{`${done}/${num}`} {t(lang, 'items')}</span>
                <Progress percent={percent} showInfo={false} />
            </div>
        </div>);
    } else if (type == 'resources') {
        return (<div className="header-unit">
            <img src={src} alt={text} className={className} />
            <div className="header-unit-body">
                <span className='header-unit-body-title'>{text}</span>
                <span className='header-unit-body-desc'>{`${num}`} {t(lang, 'items')}</span>
                <Progress percent={percent} showInfo={false} />
            </div>
        </div>);
    } else if (type == 'practice') {
        return (<div className="header-unit">
            <img src={src} alt={text} className={className} />
            <div className="header-unit-body">
                <span className='header-unit-body-title'>{text}</span>
                <span className='header-unit-body-desc'>{t(lang, 'watchlevels')} · {num}</span>
            </div>
        </div>);
    } else {
        return null;
    }
}

const IconCh = ({ src, lang, text, className, cid, exercises, handleCancel, saveResult, showModal, isSingle }) => {
    const firstExercise = exercises && exercises.length ? exercises[0] : null;
    const exerParams = firstExercise ? firstExercise.params : null;

    return (<div className="header-unit"
        onClick={() => showModal(genExerciseComponent('challenge', cid, 0, exercises, handleCancel, saveResult, lang))}
    >
        <img src={src} alt={text} className={className} />
        <div className="header-unit-body">
            <span className='header-unit-body-title'>{text}</span>
            {isSingle ? null : <div className='value'>
                <span className='points'><img src="./static/myspace/coin.svg" alt="" /> +{exerParams.coins}</span>
                <span className='time'><img src="./static/myspace/clock-three.svg" alt="" /> {exerParams.timeout / 60} min</span>
            </div>}
        </div>
    </div>);
}


const showLevelUnits = (aLevel, showModal, handleCancel, results, saveResult, lang, limitUnit, canAccess, type?) => {

    const curr_level = results['current_level']; // To show all set to: 10
    const level = aLevel.level;
    const openLevel = aLevel.open && canAccess;
    const isPractice = (type == 'practice');
    const isChallenge = (type == 'challenge');
    const isCourse = (type == 'course');
    const isResources = (type == 'resources');

    const handleSaveResult = (uid, ex, result) => {
        // console.log('LevelUnits.handleSaveResult:', uid, ex, result);
        if (result) result.l = level;
        saveResult(uid, ex, result);
    }

    // console.log('showLevelUnits aLevel:', aLevel);

    return (
        aLevel.units.map((aUnit, uIdx) => {
            const res_unit = results ? results[`u${aUnit.uid}`] : null;
            // console.log('results:', results, res_unit, aLevel.level);

            const alreadyu1 = results.u1;

            let mustActivate = openLevel && ((limitUnit > 0 && (uIdx <= limitUnit - 1)) || (limitUnit <= 0));
            if (isCourse || isResources || isPractice) mustActivate = true;

            const current = results ? (results['current'] === `u${aUnit.uid}`) : 0;
            const passed = res_unit ? (res_unit.p && mustActivate) : false;

            // determine if active
            let active = false; //results ? (curr_level >= level && mustActivate) : (isPractice ? 1 : 0);
            if (isCourse || isResources) active = true;
            else if (results) active = (curr_level >= level && mustActivate);
            else active = isPractice;


            const stylepass = openLevel ? (passed ? 'passed' : (active ? null : 'grayscale')) : 'grayscale';

            const done = res_unit ? res_unit.h : 0;
            const num = aUnit.exercises ? aUnit.exercises.length : 1;

            const isSingle = aUnit.single || false;
            // console.log('showLevelUnits isSingle ', aUnit.label, ':', isSingle);

            const collapsible = openLevel && (active || passed || isPractice) && !isChallenge && !isSingle ? 'header' : 'disabled';

            // console.log('showLevelUnits label:', aUnit.label, ' openLevel:', openLevel, ' active:', active, ' mustActivate:', mustActivate, ' collapsible:', collapsible);


            return (
                [<Panel
                    header={isChallenge || isSingle ? <IconCh
                        cid={aUnit.uid}
                        lang={lang}
                        src={`./static/${aUnit.icon}`}
                        text={t(lang, aUnit.label)} className={stylepass}
                        exercises={aUnit.exercises}
                        handleCancel={handleCancel}
                        saveResult={handleSaveResult}
                        showModal={showModal}
                        isSingle={isSingle}
                    /> : <IconL
                        lang={lang}
                        src={`./static/${aUnit.icon}`}
                        text={t(lang, aUnit.label)} className={stylepass}
                        done={done}
                        num={num}
                        type={type}
                    />}

                    key={`u${aUnit.uid}`}
                    showArrow={!(isChallenge || isSingle)}
                    collapsible={collapsible}
                    data-unit={`u${aUnit.uid}`}
                >
                    {mustActivate && !(isChallenge || isSingle) ? <UnitExercises
                        exercises={aUnit.exercises}
                        uid={aUnit.uid}
                        showModal={showModal}
                        handleCancel={handleCancel}
                        results={results}
                        saveResult={handleSaveResult}
                        key={aUnit.uid}
                        lang={lang}
                        isPractice={isPractice}
                    /> : null}
                </Panel>
                    /* ,
                    <div key={`sb${aUnit.uid}`}>
                        {mustActivate && limitUnit > 0 && alreadyu1 ? <SubscribeButton lang={lang} show={mustActivate} /> : null}
                    </div> */
                ]
            );
        })
    )
};

export default showLevelUnits;
