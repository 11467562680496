import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { RouteComponentProps, Redirect, NavLink } from 'react-router-dom';

import { loadAClass } from '../../modules/aclass';
import { leaveClass } from '../../modules/user';
import { loadResponses, saveResponses } from '../../modules/response';
import plan from '../../data/plan';

import BottomMenu from '../../components/Menu';
import ModalLoader from '../../components/Course/ModalLoader';
import UnitExercises from '../../components/Course/UnitExercises';

import { User } from '../../models/user';
import { generateEquation, generateEquationSeries, generateSimpleEquation, getMobileOperatingSystem } from '../../utils';
import { AClass } from '../../models/aclass';

import { Collapse, Button } from 'antd';

import moment from 'moment-timezone';

import './index.less';
import TouchAbacus from '../../components/base/TouchAbacus';
import PathGrid from '../../components/base/Path/Grid';
import Abacus from '../../components/base/abacus/Abacus';
import AbacusView from '../../components/base/abacus';
import NumPad from '../../components/Utils/NumPad';
import BaloonSchall from '../../components/gamez/baloonschall';
import CartoonLayOut from '../../components/gamez/cartoonlayout';
import SubscriptionOptions from '../../components/Subscription/SubscriptionOptions';
import GuidedSequence from '../../components/gamez/guidedsequence';
import Equation from '../../components/gamez/challenge/equationchallenge';
import MyTime from '../../components/Utils/time/MyTimer';
import MyStopwatch from '../../components/Utils/time/MyStopwatch';
import ForwardGrid from '../../components/base/Path/ForwardGrid';
import Mesh from '../../components/base/Mesh';
import CompleteOperations from '../../components/gamez/challenge/completeoperations';
import NumberRiddle from '../../components/gamez/challenge/numberriddle';
import Step4 from '../../components/User/Register/Step4';
import SocialShare from '../../components/Utils/SocialShare';

const Panel = Collapse.Panel;

interface IProps extends RouteComponentProps<any> {
  lang: string;
  user: User;
}
const isApple = getMobileOperatingSystem() == 'iOS' ? true : false;

class TestScreen extends React.Component<IProps> {
  state = {
    modalVisible: false,
    modalContent: null,
    modalTitle: 'Ejercicio'
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    // Test generate equation
    // generateEquation(5);
    /* const eq = generateSimpleEquation(3);
    const jeq = eq.join('');
    console.log('eq:', jeq, eval(jeq));

    console.log('generateEquationSeries:', generateEquationSeries(2, 3, 'simple')); */


  };

  handleOk = e => {
    console.log('Course.handleOk', e);
    this.setState({
      modalVisible: false
    });
  };

  handleCancel = e => {
    // console.log('Course.handleCancel', e);
    this.setState({
      modalVisible: false
    });
  };


  render() {
    const props = this.props;

    const user = props.user;
    const lang = props.lang;

    const time = new Date();
    time.setSeconds(time.getSeconds() + 600); // 10 minutes timer


    // Touch abacus in modal
    /* return <section>
            <ModalLoader title={this.state.modalTitle} visible={this.state.modalVisible} handleOk={this.handleOk} handleCancel={this.handleCancel} content={
                <TouchAbacus rows={2} isactive={true} hidelatframe={true} onChange={(num) => {
                    console.log('num:', num);
                }} reset={0} beadcolors={['rojo', 'azul', 'verde']} initcolor={1} number={46} />
            } />


            {this.state.modalVisible ? null : <div style={{ display: 'flex', paddingTop: '80px' }}>
                <TouchAbacus
                    rows={1}
                    isactive={true}
                    hidelatframe={true}
                    onChange={(num) => {
                        console.log('num:', num);
                    }}
                    reset={0}
                    beadcolors={['azul', 'rojo', 'verde']}
                    initcolor={1}
                    number={5}
                    point={{ row: 1, bead: 5, direction: 'up' }}
                    guided={true}
                />
            </div>}

            <Button onClick={() => this.setState({ modalVisible: true })}>Show modal</Button>
            <BottomMenu section={'testscreen'} />

        </section>; */

    /* return <section>
            <TouchAbacus
                rows={12}
                isactive={true}
                hidelatframe={false}
                onChange={(num) => {console.log('num:', num);}}
                reset={0}
                beadcolors={['rojo', 'azul', 'verde']}
                initcolor={4}
                number={46} 
            />
            <BottomMenu section={'testscreen'} />
        </section>; */

    // Reto Canino
    /* return <section>
            <PathGrid
              key={1}
              items={9}
              rows={1}
              value={10}
              vel={10}
              init={10}
              finish={20}
              onComplete={()=>{console.log('Completed....');}}
            />
            <BottomMenu section={'testscreen'} />
        </section>; */

    // Abaco
    /* return (
      <section style={{ margin: '2em auto', textAlign: 'center' }}>
        <div style={{display: "flex"}}>
          <AbacusView
            params={{
              rows: 1,
              number: 0,
              hidelatframe: false,
              hideheaderlabels: true,
              markselected: false,
              rowcolor: 4,
              numcolors: 2,
              inactiverows: [1, 4],
              stillrows: [0],
              showNumbers: true
            }}
          />
          <AbacusView
            params={{
              rows: 1,
              number: 1,
              hidelatframe: false,
              hideheaderlabels: true,
              markselected: false,
              rowcolor: 4,
              numcolors: 2,
              inactiverows: [1, 4],
              stillrows: [0],
              showNumbers: true
            }}
          />

          <AbacusView
            params={{
              rows: 2,
              number: 95,
              hidelatframe: false,
              hideheaderlabels: true,
              markselected: false,
              rowcolor: 4,
              numcolors: 2,
              // inactiverows: [1, 4], 
              // stillrows: [0], 
              showNumbers: true
            }}
          />
        </div>
        <BottomMenu section={'testscreen'} />
      </section>
    ); */

    // Numpad
    /* return <section style={{margin: "2em auto", textAlign:"center"}}>
            <NumPad clickHandler={(val)=>{console.log('val:', val);}} />
            <BottomMenu section={'testscreen'} />
        </section>; */

    // Tablero
    /* return (
      <section style={{ margin: '2em auto', textAlign: 'center', }}>
        <CartoonLayOut
          params={
            // for 5
            {
                        couples : [
                            "9-4","1+6","9-7","8-3","1+2","3+1","7-2","6+2","1+8","8-5","5+2","2+5","4-2","7-1","1+1","4-1","3-1","5+1","5+3","8-7","4-3","7+1","7-6","6-1","9-8",
                            "6+1","3+6","4+5","2-1","1+5","3-2","9-6","9-3","1+7","1+3","7-5","6+3","2+7","7+2","8-1","8+1","9-1","2+2","9-2","6-5","9-5","3+5","8-2","5+4","2+1","2+6","8-6"
                            ],
                        rows : 1,
                        coins : 10,
                        num : 10,
                        errors : 2,
                        cansubstract : true
                    }

            // // for 10
            // {
            //   couples: [
            //     '6+4','9+6','3+8','8+4','9+5','8+2','7+3','8+7','4+7','8+5','6+5','8+8','8+3','4+6','9+3','3+9','9+9','7+9','7+4','8+9','4+8','4+9','2+9','3+7','6+9','7+5','9+8','2+8','9+7','5+5','9+4','1+9'
            //   ],
            //   rows: 2,
            //   coins: 10,
            //   num: 10,
            //   errors: 2,
            //   cansubstract: true
            // }
            
          }
        />
        { <BottomMenu section={'testscreen'} /> }
      </section>
    ); */

    // Tablero Record
    /* return (
      <section style={{  }} className="cartoon-record">
        <CartoonLayOut
          params={           
            // for record 5
            {
              couples : [
                  "0+10"
                  ],
              rows : 2,
              coins : 10,
              num : 10,
              errors : 2,
              cansubstract : true
            }
          }
        />
        
      </section>
    ); */

    // Subscription
    /* return (
      <section>
        <SubscriptionOptions lang={'es'} preferredOption={3}/>        
      </section>
    ); */

    // Guided seq
    /* return (<section>
      <GuidedSequence 
      lang='es'
      params={{series: [
        { text: '', number: 0, voice: 'numbers/learn' },
        { text: '1', voice: 'numbers/el_uno', points: [{ row: 1, bead: 1, direction: 'up', voice: 'numbers/uno', }] },
        { text: '7', voice: 'numbers/el_siete', points: [{ row: 1, bead: 5, direction: 'down', voice: 'numbers/cinco' }, { row: 1, bead: 2, direction: 'up', voice: 'numbers/y_dos' }] },
        { text: '2', points: [{ row: 1, bead: 2, direction: 'up' }] },
        { text: '3', points: [{ row: 1, bead: 3, direction: 'up' }] },
        { text: '4', points: [{ row: 1, bead: 4, direction: 'up' }] },
        { text: '5', points: [{ row: 1, bead: 5, direction: 'down' }] },
        { text: '6', points: [{ row: 1, bead: 5, direction: 'down' }, { row: 1, bead: 1, direction: 'up' }] },
        { text: '8', points: [{ row: 1, bead: 5, direction: 'down' }, { row: 1, bead: 3, direction: 'up' }] },
        { text: '9', points: [{ row: 1, bead: 5, direction: 'down' }, { row: 1, bead: 4, direction: 'up' }] },
        { text: '0', number: 9, points: [{ row: 1, bead: 5, direction: 'up' }, { row: 1, bead: 1, direction: 'down' }] },
    ], rows: 1, coins: 10, num: 10, errors: 2}}
      uid='1'
      eid='1'
      saveResult={()=>console.log('saveResult...')}
      handleCancel={()=>console.log('handleCancel...')} />
    </section>) */

    // Equation
    /* return (
      <section style={{ margin: "2em auto", textAlign: "center", color: "black" }} className="equation">
        <Equation
          lang={props.lang}
          params={
            { ops: 5, coins: 10, num: 1, errors: 0, timeout: 30, rows: 3 }
          }
          saveResult={(uid, cod, results) => console.log("... TestingScreen Save result:", uid, cod, results)}
        />

      </section>
    ); */

    // PathGrid
    /* return (
      <section style={{ margin: "2em auto", textAlign: "center", color: "black" }} className="equation">
        <ForwardGrid
          key={'current'}
          items={6}
          cols={4}
          rows={3}
          init={1}
          finish={49}
          onClick={(serie, items)=>console.log("...onClick:", serie, items)}
          showPath={true}
          initpos={{x:0,y:0}}
          endpos={{x:2,y:3}}
          shownumbers={true}
        />

      </section>
    ); */

    // Mesh
    /* return (
      <section style={{ margin: "2em auto", textAlign: "center", color: "black" }} className="mesh">
        <Mesh
          rows={4}
          cols={4}
          objects={[
            { val:1 ,img: 'icons8-bee-80.png' },
            { val:2 ,img: 'icons8-bird-80.png' },
            { val:3 ,img: 'icons8-butterfly-80.png' },
            { val:4 ,img: 'icons8-cat-butt-80.png' },
            { val:5 ,img: 'icons8-crab-80.png' },
          ]}
          position={'random'}
          guess = {false}
        />
      </section>
    ); */

    // CompleteOperations
    /* return (
      <section style={{ margin: "2em auto", textAlign: "center", color: "black" }} className="equation">
        <CompleteOperations
          lang={props.lang}
          params={
            { ops: 3, coins: 10, num: 1, errors: 0, timeout: 60 }
          }
          saveResult={(uid, cod, results) => console.log("... TestingScreen Save result:", uid, cod, results)}
        />

      </section>
    ); */

    // NumberRiddle
    /* return (
      <section style={{ margin: "2em auto", textAlign: "center", color: "black" }}>
        <NumberRiddle
          lang={props.lang}
          params={
            { coins: 10, num: 2, errors: 0, timeout: 3000, init: 1, finish: 10 }
          }
          saveResult={(uid, cod, results) => console.log("... TestingScreen Save result:", uid, cod, results)}
        />

      </section>
    ); */

    //Step3
    /* return (
      <section style={{ margin: "2em auto", textAlign: "center", color: "black" }}>
      <Step4 lang={lang} user={user} />
      </section>
    ); */

    //Share
    return (
      <section style={{ margin: "2em auto", textAlign: "center", color: "black" }}>
        <SocialShare lang={'es'} />
      </section>
    );

    // return null;
  }
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
  lang: user.lang
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestScreen);
