import { Collapse } from 'antd';
import { useEffect } from 'react';
import { Redirect } from 'react-router';

const { Panel } = Collapse;


const Logout = ({ logout }) => {


    useEffect(() => {
        logout();
    }, []);



    return <Redirect to="/profile/login" />;;
};

export default Logout;
