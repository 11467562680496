import React, { useEffect, useState } from 'react';
import { List, Button } from 'antd';
import { t } from '../../../languages';
import { NavLink, Redirect } from 'react-router-dom';
import AgeButtons from '../AgeButtons';
import { User } from '../../../models/user';

const Age = ({ lang, user, save }) => {
    const [selectedAge, setSelectedAge] = useState(user ? user.age : 1);
    const [next, setNext] = useState(false);

    const handleSaveAge = (age) => {
        const aUser: User = user || new User();
        aUser.age = age;
        save(aUser);
        setNext(true);
    }

    if (next) return <Redirect to="/register/step1" />
    else return (
        <section className="welcome-language">

            <div className="buttons">
                <h2>{t(lang, 'agelevel')}</h2>
                <p>{t(lang, 'chooseagerange')}</p>
                <AgeButtons age={selectedAge} handleChangeAge={(selAge) => setSelectedAge(selAge)} />
            </div>
            <NavLink to="/register/step1" onClick={() => handleSaveAge(selectedAge)}>
                <Button type="primary" className="button-next">
                    {t(lang, 'continue')}
                </Button>
            </NavLink>
        </section>
    )
};
export default Age;