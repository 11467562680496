import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { RouteComponentProps } from 'react-router-dom';

import BottomMenu from '../../components/Menu';

import { User } from '../../models/user';
import { getMobileOperatingSystem } from '../../utils';

import { Row, Col } from 'antd';
import FloatButton from '../../components/Utils/FloatButton';

import './index.less';
import AbacusView from '../../components/base/abacus';
import ChangeOrientation from '../../components/Utils/ChangeOrientation';
import {
  ColumnWidthOutlined,
  MenuOutlined,
  ReloadOutlined,
  SlidersOutlined
} from '@ant-design/icons';

declare let window: any;

interface IProps extends RouteComponentProps<any> {
  lang: string;
  user: User;
}

const isApple = getMobileOperatingSystem() == 'iOS' ? true : false;

const DEF_NUM_ROWS = 11;
const DEF_ROWS_COLOR = 4;

const INITRIGHT = 10;
const INITBOTTOM = 50;

const FreeMode = props => {
  const [rows, setRows] = React.useState(DEF_NUM_ROWS);
  const [rowColor, setRowColor] = React.useState(DEF_ROWS_COLOR);
  const [number, setNumber] = React.useState(0);

  useEffect(() => {
    // set3R();
    // set4R();
  }, []);


  const set4R = () => {
    setNumber(0);
    setRows(4);
    setRowColor(0);
  };
  
  const set3R = () => {
    setNumber(0);
    setRows(3);
    setRowColor(0);
  };

  const setDefR = () => {
    setNumber(0);
    setRows(DEF_NUM_ROWS);
    setRowColor(DEF_ROWS_COLOR);
  };

  const setZero = () => {
    setNumber(0);
  };

  return (
    <section className="freemode">
      {/* <ChangeOrientation to={'landscape'} /> */}

      <Row className="freemode-cover">
        <Col span={24} className="freemode-body">
          <AbacusView
            params={{
              rows: rows,
              number: number,
              hidelatframe: false,
              hideheaderlabels: true,
              markselected: false,
              rowcolor: rowColor,
              numcolors: 3,
              inactiverows: [],
              stillrows: [],
              showNumbers: true
            }}
            onChange={n => {
              setNumber(n);
            }}
          />
        </Col>
        {/* <Col span={24}>
          <Row className={'botones'}>
            {rows == 3 ? (
              <FloatButton
                style={{ bottom: INITBOTTOM + 100, right: INITRIGHT + 0 }}
                onClick={setDefR}
                icon={<ColumnWidthOutlined />}
              />
            ) : null}
            {rows == DEF_NUM_ROWS ? (
              <FloatButton
                style={{ bottom: INITBOTTOM + 100, right: INITRIGHT + 0 }}
                onClick={set3R}
                icon={<SlidersOutlined />}
              />
            ) : null}
            <FloatButton
              style={{ bottom: INITBOTTOM + 50, right: INITRIGHT + 0 }}
              onClick={setZero}
              icon={<ReloadOutlined />}
            />
          </Row>
        </Col> */}
      </Row>
      {/* <BottomMenu section={'freeMode'} /> */}
    </section>
  );
};

const mapStateToProps = ({ user }) => ({
  user: user.user,
  lang: user.lang
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FreeMode);
