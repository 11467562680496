import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'antd';
import { t } from '../../../languages';
import Box from '../../base/DragThingsToBoxes/Box';

import './index.less';

const object = ['airplane.png', 'automobile1.png', 'cat.png'];

const boxItems = [
    { uid: 1, img: `./static/objects/${object[0]}`, value: 1 },
    { uid: 2, img: `./static/objects/${object[1]}`, value: 2 },
    { uid: 3, img: `./static/objects/${object[2]}`, value: 3 },
];

const desiredItemsOrder = [
    { did: 1, text: `airplane`, value: 1 },
    { did: 2, text: `automobile`, value: 2 },
    { did: 3, text: `cat`, value: 3 },
];

const DragShaoesInOrder = ({ lang, params, uid, eid, saveResult, handleCancel }) => {

    const [moved, setMoved] = useState(false);
    const [message, setMessage] = useState(null);
    const [messagestyle, setMessagestyle] = useState('');
    const [currentvalue, setCurrentvalue] = useState(null);
    const [citems, setCitems] = useState(null);
    const [desired, setDesired] = useState(null);

    useEffect(() => {
        boxItems.sort(() => Math.random() - 0.5);
        desiredItemsOrder.sort(() => Math.random() - 0.5);

        setCitems(boxItems.slice(0));
        setDesired([...desiredItemsOrder]);
    }, []);

    const handleMove = (direction, items, box) => {
        setMoved(true);
        if (direction == 'down') {
            const cValues = items.map(aI => aI.value);
            setCurrentvalue(cValues);
        } else {
            const cValues = currentvalue.filter(aV => {
                for (let idx = 0; idx < items.length; idx++) {
                    if (aV == items[idx].value) return false;
                }
                return true;
            });
            setCurrentvalue(cValues);
        }
        // console.log('DragShaoesInOrder handleMove: desired::', desired.map(aD => aD.value), 'currentvalue::', currentvalue);

    }

    const handleRateValidate = () => {
        let isOk = true;
        for (let idx = 0; idx < desired.length; idx++) {
            if (!currentvalue.length > idx) {
                isOk = false;
                break;
            }
            else if (currentvalue[idx] != desired[idx].value) {
                isOk = false;
                break;
            }
        }
        // console.log('DragShaoesInOrder handleRateValidate:', 'desired::', desired, 'currentvalue::', currentvalue, 'isOK::', isOk);

        if (isOk) { saveResult(isOk); }
        else {
            setCitems(null);
            setMessagestyle('error');
            setMessage(t(lang, 'notcorrect'));
        }
    }

    const handleNext = () => {
        console.log('handleNext');
        boxItems.sort(() => Math.random() - 0.5);
        desiredItemsOrder.sort(() => Math.random() - 0.5);

        console.log('DragShaoesInOrder handleNext: citems::', citems, 'desired::', desired, 'currentvalue::', currentvalue);

        setCitems([...boxItems]);
        setDesired([...desiredItemsOrder]);
        setCurrentvalue(null);
        setMessage('');
        setMoved(false);
    }

    const desiredText = desired ? desired.map(aO => t(lang, aO.text)).join(', ') : null;

    // console.log('DragShaoesInOrder render: citems::', citems, 'desired::', desired, 'currentvalue::', currentvalue);

    return <Row className="exercise" key="2">
        <Col span={24}>
            <h3>{t(lang, 'forparentsonly')}</h3>
            <p className='data'>{t(lang, 'dragshapes')}: {desiredText}</p>
            <section className="dragshapesinorder">
                {citems ? <article className="dragobjects">
                    <section className="drag_things_to_boxes">
                        <div className={`boxes objects`}>
                            <Box targetKey="box" onMove={(items, box) => handleMove('up', items, box)} key={1} id="orig" items={citems} />
                            <div className="inter-boxes"><img src="./static/utils/drag.svg" alt={'drag'} /></div>
                            <Box targetKey="box" onMove={(items, box) => handleMove('down', items, box)} key={1 + 1000} id="dest" />
                        </div>
                    </section>
                </article> : null}
            </section>
        </Col >
        <Col span={24}>
            <Row className={'botones'} >
                <Col span={24} className={`message ${messagestyle}`}>{message}</Col>
                {moved && !message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRateValidate} style={{ 'width': '90%' }} disabled={!moved}>{t(lang, 'grade')}</Button></Col> : null}
                {message ? <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(lang, 'tryagain')}</Button></Col> : null}
            </Row>
        </Col>
    </Row >
}

export default DragShaoesInOrder;
