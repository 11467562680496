import React, { useEffect, useState } from 'react';

import './index.less';
import { useTimer } from 'react-timer-hook';

const BACKS = '<';
const MAX_SIZE = 13;

const MyTimer = ({ expiryTimestamp, stopTime, handleExpire, handleStop }) => {
  useEffect(() => {

  }, []);


  useEffect(() => {
    // console.log('MyTimer:', stopTime, isRunning);
    if (stopTime && isRunning) {
      pause();
      handleStop(totalSeconds);
    }
    else if (!stopTime && !isRunning) resume();
  }, [stopTime]);

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => handleExpire() });

  // console.log('MyTimer:', totalSeconds, isRunning);

  return (
    <section className="timer">
      <div>
        <span>{minutes > 9 ? minutes : `0${minutes}`}</span>:<span>{seconds > 9 ? seconds : `0${seconds}`}</span>
      </div>
    </section>
  );
};
export default MyTimer;
