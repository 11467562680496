import React, { useEffect, useState } from 'react'

import Abacus from './Abacus';

import './index.less';

const showPointer = (point, containerdivname) => {
    if (!point) return null;
    else {
        const { row, bead, direction } = point;
        

        const beadid = `b[${row - 1}][${bead}]`;
        const theBead = document.getElementById(beadid);
        const rect = theBead ? theBead.getBoundingClientRect() : null;

        const containerdiv = document.getElementById(containerdivname);
        const containerRect = containerdiv ? containerdiv.getBoundingClientRect() : null;
        const absLeft = containerRect ? containerRect.left : 0;
        const absTop = containerRect ? containerRect.top : 0;


        const { top, left, width, height } = rect ? rect : { top: 0, left: 0, width: 0, height: 0 };
        console.log('theBead:', beadid, top, left, absLeft, absTop, height);

        // toggleAllowed(row, bead, true);

        let rtop = (top - absTop - 10) + 'px';
        let rleft = (left + width - absLeft - 10) + 'px';
        let imgsrc = "./static/hands/mano_down.png";

        if (direction === 'up') {
            if (beadid.indexOf('5') >= 0) {
                rtop = (top - absTop + height - 10) + 'px';
                imgsrc = "./static/hands/mano_u_up.png";
            } else {
                rtop = (top - absTop + (bead == 1 ? 30 : 0)) + 'px';
                imgsrc = "./static/hands/mano_b_up.png";
            }
        } else {
            rtop = (top - absTop - 10) + 'px';
            imgsrc = "./static/hands/mano_down.png";
        }

        // const img =
        return <div className={`pointer hand ${direction}`} style={{ top: rtop, left: rleft }}>
            <img src={imgsrc} />
        </div>;
    }
}

const generateRows = (number, rows) => {
    const numberdigits = number?number.toString().split('').reverse():[];
    for (let i = 0; i < rows; i++) {
        if(numberdigits[i]) numberdigits[i] = parseInt(numberdigits[i]);
        else numberdigits[i] = 0;
    }
    // console.log('numberdigits:', number, rows, numberdigits);
    return numberdigits.reverse();
}

const AbacusView = (props) =>{
    const [number,setNumber] = useState(0);
    const [rowValues,setRowValues] = useState(null);
    const [rows,setRows] = useState(null);
    const [sound,setSound] = useState(false);

    const updateState = () => {
        const params = props.params;
        setRows(params.rows);
        setNumber(params.number);
        setRowValues( generateRows(params.number, params.rows) );
    }

    useEffect(() => {
        // console.log('useEffect[]', props);
        updateState();
    }, []);

    useEffect(() => {
        // console.log('useEffect[props.params]', props);
        updateState();
    }, [props.params]);
    

    const clickHandler = (e, row, value, isInactive) => {
        // console.log('AbacusView.clickHandler:','::',row, value, isInactive);

        if(!isInactive){
        
            const lrowValues=[...rowValues];

            if (value === 5 || value === -5) {
                lrowValues[row] += value;
            } else {            
                const lastRowNumB2 = lrowValues[row] >= 5 ? lrowValues[row] - 5 : lrowValues[row];
                let dif2 = value < 0 ? ((4 - lastRowNumB2) + value) : (value - lastRowNumB2);
                if (value > 0 && value < 4 && dif2 < 0) dif2--;
                lrowValues[row] += dif2;
            }

            const newNumber = parseInt(lrowValues.join(''));
            // console.log('AbacusView.newNumber:',number,'::', newNumber);

            setNumber(newNumber);
            setSound(true);

            setRowValues( lrowValues );

            setTimeout(() => {
                setSound(false);
            }, 500);

            if (props.onChange) props.onChange(newNumber, lrowValues);
        }else return;
    }

    // console.log('AbacusView:', number, rowValues);

    const propsToPass = { ...props.params, number, rows, rowValues, sound, clickHandler };

    if(rows) {
        const guided = props.guided;

        return (
        <article className="abacus">
                {sound ? <audio src="./static/sounds/click.mp3" autoPlay={true} loop={false}></audio> : null}
                
                <Abacus {...propsToPass} />
                {guided? showPointer(props.point, 'showabacus') : null}
            </article>
        );
    }else return null;
}

export default AbacusView;
