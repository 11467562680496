/* eslint no-eval: 0 */
import React from 'react';

import { Button, Col, Progress, Row } from 'antd';
import Baloons from '../../base/Baloons';
import Result from '../../base/result';

import { getSeriesFromCouples } from '../../../utils';

import { motion } from 'framer-motion';
import { t } from '../../../languages';
import './index.less';

class BaloonSchall extends React.Component {
    state = {
        eid: 0,
        series: [],
        current: 0,
        results: [],
        times: [],
        message: null,
        message2: null,
        showresults: false,
        levels: []
    }

    componentDidMount() {
        this.handleRepeat();
    }

    handleRate = (timereq) => {
        const times = this.state.times;
        const results = this.state.results;
        const current = this.state.current;
        // console.log('BaloonSchall.handleRate:', this.state.series, current);
        let currentNumber = '' + this.state.series[current];
        const value = Math.floor(eval(currentNumber));
        const ops = currentNumber.match(/(\+|-|\*|\/)/sg);

        currentNumber = currentNumber.replace('*', ' x ').replace('/', ' ÷ ').replace('+', ' + ').replace('-', ' - ');

        const ops_result = ops ? ` ${currentNumber} = ${value}` : '';

        const message = `${t(this.props.lang, 'rightanswer')}: ${ops_result}`; //`¡Bravo ${ops_result}!, ${t(this.props.lang, 'surpassedin')} ${parseInt(timereq / 1000)} ${t(this.props.lang, 'seconds')}`;
        const message2 = timereq ? t(this.props.lang, 'great') : t(this.props.lang, 'nodiscorage');

        times.push(timereq);
        results.push(1);

        this.setState({
            results,
            times,
            message, message2
        });
    }

    handleNext = () => {
        const current = this.state.current;

        if (current < this.state.series.length - 1) {
            this.setState({
                current: current + 1,
                message: null,
                message2: null,
                ready: false
            });

        } else {
            const num = this.state.levels.length;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > this.props.params.errors ? 0 : 1;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            const is_test = this.props.params.is_test ? this.props.params.is_test : false;

            const times = this.state.times;
            const totalTime = times.reduce((acc, aT) => acc += aT, 0);

            this.props.saveResult(uid, `${uid}-${eid}`, { id: 'BC', uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), t: totalTime, r: this.state.results, s: this.state.series }, times);
            if (!is_test) this.setState({ showresults: true });
        }
    }

    handleRepeat = () => {
        const params = this.props.params;
        const couples = params.couples;
        // console.log('BaloonSchall.handleRepeat couples:', couples);

        const series = getSeriesFromCouples(couples, params.levels.length, params.init, params.finish, params.ops, params.config, params.ispan, params.fspan);

        // console.log('BaloonSchall.handleRepeat:', series);

        const levels = params ? params.levels : null;

        this.setState({
            series: series,
            current: 0,
            results: [],
            message: null,
            message2: null,
            showresults: false,
            ready: false,
            levels: levels
        });
    }

    handleComplete = (timereq) => {
        this.handleRate(timereq);
    }

    render() {
        const props = this.props;
        const message = this.state.message;
        const message2 = this.state.message2;
        const current = this.state.current;

        const level = this.state.levels[current];
        const series = this.state.series;
        const results = this.state.results;
        let currentNumber = series[current];

        const value = Math.floor(eval(currentNumber));

        // console.log('BaloonSchall.render currentNumber:', currentNumber, value, '::', series, results);

        currentNumber = new String(currentNumber).replace('*', ' x ').replace('/', ' ÷ ').replace('+', ' + ').replace('-', ' - ');

        const rate = results[this.state.current];
        const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((this.state.current) / series.length * 100);
        const is_test = this.props.params.is_test ? this.props.params.is_test : false;

        // console.log('BaloonSchall.render:', this.state, percent, level);
        const params = { ...props.params, num: series.length, show_time: true };

        if (!showresults) {
            if (!level) return null;
            else return (
                <Row className="exercise">
                    {/* <ChangeOrientation to={'portrait'}  /> */}
                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    {!message ? <Col span={24} className="exercise-body baloons-body">
                        {/* <ExerciseBlocker show={messagestyle} /> */}
                        <section className="baloonschall">
                            <article className="baloons">
                                <Baloons
                                    key={current}
                                    items={level.num - 1}
                                    rows={props.params.rows}
                                    value={value}
                                    vel={level.vel}
                                    init={props.params.init}
                                    finish={props.params.finish}
                                    onComplete={this.handleComplete}
                                />
                            </article>
                            <article className="number" key={currentNumber}>
                                <motion.div
                                    animate={{ scale: 2 }}
                                    transition={{ duration: 1 }}
                                    className="the-number"
                                >{currentNumber}</motion.div>
                            </article>
                        </section>
                    </Col> : <Col span={24}>
                        <Row className={'botones balloons' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>

                            {message ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                        </Row>
                    </Col>}
                </Row >
            );
        } else {
            return (
                <Result params={params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
            );
        }
    };
}

export default BaloonSchall;


