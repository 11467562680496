import React from 'react'

import { genExerciseComponent } from '../../utils/componentwrapper';

import { t } from '../../languages';
import { storeScrollPosition } from '../../utils';


interface IProps {
    results:any;
    uid:string;
    exercises:any;
    showModal:any;
    handleCancel:any;
    saveResult:any;
    lang:string;
    isPractice?:boolean;
  }

const UnitExercises = ({ results, uid, exercises, showModal, handleCancel, saveResult, lang, isPractice }:IProps) => {

    // console.log('UnitExercises exercises:', exercises, '::', results);
    // alert(JSON.stringify(results));

    return <ul className="exerlist">
        {exercises.map((aEx, idx) => {
            const code = `${uid}-${idx}`;
            const act_res = results ? results[code] : null;
            const passed = isPractice?'':(act_res ? act_res.p : false);
            const stylepass = passed ? '_passed' : '';

            // console.log('UnitExercises passed:', uid, idx, code, results[code], act_res, passed);

            return (<li key={idx}
                data-exer = {code}
                className={stylepass}
                onClick={() => {
                    showModal(genExerciseComponent(aEx.impl, uid, idx, aEx.params, handleCancel, saveResult, lang));
                }
            }>
                <img src={`./static/myspace/${aEx.icon}${stylepass}.svg`} alt={aEx.label} />
                <span>{t(lang, aEx.label)}</span>
            </li>);
        })}
    </ul>;
};

export default UnitExercises;
