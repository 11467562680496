import fetch from 'isomorphic-fetch';
import moment from 'moment';


import { API_ROOT } from '../../api-config';
import { fetch_options } from '../utils';

import { ERROR } from '../user';

export const LOAD_CLASS = 'classes/LOAD_CLASS';
export const RECEIVED_CLASS = 'classes/RECEIVED_CLASS';

const initialState = {
  data: null,
  isFetching: false,
  lastUpdated: null,
  error: null
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case LOAD_CLASS:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVED_CLASS:
      return {
        ...state,
        isFetching: false,
        data: action.aClass,
        lastUpdated: moment()
      };

    default:
      return state;
  }
};

export function loadAClass(classId: string) {

  return function (dispatch) {
    dispatch({
      type: LOAD_CLASS
    });

    const url = `${API_ROOT}/classes/${classId}`;
    return fetch(url, fetch_options)
      .then(response => {
        if (response.ok) return response.json();
        else throw new Error(response);
      })
      .then(aClass => {
        console.log('loadClass recived:', aClass);

        dispatch({
          type: RECEIVED_CLASS,
          aClass: aClass,
          receivedAt: Date.now()
        });

      })
      .catch(error => {
        console.error('saveUser error fetching:', error);
        dispatch({
          type: ERROR,
          error: 'An error ocurred'
        });
      });
  };
}