import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Progress } from 'antd';
import Result from '../../../base/result';

import { motion } from 'framer-motion';
import { t } from '../../../../languages';

import './index.less';
import ExerciseBlocker from '../../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../../Utils/ChangeOrientation';
import ForwardGrid from '../../../base/Path/ForwardGrid';
import MyTimer from '../../../Utils/time/MyTimer';

const NumericPathChallenge = ({ lang, params, uid, eid, saveResult, handleCancel }) => {
    // console.log('NumericPathChallenge:', lang, params, uid, eid);

    const [current, setCurrent] = useState(0);
    const [currentNumber, setCurrentNumber] = useState(0);
    const [numberinbox, setNumberinbox] = useState(0);
    const [results, setResults] = useState([]);
    const [message, setMessage] = useState(null);
    const [message2, setMessage2] = useState(null);
    const [showresults, setShowresults] = useState(false);
    const [showPath, setShowPath] = useState(false);
    const [moved, setMoved] = useState(false);

    const [times, setTimes] = useState([]);
    const [stopTime, setStopTime] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);


    useEffect(() => {
        handleRepeat();
    }, []);

    const handleMove = (sol, currVal, items) => {
        // console.log("...onClick:", sol, currVal, items);
        setCurrentNumber(currVal);
        setNumberinbox(sol);
        setMoved(true);
    }

    const handleRate = () => {
        const success = currentNumber === numberinbox ? 1 : 0;

        let message = null;
        let message2 = null;

        if (success) {
            message = t(lang, 'rightpath');
            message2 = null; //t(lang, 'great');
        } else {
            message = t(lang, 'wrongpath');
            message2 = null; //t(lang, 'nodiscorage');
            setShowPath(true);
        }

        results.push(success);
        setResults(results);
        setMessage(message);
        setMessage2(message2);

        setStopTime(true);
    }

    const handleNext = () => {
        if (current < params.num - 1) {
            setCurrentNumber(0);
            setCurrent(current + 1);
            setMessage(null);
            setMessage2(null);
            setShowresults(false);
            setShowPath(false);
            setMoved(false);
            setStopTime(false);

        } else {
            const num = params.num;
            const hits = results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = errors > params.errors ? 0 : 1;
            const coins = params.coins;

            const totalTime = times.reduce((acc, aT) => acc += aT, 0);

            saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: totalTime, c: (passed ? coins : 0) }, times, false);
            setShowresults(true);
        }
        setShowPath(false);
    }

    const handleRepeat = () => {
        // console.log('NumericPathChallenge.handleRepeat:', params.num);

        setCurrentNumber(0);
        setNumberinbox(0);
        setCurrent(0);
        setResults([]);
        setMessage(null);
        setShowresults(false);
        setShowPath(false);
        setMoved(false);

        setStopTime(false);
        setTimes([]);
        setIsTimeout(false);
    }

    const handleStop = (time) => {
        const lTimes = [...times];
        lTimes.push(time);
        setTimes(lTimes);
    }

    const handleExpire = () => {

        const num = params.num;
        const hits = results && results.length > 0 ? results.reduce((acc, aR) => acc += aR) : 0;
        const errors = num - hits;
        const passed = errors > params.errors ? 0 : 1;

        const is_test = params.is_test ? params.is_test : false;

        saveResult(uid, `${uid}-${eid}`, { uid, eid, p: passed, h: hits, e: errors, time: params.timeout }, [], true);

        if (!is_test) {
            setTimes([params.timeout]);
            setStopTime(true);
            setIsTimeout(true);
            setShowresults(true);
        }
    }

    const rate = results[current];
    const messagestyle = message ? (!rate ? ' error' : ' valid') : '';
    const percent = parseInt((current) / params.num * 100);

    const is_test = params.is_test ? params.is_test : false;

    // console.log('currentNumber:', currentNumber);

    if (!showresults) {
        const time = new Date();
        time.setSeconds(time.getSeconds() + params.timeout);

        return (
            <Row className="exercise numericpath">
                <ChangeOrientation to={'portrait'} />

                {!is_test ? <Col span={22} className='progress-bar'>
                    <div className='progress'><Progress percent={percent} showInfo={false} /> </div>
                </Col> : null}

                <Col span={24} className='exercise-timer'>
                    <MyTimer expiryTimestamp={time} stopTime={stopTime} handleExpire={handleExpire} handleStop={(t) => handleStop(t)} />
                </Col>

                <Col span={24} className="exercise-body numericpath-body">

                    <section className="numericpath-section">
                        <ExerciseBlocker show={messagestyle} />
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                duration: 1.5,
                            }}
                            className="the-number"
                        >
                            <ForwardGrid
                                key={current}
                                items={params.items}
                                cols={params.cols}
                                rows={params.rows}
                                init={params.ispan}
                                finish={params.fspan}
                                onClick={handleMove}
                                showPath={showPath}
                                initpos={{ x: 0, y: 0 }}
                                endpos={{ x: params.rows - 1, y: params.cols - 1 }}
                                shownumbers={true}
                            />
                        </motion.div>
                    </section>
                </Col>
                <Col span={24}>
                    <Row className={'botones pathchall' + messagestyle}>
                        <Col span={24} className={'message'}>{message}</Col>
                        {!message ? <Col span={24}><Button key="submit" type="primary" onClick={handleRate} style={{ 'width': '90%' }} disabled={!moved}>{t(lang, 'grade')}</Button></Col> : null}
                        {message ? <Col span={24}><Button key="submit" type="primary" onClick={handleNext} style={{ 'width': '90%' }}>{t(lang, 'next')}</Button></Col> : null}
                        {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                    </Row>
                </Col>
            </Row >
        );
    } else {
        return (
            <Result params={params} results={results} show_time={true} times={times} is_timeout={isTimeout} handleRepeat={handleRepeat} handleCancel={handleCancel} lang={lang} />
        );
    }
};

export default NumericPathChallenge;
