
import { genExerciseComponent } from '../../utils/componentwrapper';

import { Collapse } from 'antd';
import { t } from '../../languages';
const Panel = Collapse.Panel;

const IconL = ({ tid, src, text, className, exercises, saveResult, handleCancel, showModal, disabled, lang }) => (
    <div className={`header-test ${disabled ? '' : 'active'}`}
        onClick={() => !disabled ? showModal(genExerciseComponent('test', tid, 0, exercises, handleCancel, saveResult, lang)) : null}
    >
        <img src={src} alt={text} className={`${className} ${disabled ? 'grayscale' : ''}`} />
        <div className="header-test-body" >
            <span>{text}</span>
        </div>
    </div >);


const showLevelTest = (aLevel, showModal, handleCancel, results, saveResult, lang, canAccess, type?) => {
    const tid = 't' + aLevel.test.tid;
    const exercises = aLevel.test.exercises;
    const curr_level = results['current_level'];
    const level = aLevel.level;
    const openLevel = aLevel.open && canAccess;
    const isPractice = (type == 'practice');
    const isChallenge = (type == 'challenge');
    const isCourse = (type == 'course');
    const isResources = (type == 'resources');

    const icon = aLevel.test.icon || 'levels/sube_nivel.png';
    const label = aLevel.test.label || 'upgradelevel';

    // console.log('Course.showLevelTest:', aLevel, icon,  label)
    let disabled = (openLevel && curr_level >= level)? false : true
    if(isCourse || isResources) disabled = false;

    const handleSaveResult = (uid, ex, result) => {
        // console.log('LevelUnits.handleSaveResult:', uid, ex, result);
        if(result) result.l = level;
        saveResult(uid, ex, result);
    }

    return (
        <Panel
            header={
                <IconL
                    tid={tid}
                    src={`./static/${icon}`}
                    text={t(lang, label)}
                    className="test"
                    exercises={exercises}
                    handleCancel={handleCancel}
                    saveResult={handleSaveResult}
                    showModal={showModal}
                    disabled={disabled}
                    lang={lang}
                />
            }
            key={tid}
            showArrow={false}
            collapsible={'disabled'}
            className={`test ${openLevel ? '' : 'closed'}`}
            data-unit={tid}
        >
        </Panel>
    )
};

export default showLevelTest;
