import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { changeLanguage } from '../../modules/user';
import { saveUser } from '../../modules/user';

import './index.less';

import { User } from '../../models/user';
import { getMobileOperatingSystem } from '../../utils';
import Step1 from '../../components/User/Register/Step1';
import Step2 from '../../components/User/Register/Step2';
import Step3 from '../../components/User/Register/Step3';
import Step4 from '../../components/User/Register/Step4';
import Language from '../../components/User/Register/Language';
import Age from '../../components/User/Register/Age';
import { recordPageGA } from '../../modules/ga';

interface IProps extends RouteComponentProps<any> {
    user: User;
    lang: string;
    age: number;
    saveUser: (user: User) => void;
    changeLanguage: (lang: string) => void;
    isLoading: boolean;
    saved: boolean;
    error: boolean;
}
const isApple = (getMobileOperatingSystem() == 'iOS') ? true : false;


class Register extends React.Component<IProps> {
    state = { tempUser: null }

    componentDidMount = () => {
        window.scrollTo(0, 0);
        recordPageGA('/register');
    };

    handleSave = (user) => {
        this.setState({ tempUser: user });
    }

    handleSaveLang = (lang) => {
        const newUser = new User();
        newUser.language = lang;
        this.setState({ tempUser: newUser });
        this.props.changeLanguage(lang);
    }

    render() {
        console.log('Register.render:', this.props.error);

        return (
            <div className={`MiSoroban welcome ${isApple ? 'apple-welcome' : ''}`} >
                <Switch>
                    <Route exact path="/register/language">
                        <Language lang={this.props.lang} handleSaveLanguage={this.handleSaveLang} />
                    </Route>
                    <Route exact path="/register/age">
                        <Age lang={this.props.lang} user={this.props.user} save={this.handleSave} />
                    </Route>
                    <Route exact path="/register/step1">
                        <Step1 lang={this.props.lang}
                            user={this.state.tempUser}
                            // save={this.handleSave}
                            save={this.props.saveUser}
                            isLoading={this.props.isLoading}
                            saved={this.props.saved}
                            error={this.props.error}
                        />
                    </Route>
                    <Route exact path="/register/step2">
                        <Step2 lang={this.props.lang} user={this.state.tempUser} save={this.handleSave} />
                    </Route>
                    <Route exact path="/register/step3">
                        <Step3
                            lang={this.props.lang}
                            user={this.state.tempUser}
                            save={this.props.saveUser}
                            isLoading={this.props.isLoading}
                            saved={this.props.saved}
                            error={this.props.error}
                        />
                    </Route>
                    <Route exact path="/register/step4">
                        <Step4
                            lang={this.props.lang}
                            user={this.props.user}
                        />
                    </Route>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = ({ user }) => ({
    user: user.user,
    lang: user.lang,
    age: user.age,
    isLoading: user.isLoading,
    saved: user.saved,
    error: user.error,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            changeLanguage,
            saveUser,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Register);
