import React from 'react';
import { t } from '../../../languages';

import './index.less';

const ExerciseBlocker = ({ show }) => {
  if (show) return <section className="localpantalla"></section>;
  else return null;
};
export default ExerciseBlocker;
