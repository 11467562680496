const challenges1 = [
    {
        level: 1,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 'ch1',
                label: 'findvalue',
                level: 1,
                icon: 'challenge/numriddle.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'numberriddle',
                        icon: 'easys',
                        params: { coins: 20, num: 1, errors: 0, timeout: 30, init: 2, finish: 9, permOps: ['+', '-'] }
                    },
                ]
            },
            {
                uid: 'ch2',
                label: 'completeoperations',
                level: 1,
                icon: 'challenge/complete_ops.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'completeoperations',
                        icon: 'easys',
                        params: { ops: 2, permOps: ['+', '-'], init: 21, finish: 399, coins: 20, num: 1, errors: 0, timeout: 30 }
                    },
                ]
            },
            {
                uid: 'ch3',
                label: 'challengevisualmem',
                level: 1,
                icon: 'challenge/visual_mem.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'memomeshchallenge',
                        icon: 'easys',
                        params: { num_objects: 4, wait: 10, coins: 40, num: 1, errors: 0, timeout: 60, rows: 3 }
                    },
                ]
            },
            {
                uid: 'ch4',
                label: 'findpath',
                level: 1,
                icon: 'challenge/path.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'numericpath',
                        icon: 'easys',
                        params: { ispan: 1, fspan: 19, num: 1, items: 7, cols: 4, timeout: 120, rows: 3, coins: 50, errors: 0 }
                    },
                ]
            },
            {
                uid: 'ch5',
                label: 'solveequation',
                level: 1,
                icon: 'challenge/equation.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'equationchallenge',
                        icon: 'easys',
                        params: { ops: 4, permOps: ['+', '-'], init: 21, finish: 199, num: 1, timeout: 30, rows: 3, coins: 60, errors: 0 }
                    },
                ]
            },

        ],
    }
];

const challenges2 = [
    {
        level: 1,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 'ch1',
                label: 'findvalue',
                level: 1,
                icon: 'challenge/numriddle.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'numberriddle',
                        icon: 'easys',
                        params: { coins: 20, num: 1, errors: 0, timeout: 30, init: 2, finish: 9 }
                    },
                ]
            },
            {
                uid: 'ch2',
                label: 'completeoperations',
                level: 1,
                icon: 'challenge/complete_ops.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'completeoperations',
                        icon: 'easys',
                        params: { ops: 2, coins: 20, num: 1, errors: 0, timeout: 30 }
                    },
                ]
            },
            {
                uid: 'ch3',
                label: 'challengevisualmem',
                level: 1,
                icon: 'challenge/visual_mem.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'memomeshchallenge',
                        icon: 'easys',
                        params: { num_objects: 5, wait: 10, coins: 40, num: 1, errors: 0, timeout: 60, rows: 3 }
                    },
                ]
            },
            {
                uid: 'ch4',
                label: 'findpath',
                level: 1,
                icon: 'challenge/path.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'numericpath',
                        icon: 'easys',
                        params: { ispan: 1, fspan: 29, num: 1, items: 7, cols: 4, timeout: 120, rows: 3, coins: 50, errors: 0 }
                    },
                ]
            },
            {
                uid: 'ch5',
                label: 'solveequation',
                level: 1,
                icon: 'challenge/equation.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'equationchallenge',
                        icon: 'easys',
                        params: { ops: 5, init: 11, finish: 59, num: 1, timeout: 30, rows: 3, coins: 60, errors: 0 }
                    },
                ]
            },

        ],
    }
];

const challenges3 = [
    {
        level: 1,
        label: 'iron',
        open: true,
        units: [
            {
                uid: 'ch1',
                label: 'findvalue',
                level: 1,
                icon: 'challenge/numriddle.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'numberriddle',
                        icon: 'easys',
                        params: { coins: 20, num: 1, errors: 0, timeout: 30, init: 2, finish: 11 }
                    },
                ]
            },
            {
                uid: 'ch2',
                label: 'completeoperations',
                level: 1,
                icon: 'challenge/complete_ops.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'completeoperations',
                        icon: 'easys',
                        params: { ops: 2, coins: 20, num: 1, errors: 0, timeout: 30 }
                    },
                ]
            },
            {
                uid: 'ch3',
                label: 'challengevisualmem',
                level: 1,
                icon: 'challenge/visual_mem.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'memomeshchallenge',
                        icon: 'easys',
                        params: { num_objects: 6, init: 21, finish: 199, wait: 10, coins: 40, num: 1, errors: 0, timeout: 60, rows: 3 }
                    },
                ]
            },
            {
                uid: 'ch4',
                label: 'findpath',
                level: 1,
                icon: 'challenge/path.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'numericpath',
                        icon: 'easys',
                        params: { ispan: 1, fspan: 59, num: 1, items: 7, cols: 4, timeout: 120, rows: 3, coins: 50, errors: 0 }
                    },
                ]
            },
            {
                uid: 'ch5',
                label: 'solveequation',
                level: 1,
                icon: 'challenge/equation.png',
                exercises: [
                    {
                        label: 'Entrar',
                        impl: 'equationchallenge',
                        icon: 'easys',
                        params: { ops: 5, init: 21, finish: 119, num: 1, timeout: 30, rows: 3, coins: 60, errors: 0 }
                    },
                ]
            },

        ],
    }
];

const challenges = {
    1: challenges1,
    2: challenges2,
    3: challenges3,
}
export default challenges;