import React, { useEffect } from 'react';
import { lockLandscape, lockPortrait, unlockScreen } from '../../../utils/screen';

import './index.less';

declare let window: any;

const ChangeOrientation = ({ to }) => {

  useEffect(() => {
    // console.log('***** ChangeOrientation to:', to);

    if (to === 'landscape') lockLandscape();
    else if (to === 'portrait') lockPortrait();
    else unlockScreen();

    return () => {
      unlockScreen();
    }

  }, []);

  let oclass = 'none';
  if (to === 'landscape') oclass = 'tolandscape';
  else if (to === 'portrait') oclass = 'toportrait';

  if(window.cordova && window.screen) return null;
  else return (
    <section className={oclass}></section>
  );
  return null;
};
export default ChangeOrientation;
