import React from 'react'
import { Row, Col, Button, Progress } from 'antd';

import './index.less';
import { t } from '../../../languages';
import SocialShare from '../../Utils/SocialShare';

const PDFViewer = ({pdf}) => {

    return (
        <Row className="pdfviewer">
            <Col span={24}>
                <iframe src={pdf} frameborder="0" className='iviewer'></iframe>
            </Col>
        </Row >
    )
};

export default PDFViewer;
