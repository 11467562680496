import React, { useEffect, useState } from 'react';
import { Button, Card, Divider } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { purchase, manageSubscriptions, loadSubscriptions, BASEPRODUCTS, restorePurchases, getProductsDetails } from '../../modules/subscription';

import './index.less';
import { t } from '../../languages';
import { getMobileOperatingSystem, isValidActiveClient } from '../../utils';
import DragShaoesInOrder from '../gamez/dragshapesinorder';
import WebSubscriptionButton from './WebSubscriptionButton';

const os = getMobileOperatingSystem().toLocaleLowerCase();
const _BASEPRODUCTS = BASEPRODUCTS[os];
const HASSUBSCRIPTION = false;

const SubscriptionOptions = ({ lang, products, transactions, purchase, manageSubscriptions, loadSubscriptions, restorePurchases, getProductsDetails, user }) => {
    // console.log('SubscriptionOptions: os::', os, '_BASEPRODUCTS::', _BASEPRODUCTS);

    const [selectedOption, setSelectedOption] = useState(_BASEPRODUCTS && _BASEPRODUCTS[2] ? _BASEPRODUCTS[2] : null);
    const [parentalOk, setParentalOk] = useState(os === 'ios' ? false : true);
    const [productsDetails, setProductsDetails] = useState(null);
    const [hasSubscription, setHasSubscription]: [any, any] = useState(HASSUBSCRIPTION);
    const [isActiveClient, setIsActiveClient]: [any, any] = useState(false);

    useEffect(() => {
        console.log('SubscriptionOptions: products::', products, 'transactions::', transactions);
        // const os = getMobileOperatingSystem().toLocaleLowerCase();
        // if(os=='ios' && !products) loadSubscriptions();
        setProductsDetails(getProductsDetails());
    }, [products]);

    const evalHasSubscription = () => {
        const isActiveClient = isValidActiveClient(user);
        setIsActiveClient(isActiveClient);

        /* REAL */
        let hasSubscription = (transactions && transactions.length > 0);

        /* test NO SUBSCRIPTIONS */
        // hasSubscription = false;

        /* test with SUBSCRIPTIONS */
        // hasSubscription = true;

        setHasSubscription(hasSubscription);
    }

    useEffect(() => {
        evalHasSubscription();
    }, [transactions]);

    useEffect(() => {
        evalHasSubscription();
    }, [user]);

    const doPurchase = () => {
        // console.log('SubscriptionOptions.doPurchase:', selectedOption);
        if (products) for (const aProduct of products) {
            if (aProduct.id === selectedOption) {
                purchase(aProduct);
                break;
            }
        }
    }

    const getProductPrice = (id, defBase, defYear) => {
        // console.log('SubscriptionOptions.getProductPrice:', productsDetails);

        if (productsDetails && productsDetails[id] && productsDetails[id].pricing) {
            const basePrice = productsDetails[id].pricing.priceMicros / 1000000;
            const currency = productsDetails[id].pricing.currency;
            return {
                base: `${basePrice} ${currency}`,
                year: `${basePrice * 12} ${currency}`,
            };
        } else {
            return {
                base: defBase,
                year: defYear,
            };
        }
    }


    if (hasSubscription) {
        if (!window.cordova) return null;
        else return <Button onClick={manageSubscriptions} className="empezar">{t(lang, 'managesubscription')}</Button>;
    } else if (isActiveClient) {
        return null;
    } else {
        if (!window.cordova) {
            return <WebSubscriptionButton lang={lang} user={user} />;
        } else if (parentalOk) {
            const freetrial_url = lang === 'en' ? 'https://www.misoroban.com/try-free-3-days/' : 'https://www.misoroban.com/prueba-gratis-3-dias/';
            const termsandconditions_url = 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/';
            const privacypolicy_url = 'https://www.misoroban.com/privacidad/';

            let paymentDetail = t(lang, 'paymentDetailApple');
            if (os !== 'ios') paymentDetail = paymentDetail.replace('iTunes', 'Google Play');

            const monthlyPrice = getProductPrice(_BASEPRODUCTS[0], '2€', '24€');
            const quaterlyPrice = getProductPrice(_BASEPRODUCTS[2], '5€', '20€');
            const yearlyPrice = getProductPrice(_BASEPRODUCTS[1], '12€', '12€');

            return <section className="subscription">
                <div className='icon'><img src={'./static/subscription/crown_w.svg'} /></div>

                <h3>{t(lang, 'morethan25')}</h3>

                <p className="try">
                    <strong>{t(lang, 'try3days')}</strong><br />
                    {t(lang, 'onallplans')}<br />
                    {t(lang, 'cancelwhenyouwant')}
                </p>

                <Card onClick={() => setSelectedOption(_BASEPRODUCTS[0])} className={selectedOption === _BASEPRODUCTS[0] ? 'selected' : ''} title={t(lang, 'montly')} bordered={false}>
                    <p>{monthlyPrice.base} / {t(lang, 'month')} {/* - {monthlyPrice.year} / {t(lang, 'year')} */}</p>
                </Card>
                <Card onClick={() => setSelectedOption(_BASEPRODUCTS[2])} className={selectedOption === _BASEPRODUCTS[2] ? 'selected' : ''} title={t(lang, 'quaterly')} bordered={true}>
                    <p>{quaterlyPrice.base} / 3 {t(lang, 'months')} {/* - {quaterlyPrice.year} / {t(lang, 'year')} */}</p>
                </Card>
                <Card onClick={() => setSelectedOption(_BASEPRODUCTS[1])} className={selectedOption === _BASEPRODUCTS[1] ? 'selected' : ''} title={t(lang, 'yearly')} bordered={false}>
                    <p>{yearlyPrice.base} / {t(lang, 'year')}</p>
                </Card>

                <Button onClick={() => doPurchase()} className="empezar">{t(lang, 'startnow')}</Button>

                {os === 'ios' ? <div>
                    <Divider style={{ background: 'white' }} />
                    <span style={{ color: 'white' }}>{t(lang, 'ifalreadysubscribed')}:</span>
                    <Button onClick={() => doPurchase()} className="restore">{t(lang, 'restorePurchases')}</Button>
                    <Divider style={{ background: 'white' }} />
                </div> : null}

                <p className='data'>
                    {t(lang, 'autorenewable')} {t(lang, 'cancelwhenyouwant')}
                </p>
                <p className='data'>
                    {os === 'ios' ? <span>
                        <a href={termsandconditions_url} target="subscription">{t(lang, 'termsandconditions')}</a>  |  <a href={privacypolicy_url} target="subscription">{t(lang, 'privacypolicy')}</a>
                    </span> : null}
                    <br />
                    {paymentDetail}
                    <br /> <br />
                    {t(lang, 'onlyfirstsubscription')}
                </p>
                <p className='data'>
                    <a href={freetrial_url} target="subscription">{t(lang, 'howfreetrialworks')}</a>
                </p>

            </section>;
        } else {
            return <section className="subscription">
                <DragShaoesInOrder lang={lang} params={null} uid={0} eid={0} saveResult={() => setParentalOk(true)} handleCancel={() => console.log('cancel')} />
            </section>;
        }


    }

};


const mapStateToProps = ({ user, subscription }) => ({
    user: user.user,
    version: user.app_version,
    lang: user.lang,
    products: subscription.products,
    transactions: subscription.transactions
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            purchase,
            manageSubscriptions,
            loadSubscriptions,
            restorePurchases,
            getProductsDetails
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionOptions);
