import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addTime, openedExercise } from '../../modules/user';

const ModalLoader = ({ title, visible, handleOk, handleCancel, content, className, addTime, openedExercise }) => {

  const [initMoment, setInitMoment] = useState(0);

  useEffect(() => {

    return () => {
    };
  }, []);

  useEffect(() => {
    if (visible) {
      const initM = moment();
      setInitMoment(initM);
      openedExercise(true);
    }
  }, [visible]);

  const handleClose = () => {
    const endM = moment();
    const elapsedTime = endM - initMoment;
    // console.log('ModalLoader handleClose endM:', elapsedTime);
    addTime(elapsedTime);
    openedExercise(false);
  }

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose={true}
      width="100vw"
      style={{ margin: 0, top: 0, backgroundColor: '#f0f2f5', height: '95vh', width: '100vw' }}
      footer={
        null
      }
      className={className ? className : 'modal-loader'}
      afterClose={handleClose}
    >
      {content}
    </Modal>
  );
};


const mapStateToProps = ({ user }) => ({
  user: user.user,
  today_time: user.today_time,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addTime,
      openedExercise
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalLoader);

