import React from 'react'

import { Row, Col, Button } from 'antd';

import './index.less';

const Help = (props) => {
    // console.log('Help:', props);

    return (
        <article className="help animated bounceIn">
            <Row>
                <Col span={24}><h2>Cómo funciona</h2></Col>
                <Col span={24}>{props.children}</Col>
            </Row>
            <Row>
                <Col span={24} className="boton">
                    <Button onClick={props.handleHelp} type="primary" ghost>Entendido!</Button>
                </Col>
            </Row>

        </article>
    )

};

export default Help;
