/* eslint no-eval: 0 */
import React from 'react'

import { Row, Col, Button, Progress } from 'antd';
import AbacusView from '../../base/abacus';
import Result from '../../base/result';

import './index.less';
import { motion } from 'framer-motion';
import { t } from '../../../languages';
import ExerciseBlocker from '../../Utils/ExerciseBlocker';
import ChangeOrientation from '../../Utils/ChangeOrientation';

class SumSubstractionSeries extends React.Component {
    state = {
        eid: 0,
        results: [],
        times: [],
        numberinbox: 0,
        message: null,
        message2: null,
        showresults: false,
        moved: 0,
        completed: [],
        currentOption: null,
        maxForOption: 0,
        currentOps: 0,
        currentResult: 0,
        timer: 0
    }

    UNSAFE_componentWillMount() {
        this.setState({ timer: new Date().getTime() })
    }

    handleRate = () => {
        const params = this.props.params;
        const currentOps = this.state.currentOps;
        const maxForOption = this.state.maxForOption;
        const currentOption = this.state.currentOption;
        const splitJoin = params.ops === '+' ? `${currentOps * currentOption} + ${currentOption}` : `${maxForOption - currentOps * currentOption} - ${currentOption}`;
        const currentNumber = eval(`${splitJoin}`);

        // console.log('SumSubstractionNumber.handleNext:', this.state);
        const is_ok_result = currentNumber === this.state.numberinbox ? 1 : 0;

        if (is_ok_result) {
            const nextNumber = params.ops === '+' ? (currentNumber + currentOption) : (currentNumber - currentOption);
            const max = params.max;

            if ((params.ops === '+' && nextNumber > max) || (params.ops === '-' && nextNumber < 0)) {
                const completed = this.state.completed;
                completed.push(currentOption);
                const message = t(this.props.lang, 'rightserie');
                const message2 = t(this.props.lang, 'great');

                this.setState({ message, message2, completed, currentResult: 1, moved: 0 });
            } else {
                this.setState({ currentOps: currentOps + 1, currentResult: 1, moved: 0 });
            }

        } else {
            const message = `${t(this.props.lang, 'wrong')}, ${splitJoin} = ${currentNumber}`;
            const message2 = t(this.props.lang, 'nodiscorage');
            this.setState({ message, message2, currentResult: 0 });
        }

    }

    handleNext = () => {
        const completed = this.state.completed;
        const params = this.props.params;
        const results = this.state.results;
        const times = this.state.times;
        const currentResult = this.state.currentResult;

        if (currentResult) results.push(currentResult);

        if (completed.length <= (params.finish - params.init)) {
            this.setState({
                currentOption: null,
                numberinbox: 0,
                message: null,
                message2: null,
                moved: 0,
                currentOps: 0,
                results,
                times
            });
        } else {
            const num = this.props.params.num;
            const hits = this.state.results.reduce((acc, aR) => acc += aR);
            const errors = num - hits;
            const passed = true;
            const uid = this.props.uid;
            const eid = this.props.eid;
            const coins = this.props.params.coins;

            const is_test = this.props.params.is_test ? this.props.params.is_test : false;

            this.props.saveResult(uid, `${uid}-${eid}`, { id: 'SSS', uid, eid, p: passed, h: hits, e: errors, c: (passed ? coins : 0), r: this.state.results, s: this.state.series });
            const results = [hits];
            if (!is_test) this.setState({ results, showresults: true });
        }
    }

    handleRepeat = () => {

        this.setState({
            eid: 0,
            results: [],
            times: [],
            numberinbox: 0,
            message: null,
            message2: null,
            showresults: false,
            moved: 0,
            completed: [],
            currentOption: null,
            maxForOption: 0,
            currentOps: 0,
            currentResult: 0,
            timer: new Date().getTime()
        });
    }

    handleChange = (num) => {
        this.setState({ numberinbox: num, moved: 1 });
    }

    handleOption = (num) => {
        const params = this.props.params;
        const maxForOption = params.ops === '+' ? 0 : Math.floor(params.max / num) * num;
        this.setState({
            currentOption: num,
            maxForOption,
            numberinbox: maxForOption
        })
    }

    render() {
        const props = this.props;
        const currentOption = this.state.currentOption;
        const currentResult = this.state.currentResult;
        const completed = this.state.completed;
        const currentOps = this.state.currentOps;

        const message = this.state.message;
        const message2 = this.state.message2;
        const messagestyle = message ? (!currentResult ? ' error' : ' valid') : '';
        const showresults = this.state.showresults;
        const percent = parseInt((this.state.current) / props.params.num * 100);

        const is_test = this.props.params.is_test ? this.props.params.is_test : false;

        const params = { ...props.params, rows: props.params ? props.params.rows : 1, number: this.state.numberinbox };

        const init = props.params.init;
        const finish = props.params.finish;
        const options = Array.from(Array(finish - init + 1), (_, i) => i + init);
        const currentNumber = `${params.ops}${currentOption}`;

        // console.log('SumSubstractionSeries.render:', params, options);

        if (!showresults) {
            return (
                <Row className="exercise">
                    <ChangeOrientation to={'portrait'} />

                    {!is_test ? <Col span={22} className='progress-bar'>
                        <Progress percent={percent} showInfo={false} />
                    </Col> : null}

                    <Col span={24} className="exercise-body">
                        <ExerciseBlocker show={messagestyle} />

                        <section className="sumsubstractionseries">
                            {!currentOption ? <article key="selection" className="selection">
                                {options.map((el, idx) => <Button key={idx} disabled={completed.includes(el)} className="round" onClick={() => this.handleOption(el)}>{el}</Button>)}
                            </article> : null}

                            {currentOption ? <div>
                                <AbacusView params={params} isactive={true} hidelatframe={true} hideheaderlabels={true} onChange={this.handleChange} />
                                <article className="number" key={currentOption}>
                                    <motion.div
                                        animate={{ scale: 2 }}
                                        transition={{ duration: 1 }}
                                        className="the-number"
                                        key={currentOps}
                                    >{currentNumber}</motion.div>
                                </article>
                            </div> : null}
                        </section>
                    </Col>
                    <Col span={24}>
                        <Row className={'botones' + messagestyle}>
                            <Col span={24} className={'message'}>{message}</Col>

                            {!message && currentOption ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleRate} style={{ 'width': '90%' }} disabled={!this.state.moved}>{t(props.lang, 'grade')}</Button></Col> : null}
                            {message && currentOption ? <Col span={24}><Button key="submit" type="primary" onClick={this.handleNext} style={{ 'width': '90%' }}>{t(props.lang, 'next')}</Button></Col> : null}
                            {message2 ? <Col span={24} className={'message'}>{message2}</Col> : null}
                        </Row>
                    </Col>
                </Row >
            );
        } else {
            // console.log('SumSubstractionSeries.render:', this.props);

            return (
                <Result params={props.params} results={this.state.results} handleRepeat={this.handleRepeat} handleCancel={props.handleCancel} lang={props.lang} />
            );
        }
    };
}

export default SumSubstractionSeries;

